import { ForwardButton, View } from '@gts-common/client';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import {
  PaymentChangeInitiator,
  PaymentMethod,
  PaymentMethodDebitData,
  PaymentMethodType,
  UnreachableError,
} from '@gts-sm/utils';
import { PaymentProviderChangeConfirmLoaded } from '../../types';

interface PaymentMethodDebitProps {
  paymentMethodData: PaymentMethodDebitData;
}

const PaymentMethodDebit = ({ paymentMethodData }: PaymentMethodDebitProps) => {
  return (
    <Box>
      <Typography paragraph={true}>
        Das folgende Mandat wurde erfolgreich erstellt:
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography>Konto:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>…{paymentMethodData.accountNumberEnding}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography>Bank:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{paymentMethodData.bankName ?? 'N/A'}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography>Kontoinhaber:</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>
            {paymentMethodData.accountHolderName ?? 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

function getSuccessInfo(paymentMethod: PaymentMethod) {
  switch (paymentMethod.type) {
    case PaymentMethodType.debit:
      return <PaymentMethodDebit paymentMethodData={paymentMethod.data} />;
    case PaymentMethodType.manual:
      throw new Error('Payment method "manual" not supported in this context');
    default:
      throw new UnreachableError(paymentMethod);
  }
}

export function MandateCreatedInfo({
  execAcknowledgePaymentProviderChanged,
  state,
}: {
  execAcknowledgePaymentProviderChanged: () => void;
  state: PaymentProviderChangeConfirmLoaded;
}) {
  const { currentPaymentMethod, paymentChangeInitiatorData } = state;
  const htmlSuccessInfo = getSuccessInfo(currentPaymentMethod);
  const buttonLabel =
    paymentChangeInitiatorData.initiator ===
    PaymentChangeInitiator.GROUP_DATA_UPDATE
      ? 'Weiter zur Startseite'
      : 'Weiter zur Buchung';

  return (
    <View hasBackButton={false} title="Gespeicherte Bezahldaten" maxWidth="sm">
      <Paper
        variant="outlined"
        square
        style={{ padding: '1rem', marginBottom: '2rem' }}
      >
        <Box mb="2rem">{htmlSuccessInfo}</Box>
        <Box textAlign="right" mt="1rem">
          <ForwardButton
            onClick={execAcknowledgePaymentProviderChanged}
            label={buttonLabel}
          />
        </Box>
      </Paper>
    </View>
  );
}
