import { Box, ButtonGroup, Tabs, Tab } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Path, LocationDescriptorObject } from 'history';

import { View, LinkButton } from '@gts-common/client';
import { GroupUserLoginRequestBody, UserLoginRequestBody } from '@gts-sm/utils';
import { useState, ChangeEvent } from 'react';
import {
  REQUEST_PASSWORD_RESET_MAIL_VIEW,
  SIGN_UP_VIEW,
} from '../../constants';
import { LoginFormGroupUser } from './LoginFormGroupUser';
import { LoginFormUser } from './LoginFormUser';

interface Props {
  execUserLogin: (
    loginData: UserLoginRequestBody,
    redirectAfterLogin: LocationDescriptorObject | Path | undefined,
  ) => void;
  execGroupUserLogin: (
    loginData: GroupUserLoginRequestBody,
    redirectAfterLogin: LocationDescriptorObject | Path | undefined,
  ) => void;
}

export const Login = ({ execUserLogin, execGroupUserLogin }: Props) => {
  const location = useLocation<{ referrer: LocationDescriptorObject }>();
  const previousLocation = location.state?.referrer;

  const [value, setValue] = useState(0);

  const handleChange = (
    _event: ChangeEvent<Record<string, never>>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <View hasBackButton={false} title="Anmeldung" maxWidth="xs">
      <Box mb="1rem">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Mit E-Mail" />
          <Tab label="Mit Benutzername" />
        </Tabs>
      </Box>

      {value === 0 && (
        <LoginFormUser
          execUserLogin={execUserLogin}
          previousLocation={previousLocation}
        />
      )}

      {value === 1 && (
        <LoginFormGroupUser
          execGroupUserLogin={execGroupUserLogin}
          previousLocation={previousLocation}
        />
      )}

      <Box textAlign="center" mt="1rem">
        <ButtonGroup variant="text">
          <LinkButton to={SIGN_UP_VIEW} label="Registrierung" />
          <LinkButton
            to={REQUEST_PASSWORD_RESET_MAIL_VIEW}
            label="Passwort vergessen"
          />
        </ButtonGroup>
      </Box>
    </View>
  );
};
