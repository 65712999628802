import { Form, Formik } from 'formik';
import { Box, Typography } from '@material-ui/core';
import {
  Company,
  NormalizedPaymentMethodStatus,
  PaymentMethod,
} from '@gts-sm/utils';

import { checkoutPaymentValidation } from '../../validationSchemata';
import {
  PaymentMethodsLoadedState,
  SaveInvoiceAndPaymentDataFields,
} from '../../types';
import { InvoiceFields } from './InvoiceFields';
import { PAYMENT_METHOD_CURRENT, PaymentOptions } from './PaymentOptions';

interface Props {
  formId: string;
  invoiceData: Company;
  paymentMethods: PaymentMethodsLoadedState;
  execSubmit: (data: SaveInvoiceAndPaymentDataFields) => void;
}

function getCurrentPaymentMethodValue(
  currentPaymentMethod: PaymentMethod | undefined,
) {
  if (currentPaymentMethod) {
    if (
      currentPaymentMethod.status === NormalizedPaymentMethodStatus.DISABLED
    ) {
      return '';
    } else {
      return PAYMENT_METHOD_CURRENT;
    }
  } else {
    return '';
  }
}

export const InvoiceAndPaymentData = ({
  formId,
  invoiceData,
  paymentMethods,
  execSubmit,
}: Props) => {
  const initialPaymentFieldValues: SaveInvoiceAndPaymentDataFields = {
    companyName: invoiceData.companyName,
    streetAndNumber: invoiceData.streetAndNumber,
    zip: invoiceData.zip,
    city: invoiceData.city,
    paymentMethod: getCurrentPaymentMethodValue(
      paymentMethods.currentPaymentMethod,
    ),
  };

  return (
    <Formik
      onSubmit={(values) => {
        execSubmit(values);
      }}
      initialValues={initialPaymentFieldValues}
      validationSchema={checkoutPaymentValidation}
    >
      <Form noValidate={true} id={formId}>
        <Typography gutterBottom={true}>Rechnungsdaten</Typography>
        <Box mb="0.35em">
          <InvoiceFields />
        </Box>
        <Typography>Bezahldaten</Typography>
        <PaymentOptions paymentMethods={paymentMethods} />
      </Form>
    </Formik>
  );
};
