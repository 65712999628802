import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Button, View } from '@gts-common/client';
import EmailIcon from '@material-ui/icons/Email';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface ConfirmationProps {
  isEmailConfirmed: boolean;
  goToHome: () => void;
  goToSignUpMailSent: () => void;
}

interface EmailConfirmedProps {
  goToHome: () => void;
}

interface EmailNotConfirmedProps {
  goToSignUpMailSent: () => void;
}

const EmailConfirmed = ({ goToHome }: EmailConfirmedProps) => {
  return (
    <>
      <Typography variant="h6" component="p" gutterBottom={true}>
        Hier können Sie Ihr neues Produkt aufrufen.
      </Typography>
      <Button onClick={goToHome} label="zur Startseite" />
    </>
  );
};

const EmailNotConfirmed = ({ goToSignUpMailSent }: EmailNotConfirmedProps) => {
  return (
    <>
      <Typography variant="h6" component="h3">
        Ihr nächster Schritt:
      </Typography>
      <List>
        <ListItem button onClick={goToSignUpMailSent}>
          <ListItemIcon>
            <EmailIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Bitte bestätigen Sie Ihre E-Mail-Adresse, um das Produkt nutzen zu können. Sie haben von uns eine E-Mail an Ihre E-Mail-Adresse erhalten. Bitte prüfen Sie auch Ihren Spamordner." />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={goToSignUpMailSent}>
              <ArrowForwardIcon color="primary" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export const Confirmation = ({
  isEmailConfirmed,
  goToHome,
  goToSignUpMailSent,
}: ConfirmationProps) => {
  return (
    <View hasBackButton={false} title="Buchungsbestätigung" maxWidth="sm">
      <Paper
        variant="outlined"
        square
        style={{ padding: '1rem', marginBottom: '2rem' }}
      >
        <Typography variant="h6" component="h3" align="center">
          Vielen Dank für Ihre erfolgreiche Buchung.
        </Typography>
        <Box textAlign="center">
          {!isEmailConfirmed && (
            <EmailNotConfirmed goToSignUpMailSent={goToSignUpMailSent} />
          )}

          {isEmailConfirmed && <EmailConfirmed goToHome={goToHome} />}
        </Box>
      </Paper>
    </View>
  );
};
