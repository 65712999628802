import { connect } from 'react-redux';

import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Path } from 'history';
import { PlanForCheckout } from '@gts-sm/utils';

import { ServiceInstanceCheckout } from '../components/Checkout/ServiceInstanceCheckout/ServiceInstanceCheckout';
import { SERVICE_INSTANCE_CHECKOUT_VIEW } from '../constants';
import {
  ReduxState,
  SaveInvoiceAndPaymentDataFields,
  ServiceInstanceCheckoutStepCode,
} from '../types';

import { Actions } from '../actions/reduxActionTypes';
import {
  execFinishServiceInstanceCheckout,
  execGetServiceInstanceForCheckout,
  execOpenServicePriceExample,
  loadInvoiceAndPaymentData,
  serviceInstanceCheckoutSaveInvoiceAndPaymentData,
} from '../actions';
import { execReplace } from '../actions/navigation';

const matchSelector = createMatchSelector<
  RouterRootState,
  {
    step: ServiceInstanceCheckoutStepCode;
    planId: string;
    serviceInstanceId: string;
  }
>(SERVICE_INSTANCE_CHECKOUT_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const stepCodeFromUrl = match?.params.step;
  const planIdFromUrl = match?.params.planId;
  const serviceInstanceIdFromUrl = match?.params.serviceInstanceId;
  const paymentState = state.invoiceAndPaymentData;
  const checkoutServiceInstance = state.checkout.serviceInstance;

  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  let selectedPlan;

  if (planIdFromUrl && checkoutServiceInstance) {
    selectedPlan = checkoutServiceInstance.plans.find((plan) => {
      return plan.planId === planIdFromUrl;
    });
  }

  return {
    checkoutServiceInstance,
    selectedPlan,
    groupId: state.group.groupId,
    paymentState,
    serviceInstanceIdFromUrl,
    planIdFromUrl,
    stepCodeFromUrl,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execGetServiceInstanceForCheckout(serviceInstanceId: string) {
    dispatch(execGetServiceInstanceForCheckout(serviceInstanceId));
  },
  execLoadInvoiceAndPaymentData() {
    dispatch(loadInvoiceAndPaymentData());
  },
  execSavePaymentStep({
    data,
    groupId,
    nextStepUrl,
    serviceInstanceId,
    planId,
  }: {
    data: SaveInvoiceAndPaymentDataFields;
    groupId: string;
    nextStepUrl: Path;
    serviceInstanceId: string;
    planId: string;
  }) {
    dispatch(
      serviceInstanceCheckoutSaveInvoiceAndPaymentData({
        groupId,
        data,
        nextStepUrl,
        serviceInstanceId,
        planId,
      }),
    );
  },
  execOpenServicePriceExample(plan: PlanForCheckout) {
    dispatch(execOpenServicePriceExample(plan));
  },
  execRedirect(redirectUrl: Path) {
    dispatch(execReplace(redirectUrl));
  },
  execFinishServiceInstanceCheckout(serviceInstanceId: string, planId: string) {
    dispatch(execFinishServiceInstanceCheckout(serviceInstanceId, planId));
  },
});

export const ServiceInstanceCheckoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceInstanceCheckout);
