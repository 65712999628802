import { Box } from '@material-ui/core';
import { BackButton, ForwardButton } from '@gts-common/client';

interface BaseProps {
  showBackBtn?: boolean;
  forwardButtonLabel?: string;
  prevStepUrl?: string;
}

interface WithUrl {
  nextStepAction?: undefined;
  nextStepUrl?: string;
  submitForForm?: undefined;
}

interface WithAction {
  nextStepAction?: () => void;
  nextStepUrl?: undefined;
  submitForForm?: undefined;
}

interface WithSubmit {
  nextStepAction?: undefined;
  nextStepUrl?: undefined;
  submitForForm?: string;
}

type Props = BaseProps & (WithUrl | WithAction | WithSubmit);

export const NavButtons = ({
  showBackBtn = true,
  forwardButtonLabel,
  prevStepUrl,
  nextStepUrl,
  nextStepAction,
  submitForForm,
}: Props) => {
  const justifyContent = showBackBtn ? 'space-between' : 'flex-end';

  return (
    <Box pt="24px" display="flex" justifyContent={justifyContent}>
      {showBackBtn && <BackButton to={prevStepUrl} />}
      {forwardButtonLabel !== undefined && (
        <ForwardButton
          to={nextStepUrl}
          onClick={nextStepAction}
          label={forwardButtonLabel}
          submitForForm={submitForForm}
        />
      )}
    </Box>
  );
};
