import { Paper } from '@material-ui/core';
import { Button, WarningText } from '@gts-common/client';

interface Props {
  goToAvv: () => void;
}

export const AvvText = ({ goToAvv }: Props) => {
  return (
    <Paper style={{ textAlign: 'center', padding: '1rem', margin: '2rem 0' }}>
      <WarningText>
        Aufgrund rechtlicher Rahmenbedingungen der DSGVO muss vor der Nutzung
        von gebuchten Services, in denen personenbezogene Daten erfasst werden,
        der Auftrags&shy;verarbeitungs&shy;vertrag (AV-Vertrag) mit uns
        abgeschlossen sein. Solange der AV-Vertrag nicht abgeschlossen ist, kann
        leider der Service nicht genutzt werden.
      </WarningText>
      <Button label="Zum AV-Vertrag" onClick={() => goToAvv()} />
    </Paper>
  );
};
