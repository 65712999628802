import { PASSWORD_RESET_PATH, ResetPasswordRequestBody } from '@gts-sm/utils';

import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { logoutSucceeded } from './login';

export const resetPassword = (
  data: ResetPasswordRequestBody,
): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  serverComm
    .execPatchRequest<Record<string, never>, ResetPasswordRequestBody>(
      PASSWORD_RESET_PATH,
      data,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          // If the user was logged in the server logged them out.
          // Do this also here to force the user to log in again
          dispatch(logoutSucceeded());
          dispatch(serverRequestSucceeded());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
