import { ErrorResponse } from '@gts-common/client-server';
import { unknownErrorMessage } from '@gts-common/client';
import { serverStatusErrors } from '../../validationMessages';

export function getOperationFailureReason(resp: ErrorResponse) {
  const reason = serverStatusErrors[resp.body.status];

  if (reason) {
    return reason;
  } else {
    return unknownErrorMessage;
  }
}
