"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatFloat = void 0;
/**
 * Formats the float using DECIMAL_SEPARATOR instead of .
 * Cannot cope with big number
 * @param num float number to format
 */
const constants_1 = require("./constants");
function formatFloat(num) {
    return String(num).replace('.', constants_1.DECIMAL_SEPARATOR);
}
exports.formatFloat = formatFloat;
