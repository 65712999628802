import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Company,
  INVOICE_AND_PAYMENT_DATA_PATH,
  PaymentChangeGroupDataUpdateData,
  PaymentChangeInitiator,
  PaymentChangeInitiatorData,
  PaymentChangeProductCheckoutData,
  PaymentMethod,
  SaveInvoiceAndPaymentDataRequestBody,
  SaveInvoiceAndPaymentDataResponse,
  SaveInvoiceAndPaymentDataResponseType,
  UnreachableError,
  PaymentChangeServiceInstanceCheckoutData,
  SaveInvoiceAndPaymentDataResponseOk,
} from '@gts-sm/utils';

import { Path } from 'history';
import { serverComm } from '../serverComm';
import { SaveInvoiceAndPaymentDataFields } from '../types';
import { HOME_VIEW } from '../constants';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import {
  Actions,
  INVOICE_AND_PAYMENT_DATA_SAVED,
  InvoiceAndPaymentDataSaved,
  Thunk,
} from './reduxActionTypes';
import { execReplace } from './navigation';

function paymentDataSaved(
  invoiceData: Company,
  newPaymentMethod: PaymentMethod,
  changePaymentMethod: SaveInvoiceAndPaymentDataResponseOk['changePaymentMethod'],
): InvoiceAndPaymentDataSaved {
  return {
    type: INVOICE_AND_PAYMENT_DATA_SAVED,
    payload: { invoiceData, newPaymentMethod, changePaymentMethod },
  };
}

function saveInvoiceAndPaymentData({
  groupId,
  data,
  paymentChangeInitiatorData,
  redirectAfterResponseOk,
}: {
  groupId: string;
  data: SaveInvoiceAndPaymentDataFields;
  paymentChangeInitiatorData: PaymentChangeInitiatorData;
  redirectAfterResponseOk: Path;
}): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    const { paymentMethod, ...invoiceData } = data;

    // We always send all the data because currently the server doesn't support partial data
    // for this end-point
    const trimmedInvoiceData: Company = {
      city: invoiceData.city.trim(),
      companyName: invoiceData.companyName.trim(),
      streetAndNumber: invoiceData.streetAndNumber.trim(),
      zip: invoiceData.zip.trim(),
    };

    const newPaymentMethodType = paymentMethod.startsWith('new_')
      ? paymentMethod.substring(4)
      : undefined;

    serverComm
      .execPostRequest<
        SaveInvoiceAndPaymentDataResponse,
        SaveInvoiceAndPaymentDataRequestBody
      >(INVOICE_AND_PAYMENT_DATA_PATH, {
        groupId,
        paymentChangeInitiatorData,
        invoiceData: trimmedInvoiceData,
        newPaymentMethodType,
      })
      .then(
        (resp) => {
          if (resp.succeeded) {
            const result = resp.body;
            switch (result.type) {
              case SaveInvoiceAndPaymentDataResponseType.ok: {
                dispatch(
                  paymentDataSaved(
                    trimmedInvoiceData,
                    result.currentPaymentMethod,
                    result.changePaymentMethod,
                  ),
                );
                dispatch(execReplace(redirectAfterResponseOk));
                break;
              }
              case SaveInvoiceAndPaymentDataResponseType.redirect:
                window.location.href = result.url;
                break;
              default:
                throw new UnreachableError(result);
            }
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function groupDataSaveInvoiceAndPaymentData(
  groupId: string,
  data: SaveInvoiceAndPaymentDataFields,
): Thunk<Actions> {
  return (dispatch) => {
    const paymentChangeInitiatorData: PaymentChangeGroupDataUpdateData = {
      initiator: PaymentChangeInitiator.GROUP_DATA_UPDATE,
    };

    dispatch(
      saveInvoiceAndPaymentData({
        groupId,
        data,
        paymentChangeInitiatorData,
        redirectAfterResponseOk: HOME_VIEW,
      }),
    );
  };
}

export function productCheckoutSaveInvoiceAndPaymentData({
  groupId,
  data,
  productId,
  nextStepUrl,
}: {
  groupId: string;
  data: SaveInvoiceAndPaymentDataFields;
  productId: string;
  nextStepUrl: Path;
}): Thunk<Actions> {
  return (dispatch) => {
    const paymentChangeInitiatorData: PaymentChangeProductCheckoutData = {
      initiator: PaymentChangeInitiator.PRODUCT_CHECKOUT,
      productId,
    };

    dispatch(
      saveInvoiceAndPaymentData({
        groupId,
        data,
        paymentChangeInitiatorData,
        redirectAfterResponseOk: nextStepUrl,
      }),
    );
  };
}

export function serviceInstanceCheckoutSaveInvoiceAndPaymentData({
  groupId,
  data,
  serviceInstanceId,
  planId,
  nextStepUrl,
}: {
  groupId: string;
  data: SaveInvoiceAndPaymentDataFields;
  serviceInstanceId: string;
  planId: string;
  nextStepUrl: Path;
}): Thunk<Actions> {
  return (dispatch) => {
    const paymentChangeInitiatorData: PaymentChangeServiceInstanceCheckoutData = {
      initiator: PaymentChangeInitiator.SERVICE_INSTANCE_CHECKOUT,
      serviceInstanceId,
      planId,
    };

    dispatch(
      saveInvoiceAndPaymentData({
        groupId,
        data,
        paymentChangeInitiatorData,
        redirectAfterResponseOk: nextStepUrl,
      }),
    );
  };
}
