import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  REQUEST_PASSWORD_RESET_PATH,
  PasswordResetRequestBody,
} from '@gts-sm/utils';
import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { execReplace } from './navigation';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

export const requestPasswordResetMail = (email: string): Thunk<Actions> => (
  dispatch,
) => {
  dispatch(startServerRequest());

  const trimmedAndLoweredEmail = email.trim().toLowerCase();
  const data: PasswordResetRequestBody = { email: trimmedAndLoweredEmail };
  const search = new URLSearchParams({ email: trimmedAndLoweredEmail });

  serverComm
    .execPostRequest<Record<string, never>, PasswordResetRequestBody>(
      REQUEST_PASSWORD_RESET_PATH,
      data,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(execReplace({ search: '?' + search.toString() }));
          dispatch(serverRequestSucceeded());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
