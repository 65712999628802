import { Typography } from '@material-ui/core';
import { View } from '@gts-common/client';
import { ResendSignUpMail } from '../ResendSignUpMail';

interface Props {
  execResendSignUpMail: (email: string) => void;
  email: string;
}

export const SignUpNotConfirmed = ({ execResendSignUpMail, email }: Props) => {
  return (
    <View hasBackButton={false} title="Account nicht aktiviert" maxWidth="sm">
      <Typography align="justify">
        Der Account mit E-Mail-Adresse {email} wurde noch nicht aktiviert. Bitte
        bestätigen Sie Ihre Registrierung bei uns, indem Sie auf den Link in der
        E-Mail klicken. Sollten Sie keine E-Mail erhalten haben, können Sie eine
        hier erneut anfordern.
      </Typography>

      <ResendSignUpMail
        email={email}
        execResendSignUpMail={execResendSignUpMail}
      />
    </View>
  );
};
