import { initialState } from '../initialState';
import {
  Actions,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { ServiceInstancesState } from '../types';

export function serviceInstancesReducer(
  state: ServiceInstancesState = initialState.serviceInstances,
  action: Actions,
): ServiceInstancesState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        serviceInstances: action.payload.serviceInstances,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        serviceInstances: action.payload.serviceInstances,
      };

    case LOGOUT_SUCCEEDED:
      return initialState.serviceInstances;

    default:
      return state;
  }
}
