import {
  ServiceInstanceCheckoutStepCode,
  LoadingState,
} from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForPayment({
  checkoutServiceInstance,
  groupId,
  paymentState,
  selectedPlan,
}: GetStepToShowParams): StepToShow | undefined {
  const { invoiceData, paymentMethods } = paymentState;

  if (paymentMethods.loadState === LoadingState.error) {
    return { type: StepToShowType.error };
  } else if (
    paymentMethods.loadState === LoadingState.loaded &&
    invoiceData.loadState === LoadingState.loaded &&
    checkoutServiceInstance
  ) {
    // If the checkoutServiceInstance is defined but we have no plan it means that
    // the planId is either wrong or not there
    if (selectedPlan) {
      return {
        type: StepToShowType.withData,
        stepData: {
          step: ServiceInstanceCheckoutStepCode.Payment,
          checkoutServiceInstance,
          groupId,
          invoiceData,
          paymentMethods,
          selectedPlan,
        },
      };
    } else {
      return undefined;
    }
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData:
        paymentMethods.loadState !== LoadingState.loaded ||
        invoiceData.loadState !== LoadingState.loaded,
      loadServiceInstance: !checkoutServiceInstance,
    };
  }
}
