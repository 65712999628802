import { Formik } from 'formik';

import { EditUserRequestBody } from '@gts-sm/utils';
import { passwordChangeValidation } from '../../validationSchemata';
import { PasswordChangeForm } from './PasswordChangeForm';

const initialValues: EditUserRequestBody = {
  password: '',
  newPassword: '',
};

interface Props {
  execPasswordChange: (passwordChange: EditUserRequestBody) => void;
}

export const PasswordChange = ({ execPasswordChange }: Props) => {
  return (
    <Formik
      onSubmit={execPasswordChange}
      initialValues={initialValues}
      validationSchema={passwordChangeValidation}
    >
      <PasswordChangeForm />
    </Formik>
  );
};
