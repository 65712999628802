import { Box } from '@material-ui/core';

import { Form, Formik } from 'formik';
import { Button } from '@gts-common/client';
import { SignUpRequestBody } from '@gts-sm/utils';
import { signUpValidation } from '../../../validationSchemata';
import { signUpFormInitialValues } from '../../../constants';
import { ConditionsAndPrivacyFields } from './ConditionsAndPrivacyFields';
import { UserInfoFields } from './UserInfoFields';

const formId = 'signup-form';

interface Props {
  execSignUp: (signUp: SignUpRequestBody) => void;
}

export const SignUpForm = ({ execSignUp }: Props) => {
  return (
    <Formik
      onSubmit={(signUpData: SignUpRequestBody) => execSignUp(signUpData)}
      initialValues={signUpFormInitialValues}
      validationSchema={signUpValidation}
    >
      <Form noValidate={true} id={formId}>
        <UserInfoFields />
        <ConditionsAndPrivacyFields />
        <Box textAlign="center" mt="1rem">
          <Button submitForForm={formId} label="Registrieren" />
        </Box>
      </Form>
    </Formik>
  );
};
