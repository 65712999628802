import {
  Company,
  Contact,
  GetCheckoutProductResponse,
  ServiceInstance,
  ClientGroup,
  ConfirmPaymentProviderChangeResponse,
  AcceptedAVV,
  GroupUser,
  SessionType,
  PaymentMethod,
  Invoice,
  GetCheckoutServiceInstanceResponse,
  ServiceInstanceWithSubscriptions,
} from '@gts-sm/utils';
import { CommonState, LoginStatus, Normalized } from '@gts-common/client';
import { RouterRootState } from 'connected-react-router';
import { LocationDescriptorObject, Path } from 'history';

export enum ProductCheckoutStepCode {
  Product = 'product',
  Account = 'account',
  Payment = 'payment',
  Finish = 'finish',
}

export enum ServiceInstanceCheckoutStepCode {
  SelectPlan = 'select-plan',
  Plan = 'plan',
  Payment = 'payment',
  Finish = 'finish',
}

export type NavigationTo = LocationDescriptorObject | Path;

export interface AppState {
  loginStatus: LoginStatus;
  avvTemplate: string | undefined;
  isEmailConfirmed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  userType: SessionType;
}

export interface ServiceInstancesState {
  serviceInstances: Normalized<ServiceInstance>;
}

export interface CheckoutState {
  product: GetCheckoutProductResponse | undefined;
  serviceInstance: GetCheckoutServiceInstanceResponse | undefined;
}

export enum LoadingState {
  loadNeeded,
  error,
  loaded,
}

export interface PaymentMethodsNotLoadedState {
  loadState: LoadingState.loadNeeded | LoadingState.error;
}

export interface PaymentMethodsLoadedState {
  loadState: LoadingState.loaded;
  currentPaymentMethod?: PaymentMethod;
  changePaymentMethod: Array<{
    type: string;
    label: string;
  }>;
}
export type PaymentMethodsState =
  | PaymentMethodsNotLoadedState
  | PaymentMethodsLoadedState;

export interface SelectedPaymentMethodNotLoaded {
  loadState: LoadingState.loadNeeded | LoadingState.error;
}
export interface SelectedPaymentMethod {
  loadState: LoadingState.loaded;
  currentPaymentMethod?: PaymentMethod;
}
export type SelectedPaymentMethodState =
  | SelectedPaymentMethodNotLoaded
  | SelectedPaymentMethod;

export interface InvoiceDataNotLoadedState {
  loadState: LoadingState.loadNeeded | LoadingState.error;
}
export interface InvoiceDataLoadedState extends Company {
  loadState: LoadingState.loaded;
}
export type InvoiceDataState =
  | InvoiceDataNotLoadedState
  | InvoiceDataLoadedState;

export interface GroupStateEmpty {
  available: false;
}
export interface GroupStateFilled extends ClientGroup {
  available: true;
}
export type GroupState = GroupStateEmpty | GroupStateFilled;

export interface PaymentProviderChangeConfirmLoadNeeded {
  loadState: LoadingState.loadNeeded | LoadingState.error;
}

export type PaymentProviderChangeConfirmLoaded = ConfirmPaymentProviderChangeResponse & {
  loadState: LoadingState.loaded;
};

export type PaymentProviderChangeConfirmState =
  | PaymentProviderChangeConfirmLoadNeeded
  | PaymentProviderChangeConfirmLoaded;

export interface InvoiceAndPaymentDataState {
  invoiceData: InvoiceDataState;
  paymentProviderChangeConfirm: PaymentProviderChangeConfirmState;
  paymentMethods: PaymentMethodsState;
  selectedPaymentMethod: SelectedPaymentMethodState;
}

export interface AvvState {
  acceptedList: ReadonlyArray<AcceptedAVV>;
}

export interface GroupUsersState {
  users: ReadonlyArray<GroupUser>;
}

export interface SubscriptionsState {
  list: ReadonlyArray<ServiceInstanceWithSubscriptions>;
}

export interface InvoicesState {
  list: ReadonlyArray<Invoice>;
}

export interface ReduxState extends RouterRootState {
  app: AppState;
  group: GroupState;
  serviceInstances: ServiceInstancesState;
  checkout: CheckoutState;
  invoiceAndPaymentData: InvoiceAndPaymentDataState;
  common: CommonState;
  avv: AvvState;
  groupUsers: GroupUsersState;
  subscriptions: SubscriptionsState;
  invoices: InvoicesState;
}

export interface AVV {
  db: Contact;
  company: {
    companyName: string;
    streetAndNumber: string;
    zip: string;
    city: string;
  };
}

export interface LoginData {
  isEmailConfirmed: boolean;
  email: string;
  group: GroupState;
  firstName: string;
  lastName: string;
  serviceInstances: Normalized<ServiceInstance>;
  userType: SessionType;
}

export interface ProductCheckoutStep {
  code: ProductCheckoutStepCode;
  label: string;
}

export interface ServiceInstanceCheckoutStep {
  code: ServiceInstanceCheckoutStepCode;
  label: string;
}

export interface PersonalDataView {
  contact: Contact;
}

export interface GroupUserBase {
  username: string;
  password: string;
}

export interface SaveInvoiceAndPaymentDataFields extends Company {
  paymentMethod: string;
}
