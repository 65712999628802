import { Typography, Grid } from '@material-ui/core';
import { TextInput } from '@gts-common/client';
import { Company, Contact } from '@gts-sm/utils';
import { ContactForm } from './ContactForm';

interface Props {
  contact: Contact;
  company: Company;
}

export const AVVForm = ({ contact, company }: Props) => {
  return (
    <form>
      <Typography variant="h6" style={{ marginBottom: '2rem' }}>
        Firma
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="company.companyName"
            value={company.companyName}
            label="Firma"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="company.streetAndNumber"
            value={company.streetAndNumber}
            label="Straße und Hausnummer"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="company.zip"
            value={company.zip}
            label="Postleitzahl"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="company.city"
            value={company.city}
            label="Stadt"
            disabled
          />
        </Grid>
      </Grid>

      <Typography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Datenschutzbeauftragter
      </Typography>
      <ContactForm contact={contact} />
    </form>
  );
};
