import { Box, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';

import { useEffect } from 'react';
import {
  ErrorText,
  View,
  PleaseWaitText,
  InvalidLinkText,
  LinkButton,
} from '@gts-common/client';
import { UnreachableError } from '@gts-sm/utils';

import { ID_REGEX } from '@gts-common/client-server';
import {
  getSteps,
  getStepUrlFromStepCode,
  getStepUrls,
} from '../../../containers/helpers/productCheckoutSteps';
import { HOME_VIEW } from '../../../constants';
import { StepToShowType } from '../commonCheckoutTypes';
import { getStepToShow } from './getStepToShow/getStepToShow';
import { getStepperContents, PropsCheckout } from './getStepperContents';

interface PropsCheckoutChecked extends PropsCheckout {
  productIdFromUrl: string;
}

interface PropsCheckoutUnchecked extends PropsCheckout {
  productIdFromUrl: string | undefined;
}

const CheckoutValid = ({
  checkoutProduct: checkoutProductUnchecked,
  groupId,
  execFinishProductCheckout,
  execGetProductForCheckout,
  execLoadInvoiceAndPaymentData,
  execSavePaymentStep,
  execRedirect,
  execOpenServicePriceExample,
  execUserLogin,
  execSignUp,
  isLoggedIn,
  paymentState,
  productIdFromUrl,
  stepCodeFromUrl,
}: PropsCheckoutChecked) => {
  // only use the checkoutProduct from state if is same as in URL
  const checkoutProduct =
    productIdFromUrl === checkoutProductUnchecked?.productId
      ? checkoutProductUnchecked
      : undefined;

  const stepAndLoadData = getStepToShow({
    checkoutProduct,
    groupId,
    isLoggedIn,
    paymentState,
    stepCodeFromUrl,
  });

  let loadProduct = false;
  let loadInvoiceAndPaymentData = false;
  if (stepAndLoadData.type === StepToShowType.loadNeeded) {
    loadProduct = stepAndLoadData.loadProduct;
    loadInvoiceAndPaymentData = stepAndLoadData.loadInvoiceAndPaymentData;
  }
  const stepCodeToShow =
    stepAndLoadData.type === StepToShowType.withData
      ? stepAndLoadData.stepData.step
      : undefined;

  useEffect(() => {
    if (loadProduct) {
      execGetProductForCheckout(productIdFromUrl);
    }
  }, [execGetProductForCheckout, loadProduct, productIdFromUrl]);
  useEffect(() => {
    if (loadInvoiceAndPaymentData) {
      execLoadInvoiceAndPaymentData();
    }
  }, [execLoadInvoiceAndPaymentData, loadInvoiceAndPaymentData]);
  useEffect(() => {
    if (stepCodeToShow !== undefined && stepCodeToShow !== stepCodeFromUrl) {
      // if step to show differs from the step in the URL --> change URL to match the shown step
      execRedirect(getStepUrlFromStepCode(stepCodeToShow, productIdFromUrl));
    }
  }, [execRedirect, productIdFromUrl, stepCodeFromUrl, stepCodeToShow]);

  switch (stepAndLoadData.type) {
    case StepToShowType.error:
      return (
        <>
          <ErrorText>
            Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später
            erneut.
          </ErrorText>
          <LinkButton to={HOME_VIEW} label="Zur Startseite" />
        </>
      );
    case StepToShowType.loadNeeded:
      return <PleaseWaitText />;
    case StepToShowType.withData: {
      const stepData = stepAndLoadData.stepData;
      const { nextStepUrl, prevStepUrl } = getStepUrls(
        isLoggedIn,
        productIdFromUrl,
        stepData.step,
      );
      const { steps } = getSteps(isLoggedIn);
      const stepNumberToShow = steps.findIndex(
        (step) => step.code === stepData.step,
      );

      return (
        <View hasBackButton={false} title="Produkt buchen">
          <Box>
            <Stepper activeStep={stepNumberToShow} orientation="vertical">
              {steps.map(({ label, code }) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {code === stepData.step
                        ? getStepperContents({
                            execSignUp,
                            execUserLogin,
                            execFinishProductCheckout,
                            execGetProductForCheckout,
                            execLoadInvoiceAndPaymentData,
                            execSavePaymentStep,
                            execRedirect,
                            execOpenServicePriceExample,
                            nextStepUrl,
                            prevStepUrl,
                            stepData,
                          })
                        : undefined}
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </View>
      );
    }
    default:
      throw new UnreachableError(stepAndLoadData);
  }
};

export const ProductCheckout = (props: PropsCheckoutUnchecked) => {
  const productIdFromUrl = props.productIdFromUrl;
  if (productIdFromUrl && ID_REGEX.test(productIdFromUrl)) {
    const propsChecked = { ...props, productIdFromUrl };
    return <CheckoutValid {...propsChecked} />;
  } else {
    return <InvalidLinkText />;
  }
};
