import { ProductCheckoutStepCode } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForProduct({
  checkoutProduct,
}: GetStepToShowParams): StepToShow {
  if (checkoutProduct) {
    return {
      type: StepToShowType.withData,
      stepData: { step: ProductCheckoutStepCode.Product, checkoutProduct },
    };
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData: false,
      loadProduct: true,
    };
  }
}
