import { ProductCheckoutStepCode, LoadingState } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForFinish({
  checkoutProduct,
  groupId,
  paymentState,
}: GetStepToShowParams): StepToShow | undefined {
  const { invoiceData } = paymentState;

  if (groupId === undefined) {
    // must be logged in
    return undefined;
  }

  const { selectedPaymentMethod } = paymentState;

  if (selectedPaymentMethod.loadState === LoadingState.error) {
    return { type: StepToShowType.error };
  } else if (
    selectedPaymentMethod.loadState === LoadingState.loaded &&
    checkoutProduct
  ) {
    const currentPaymentMethod = selectedPaymentMethod.currentPaymentMethod;
    if (currentPaymentMethod) {
      return {
        type: StepToShowType.withData,
        stepData: {
          step: ProductCheckoutStepCode.Finish,
          checkoutProduct,
          currentPaymentMethod,
          invoiceData,
        },
      };
    } else {
      return undefined;
    }
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData:
        selectedPaymentMethod.loadState !== LoadingState.loaded,
      loadProduct: !checkoutProduct,
    };
  }
}
