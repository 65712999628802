import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  LinkButton,
  View,
  PleaseWaitText,
  InvalidLinkText,
} from '@gts-common/client';
import { SignUpConfirmRequestBody } from '@gts-sm/utils';
import { TOKEN_LENGTH } from '@gts-common/client-server';
import { HOME_VIEW, LOGIN_VIEW } from '../../constants';

interface Props {
  execConfirmSignUp: (confirmData: SignUpConfirmRequestBody) => void;
  token: string | undefined;
  isLoggedIn: boolean;
}

export const SignUpConfirm = ({
  execConfirmSignUp,
  token,
  isLoggedIn,
}: Props) => {
  useEffect(() => {
    if (token && token.length === TOKEN_LENGTH) {
      execConfirmSignUp({ token });
    }
  }, [execConfirmSignUp, token]);

  if (token) {
    if (token.length !== TOKEN_LENGTH) {
      return <InvalidLinkText />;
    } else {
      return (
        <Box mt="2rem">
          <PleaseWaitText />
        </Box>
      );
    }
  }

  return (
    <View hasBackButton={false} title="Aktivierung erfolgreich" maxWidth="md">
      <Typography gutterBottom={true} align="center">
        Vielen Dank für Ihre Registrierung bei der get the service.
      </Typography>
      <Typography align="center">
        Ihr Account wurde erfolgreich Aktiviert.
      </Typography>

      <Box mt="1rem" textAlign="center">
        {isLoggedIn && <LinkButton to={HOME_VIEW} label="Zur Startseite" />}
        {!isLoggedIn && <LinkButton to={LOGIN_VIEW} label="Zur Anmeldung" />}
      </Box>
    </View>
  );
};
