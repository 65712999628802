import { ProductCheckoutStepCode } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForAccount(
  _params: GetStepToShowParams,
): StepToShow {
  return {
    type: StepToShowType.withData,
    stepData: { step: ProductCheckoutStepCode.Account },
  };
}
