import { Grid, Link, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Path } from 'history';
import { SignUpRequestBody } from '@gts-sm/utils';
import { checkoutSignUpValidation } from '../../../../validationSchemata';
import { UserInfoFields } from '../../../SignUp/SignUpForm/UserInfoFields';
import { ConditionsAndPrivacyFields } from '../../../SignUp/SignUpForm/ConditionsAndPrivacyFields';
import { signUpFormInitialValues } from '../../../../constants';

export const checkoutSignUpForm = 'checkout-signup-form';

interface Props {
  execSignUp: (signUp: SignUpRequestBody, redirectAfterSignUp: Path) => void;
  setShowNewUserForm: (show: boolean) => void;
  redirectAfterSignUp: Path;
}

export const NewCustomer = ({
  execSignUp,
  setShowNewUserForm,
  redirectAfterSignUp,
}: Props) => {
  return (
    <Grid container justify="space-around">
      <Grid item xs={12}>
        <Typography align="center" gutterBottom={true}>
          Schon Kunde?{' '}
          <Link component="button" onClick={() => setShowNewUserForm(false)}>
            Hier anmelden.
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Formik
          onSubmit={(signUpData: SignUpRequestBody) =>
            execSignUp(signUpData, redirectAfterSignUp)
          }
          initialValues={signUpFormInitialValues}
          validationSchema={checkoutSignUpValidation}
        >
          <Form noValidate={true} id={checkoutSignUpForm}>
            <UserInfoFields />
            <ConditionsAndPrivacyFields />
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};
