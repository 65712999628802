import { View } from '@gts-common/client';
import { Formik } from 'formik';
import { GROUP_USER_VIEW } from '../../constants';
import { GroupUserBase } from '../../types';
import { groupUserValidation } from '../../validationSchemata';
import { GroupUserForm } from './GroupUserForm';

const initialGroupUserValues: GroupUserBase = {
  username: '',
  password: '',
};

interface Props {
  groupId: string;
  execAddGroupUser: (groupId: string, groupUser: GroupUserBase) => void;
}

export const GroupUserAdd = ({ groupId, execAddGroupUser }: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={GROUP_USER_VIEW}
      backButtonLabel="Benutzerverwaltung"
      title="Benutzer anlegen"
      maxWidth="sm"
    >
      <Formik
        onSubmit={(values) => execAddGroupUser(groupId, values)}
        initialValues={initialGroupUserValues}
        validationSchema={groupUserValidation}
      >
        <GroupUserForm isEdit={false} />
      </Formik>
    </View>
  );
};
