"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnreachableError = void 0;
/**
 * Can be used for exhaustive checks.
 *
 * E.g. in the default: branch of a switch statement. Or after if/else-if if there are no variants left to check
 *
 * In those cases the typescript compiler will give an error if the cases or the if/else-if checks are not exhaustive.
 */
class UnreachableError extends Error {
    constructor(value) {
        super(String(value));
    }
}
exports.UnreachableError = UnreachableError;
