import { initialState } from '../initialState';
import {
  Actions,
  SET_HAS_AVV,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { GroupState } from '../types';

export function groupReducer(
  state: GroupState = initialState.group,
  action: Actions,
): GroupState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return action.payload.group;

    case LOGIN_CHECK_SUCCEEDED:
      return action.payload.group;

    case LOGOUT_SUCCEEDED:
      return initialState.group;

    case SET_HAS_AVV:
      if (state.available) {
        return {
          ...state,
          hasAvv: true,
        };
      }
      return state;

    default:
      return state;
  }
}
