import { Route, Switch } from 'react-router-dom';
import { LoginStatus } from '@gts-common/client';
import { EditUserRequestBody } from '@gts-sm/utils';
import {
  SIGN_UP_NOT_CONFIRMED_VIEW,
  HOME_VIEW,
  LOGIN_VIEW,
  PASSWORD_RESET_VIEW,
  PRODUCT_CHECKOUT_VIEW,
  SIGN_UP_CONFIRM_VIEW,
  SIGN_UP_VIEW,
  SIGN_UP_EMAIL_SENT_VIEW,
  CONFIRM_PAYMENT_PROVIDER_CHANGE_VIEW,
  REQUEST_PASSWORD_RESET_MAIL_VIEW,
  AVV_VIEW,
  PERSONAL_DATA_VIEW,
  GROUP_VIEW,
  GROUP_USER_VIEW,
  CONFIRMATION_VIEW,
  INVOICES_VIEW,
  SUBSCRIPTIONS_VIEW,
  SERVICE_INSTANCE_CHECKOUT_VIEW,
} from '../constants';
import { SignUpConfirmContainer } from '../containers/SignUpConfirm';
import { SignUpContainer } from '../containers/SignUp';
import { LoginContainer } from '../containers/Login';
import { PasswordResetContainer } from '../containers/PasswordReset';
import { HomeContainer } from '../containers/Home';
import { ProductCheckoutContainer } from '../containers/ProductCheckout';
import { SignUpNotConfirmedContainer } from '../containers/SignUpNotConfirmed';
import { SignUpEmailSentContainer } from '../containers/SignUpEmailSent';
import { PaymentProviderChangeConfirmContainer } from '../containers/PaymentProviderChangeConfirm';
import { RequestPasswordResetMailContainer } from '../containers/RequestPasswordResetMail';
import { TestBedReturnFromPaymentProvider } from '../testbeds/PaymentProviderChangeConfirm';
import { AVVContainer } from '../containers/AVV';
import { PersonalDataContainer } from '../containers/PersonaData';
import { GroupContainer } from '../containers/Group';
import { GroupUserSettingsContainer } from '../containers/GroupUserSettings';
import { ConfirmationContainer } from '../containers/Confirmation';
import { InvoicesContainer } from '../containers/Invoices';
import { SubscriptionsContainer } from '../containers/Subscriptions';
import { ServiceInstanceCheckoutContainer } from '../containers/ServiceInstanceCheckout';
import { NotFoundRoute, PrivateRoute, PublicRoute } from './Routes';

interface Props {
  execPasswordChange: (passwordChangeObject: EditUserRequestBody) => void;
  loginStatus: LoginStatus;
  isEmailConfirmed: boolean;
}
export const MainRouter = ({ loginStatus, isEmailConfirmed }: Props) => {
  let testBedRoutes: Array<JSX.Element>;
  if (process.env.NODE_ENV === 'development') {
    testBedRoutes = [
      <Route key="tb_ret_from_p_p" path="/testbed/return_from_payment_provider">
        <TestBedReturnFromPaymentProvider />
      </Route>,
    ];
  } else {
    testBedRoutes = [];
  }

  return (
    <Switch>
      {/* Only for logged out users */}
      <PublicRoute loginStatus={loginStatus} path={SIGN_UP_VIEW}>
        <SignUpContainer />
      </PublicRoute>
      <PublicRoute loginStatus={loginStatus} path={LOGIN_VIEW}>
        <LoginContainer />
      </PublicRoute>

      {/* For logged out and logged in users */}
      <Route path={SIGN_UP_CONFIRM_VIEW}>
        <SignUpConfirmContainer />
      </Route>
      <Route path={PRODUCT_CHECKOUT_VIEW}>
        <ProductCheckoutContainer />
      </Route>
      <Route path={CONFIRM_PAYMENT_PROVIDER_CHANGE_VIEW}>
        <PaymentProviderChangeConfirmContainer />
      </Route>
      <Route path={PASSWORD_RESET_VIEW}>
        <PasswordResetContainer />
      </Route>
      <Route path={REQUEST_PASSWORD_RESET_MAIL_VIEW}>
        <RequestPasswordResetMailContainer />
      </Route>

      {/* Only for logged in users */}

      {/* Set isEmailConfirmed to true otherwise we wouldn't be able to display the contents of the route */}
      <PrivateRoute
        isEmailConfirmed={true}
        loginStatus={loginStatus}
        path={SIGN_UP_NOT_CONFIRMED_VIEW}
      >
        <SignUpNotConfirmedContainer />
      </PrivateRoute>
      {/* Set isEmailConfirmed to true otherwise we wouldn't be able to display the contents of the route */}
      <PrivateRoute
        isEmailConfirmed={true}
        loginStatus={loginStatus}
        path={SIGN_UP_EMAIL_SENT_VIEW}
      >
        <SignUpEmailSentContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={PERSONAL_DATA_VIEW}
      >
        <PersonalDataContainer />
      </PrivateRoute>
      {/* Set isEmailConfirmed to true otherwise we wouldn't be able to display the contents of the route to a user that created an account in the checkout process */}
      <PrivateRoute
        isEmailConfirmed={true}
        loginStatus={loginStatus}
        path={CONFIRMATION_VIEW}
      >
        <ConfirmationContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        exact={true}
        path={HOME_VIEW}
      >
        <HomeContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={AVV_VIEW}
      >
        <AVVContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={GROUP_VIEW}
      >
        <GroupContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={GROUP_USER_VIEW}
      >
        <GroupUserSettingsContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={INVOICES_VIEW}
      >
        <InvoicesContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={SUBSCRIPTIONS_VIEW}
      >
        <SubscriptionsContainer />
      </PrivateRoute>
      <PrivateRoute
        isEmailConfirmed={isEmailConfirmed}
        loginStatus={loginStatus}
        path={SERVICE_INSTANCE_CHECKOUT_VIEW}
      >
        <ServiceInstanceCheckoutContainer />
      </PrivateRoute>

      {testBedRoutes}
      <NotFoundRoute loginStatus={loginStatus} />
    </Switch>
  );
};
