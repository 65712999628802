import { View, AddButton } from '@gts-common/client';
import { GroupUser } from '@gts-sm/utils';
import { Box, List, Paper, Typography } from '@material-ui/core';
import { HOME_VIEW } from '../../constants';
import { GroupUserItem } from './GroupUserItem';

interface Props {
  groupUsers: ReadonlyArray<GroupUser>;
  groupNumber: number;
  goToEditGroupUser: (groupUser: GroupUser) => void;
  execDeleteGroupUser: (groupUserId: string) => void;
  goToAddGroupUser: () => void;
}

export const GroupUserSettingsList = ({
  groupUsers,
  groupNumber,
  goToEditGroupUser,
  execDeleteGroupUser,
  goToAddGroupUser,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Benutzerverwaltung"
      maxWidth="sm"
    >
      <Paper
        variant="outlined"
        square
        style={{ padding: '1rem', marginBottom: '2rem' }}
      >
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>
          Firmennummer für Anmeldung: {groupNumber}
        </Typography>
        <List>
          {groupUsers.map((user: GroupUser) => (
            <GroupUserItem
              key={user.groupUserId}
              groupUser={user}
              execDeleteGroupUser={() => execDeleteGroupUser(user.groupUserId)}
              goToEditGroupUser={() => goToEditGroupUser(user)}
            />
          ))}
        </List>
        <Box>
          <AddButton onClick={goToAddGroupUser} label="Benutzer anlegen" />
        </Box>
      </Paper>
    </View>
  );
};
