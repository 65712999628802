"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dbString = exports.signedDateString = exports.companyString = exports.replaceMultiple = exports.replaceAll = exports.getTemplateString = void 0;
const specialString = '%%%%';
function getTemplateString(string) {
    return `${specialString}${string}${specialString}`;
}
exports.getTemplateString = getTemplateString;
function replaceAll(text, oldString, newString) {
    // The given variable oldString is defined by us so no security issue here
    return text.replace(
    // eslint-disable-next-line security-node/non-literal-reg-expr
    new RegExp(getTemplateString(oldString), 'g'), String(newString));
}
exports.replaceAll = replaceAll;
function replaceMultiple(text, replacements) {
    return Object.keys(replacements).reduce((prev, oldString) => replaceAll(prev, oldString, replacements[oldString]), text);
}
exports.replaceMultiple = replaceMultiple;
exports.companyString = 'company';
exports.signedDateString = 'signed_date';
exports.dbString = 'datenschutzbeauftragter';
