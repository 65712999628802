export const serverStatusErrors: { [index: number]: string } = {
  1001: 'Diese E-Mail-Adresse wird bereits genutzt. Falls es Ihre E-Mail-Adresse ist, versuchen Sie sich anzumelden oder nutzen Sie die Passwort Vergessen Funktion.',
  1002: 'Dieser Account wurde noch nicht aktiviert.',
  1003: 'Das aktuelle Passwort ist falsch.',
  1004: 'Falsche E-Mail-Adresse oder Passwort. Falls Sie Ihr Passwort vergessen haben, nutzen Sie die Passwort Vergessen Funktion.',
  1005: 'Dieser Service ist zurzeit nicht verfügbar.',
  1006: 'Der Link ist abgelaufen. Bitte fordern Sie einen neuen Link zum zurücksetzen des Passwortes an.',
  1007: 'Die Daten konnten nicht vom externen Zahlungsdienstleister abgerufen werden.',
  1008: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  1009: 'Dieser Benutzername existiert bereits.',
  1010: 'Falsche Gruppe, Benutzername oder Password.',
};
