import Grid from '@material-ui/core/Grid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { TextField } from '@gts-common/client';

export const InvoiceFields = () => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justify="space-around">
      <Grid item xs={12} sm={9}>
        <TextField
          name="companyName"
          autoFocus={false}
          label="Firma"
          required={true}
          autoComplete="organization"
        />
        <TextField
          name="streetAndNumber"
          label="Straße und Hausnummer"
          required={true}
          autoComplete="street-address"
        />
        <Grid container spacing={isSmOrSmaller ? 0 : 2}>
          <Grid item xs={12} md={4}>
            <TextField
              name="zip"
              label="Postleitzahl"
              required={true}
              autoComplete="postal-code"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="city"
              label="Stadt"
              required={true}
              autoComplete="address-level2"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
