import { ForwardButton, WarningText } from '@gts-common/client';
import { ServiceInstance } from '@gts-sm/utils';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StarRateIcon from '@material-ui/icons/StarRate';
import { ServiceCode } from '@gts-common/client-server';
import { Link as RouterLink } from 'react-router-dom';
import { SUBSCRIPTIONS_VIEW } from '../../constants';

function getCardSubheader(code: ServiceCode) {
  if (code === ServiceCode.FREE_TABLE) {
    // \xAD is a soft hyphen
    return 'Online Tisch-Reservierung\xADsystem';
  } else if (code === ServiceCode.NEXT_SURVEY) {
    return 'Online Bewertungs\xADsystem';
  } else {
    return '';
  }
}

function getServiceInstanceIcon(code: ServiceCode) {
  if (code === ServiceCode.FREE_TABLE) {
    return <RestaurantIcon />;
  } else if (code === ServiceCode.NEXT_SURVEY) {
    return <StarRateIcon />;
  } else {
    return null;
  }
}

interface Props {
  serviceInstance: ServiceInstance;
  goToProduct: (serviceInstanceId: string) => void;
  disabled: boolean;
}

export const ServiceInstanceCard = ({
  serviceInstance,
  goToProduct,
  disabled,
}: Props) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={serviceInstance.serviceTitle}
        subheader={getCardSubheader(serviceInstance.serviceCode)}
        action={
          <IconButton
            onClick={() => goToProduct(serviceInstance.serviceInstanceId)}
          >
            {getServiceInstanceIcon(serviceInstance.serviceCode)}
          </IconButton>
        }
      />
      <CardContent>
        <Typography gutterBottom={true}>
          Servicenummer: {serviceInstance.serviceInstanceNumber}
        </Typography>
        {serviceInstance.isReadOnly && (
          <WarningText variant="body1" align="left">
            Sie können nur lesend auf den Service zugreifen. Sie können{' '}
            <Link component={RouterLink} to={SUBSCRIPTIONS_VIEW}>
              hier
            </Link>{' '}
            Ihr Abonnement erneuern.
          </WarningText>
        )}
      </CardContent>
      <CardActions>
        <Box textAlign="right" width="100%">
          <ForwardButton
            onClick={() => goToProduct(serviceInstance.serviceInstanceId)}
            disabled={disabled}
            label="öffnen"
          />
        </Box>
      </CardActions>
    </Card>
  );
};
