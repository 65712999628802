import {
  AddGroupUserRequestBody,
  ChangeGroupUserPasswordRequestBody,
  GetGroupUsersResponse,
  GroupUser,
  GROUP_USERS_MANAGE,
} from '@gts-sm/utils';
import {
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';

import { serverComm } from '../serverComm';
import { GROUP_USER_VIEW } from '../constants';
import { GroupUserBase } from '../types';
import {
  Actions,
  GotDeletedUserAction,
  GotGroupUsersAction,
  GOT_DELETED_USER,
  GOT_GROUP_USERS,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execPush } from './navigation';

export const deleteGroupUser = (groupUserId: string): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execDeleteRequest(`${GROUP_USERS_MANAGE}/${groupUserId}`)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotDeletedUser(groupUserId));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export function deleteGroupUserDecision(groupUserId: string) {
  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: ModalType.DECISION_MODAL,
    title: 'Benutzer löschen',
    body:
      'Wollen Sie den Benutzer wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
    extraProps: {
      confirmAction: deleteGroupUser(groupUserId),
    },
  };

  return execOpenMessage(modal);
}

function gotDeletedUser(groupUserId: string): GotDeletedUserAction {
  return {
    type: GOT_DELETED_USER,
    payload: { groupUserId },
  };
}

export const addGroupUser = (
  groupId: string,
  groupUser: GroupUserBase,
): Thunk<Actions> => {
  return (dispatch) => {
    const data: AddGroupUserRequestBody = {
      groupId,
      username: groupUser.username.trim().toLowerCase(),
      password: groupUser.password,
    };

    dispatch(startServerRequest());
    serverComm
      .execPostRequest<Record<string, never>, AddGroupUserRequestBody>(
        GROUP_USERS_MANAGE,
        data,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(getGroupUsers(groupId));
            dispatch(execPush(GROUP_USER_VIEW));
            dispatch(
              serverRequestSucceeded('Benutzer wurde erfolgreich angelegt'),
            );
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export const editGroupUser = (
  groupUserId: string,
  groupUser: GroupUserBase,
): Thunk<Actions> => {
  return (dispatch) => {
    const data: ChangeGroupUserPasswordRequestBody = {
      newPassword: groupUser.password,
    };
    dispatch(startServerRequest());
    serverComm
      .execPatchRequest<
        Record<string, never>,
        ChangeGroupUserPasswordRequestBody
      >(`${GROUP_USERS_MANAGE}/${groupUserId}`, data)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(execPush(GROUP_USER_VIEW));
            dispatch(
              serverRequestSucceeded(
                'Das Passwort wurde erfolgreich geändert.',
              ),
            );
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export const getGroupUsers = (groupId: string): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execGetRequest<GetGroupUsersResponse>(`${GROUP_USERS_MANAGE}/${groupId}`)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotGroupUsers(resp.body.users));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

function gotGroupUsers(
  groupUsers: ReadonlyArray<GroupUser>,
): GotGroupUsersAction {
  return {
    type: GOT_GROUP_USERS,
    payload: { groupUsers },
  };
}
