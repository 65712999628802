import { AcceptedAVV, Company } from '@gts-sm/utils';
import { useEffect } from 'react';
import { Link, Box, Typography } from '@material-ui/core';
import { Button } from '@gts-common/client';

interface Props {
  groupId: string;
  avvList: ReadonlyArray<AcceptedAVV>;
  company: Company;
  hasServiceThatNeedsAvv: boolean;
  execGetAllAVVContracts: (groupId: string) => void;
  execShowAvv: () => void;
}

export const AVVList = ({
  groupId,
  avvList,
  company,
  hasServiceThatNeedsAvv,
  execGetAllAVVContracts,
  execShowAvv,
}: Props) => {
  useEffect(() => execGetAllAVVContracts(groupId), [
    execGetAllAVVContracts,
    groupId,
  ]);

  const buttonDisabled = !company.companyName || !hasServiceThatNeedsAvv;

  return (
    <>
      {avvList.map((avvItem: AcceptedAVV) => (
        <Box key={avvItem.filename}>
          <Link
            href={avvItem.downloadUrl}
            download={avvItem.filename}
            underline="hover"
          >
            Auftragsverarbeitungsvertrag (AV-Vertrag) vom {avvItem.acceptedAt}
          </Link>
        </Box>
      ))}

      {!company.companyName && (
        <Box mt="1rem">
          <Typography>
            Sie können den Auftragsverarbeitungsvertrag (AV-Vertrag) erst
            abschließen, wenn die Rechnungsadresse hinterlegt ist. Ihre
            Adressdaten werden für den Abschluß des AV-Vertrages benötigt.
          </Typography>
        </Box>
      )}

      {!hasServiceThatNeedsAvv && (
        <Box mt="1rem">
          <Typography>
            Sie haben keinen Service gebucht, der einen
            Auftragsverarbeitungsvertrag (AV-Vertrag) benötigt.
          </Typography>
        </Box>
      )}

      <Box mt="1rem">
        <Button
          onClick={execShowAvv}
          disabled={buttonDisabled}
          label="AV-Vertrag abschließen"
        />
      </Box>
    </>
  );
};
