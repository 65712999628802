import { push, replace } from 'connected-react-router';
import { NavigationTo } from '../types';
import { Actions, Thunk } from './reduxActionTypes';

export function execReplace(to: NavigationTo | undefined): Thunk<Actions> {
  return (dispatch) => {
    // Without the ifs typescript complains
    if (typeof to === 'string') {
      dispatch(replace(to));
    }

    if (typeof to === 'object') {
      dispatch(replace(to));
    }
  };
}

export function execPush(to: NavigationTo): Thunk<Actions> {
  return (dispatch) => {
    // Without the ifs typescript complains
    if (typeof to === 'string') {
      dispatch(push(to));
    }

    if (typeof to === 'object') {
      dispatch(push(to));
    }
  };
}
