import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { getInvoices } from '../actions';
import { Actions } from '../actions/reduxActionTypes';
import { Invoices } from '../components/Invoices/Invoices';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  const invoices = state.invoices.list;

  return {
    invoices,
    groupId: state.group.groupId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execGetInvoices(groupId: string) {
    dispatch(getInvoices(groupId));
  },
});

export const InvoicesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Invoices);
