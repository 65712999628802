import {
  AVV_PATH,
  GetAVVContractsResponse,
  GetAvvHtmlTemplateResponse,
  PostAvvRequestBody,
} from '@gts-sm/utils';
import { push } from 'connected-react-router';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';

import { CLIENT_DATE_TIME_FORMAT } from '@gts-common/client-server';
import { format, parseISO } from 'date-fns';
import { AVV } from '../types';
import { HOME_VIEW } from '../constants';
import { serverComm } from '../serverComm';
import {
  Actions,
  AVV_ACCEPTED,
  AvvAcceptedAction,
  GOT_AVV_TEMPLATE,
  GotAvvTemplateAction,
  Thunk,
  SET_HAS_AVV,
  setHasAvvAction,
  GotAVVContractsAction,
  GOT_AVV_CONTRACTS,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

export function avvAccepted(filename: string): AvvAcceptedAction {
  return {
    type: AVV_ACCEPTED,
    payload: filename,
  };
}

export function gotAvvTemplate(avv: string): GotAvvTemplateAction {
  return {
    type: GOT_AVV_TEMPLATE,
    payload: avv,
  };
}

export const acceptAvv = (groupId: string, avv: AVV): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    const data: PostAvvRequestBody = { groupId, ...avv };
    serverComm
      .execPostRequest<string, PostAvvRequestBody>(AVV_PATH, data)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(avvAccepted(resp.body));
            dispatch(serverRequestSucceeded());
            dispatch(setHasAvv());
            dispatch(push(`${HOME_VIEW}`));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export const loadAvvTemplate = (): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execGetRequest<GetAvvHtmlTemplateResponse>(AVV_PATH)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotAvvTemplate(resp.body.avv));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export function setHasAvv(): setHasAvvAction {
  return {
    type: SET_HAS_AVV,
  };
}

export const getAllAVVContracts = (groupId: string): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execGetRequest<GetAVVContractsResponse>(`${AVV_PATH}/${groupId}`)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotAVVContracts(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

function gotAVVContracts(avvs: GetAVVContractsResponse): GotAVVContractsAction {
  const avvList = avvs.avvList.map((avv) => {
    return {
      ...avv,
      acceptedAt: format(
        parseISO(avv.acceptedAt),
        `${CLIENT_DATE_TIME_FORMAT}:ss`,
      ),
    };
  });

  return {
    type: GOT_AVV_CONTRACTS,
    payload: { avvList },
  };
}
