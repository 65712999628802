import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { execPasswordChange } from '../actions';
import { Actions } from '../actions/reduxActionTypes';
import { Contact, EditUserRequestBody } from '../../../utils/build';
import { PersonalData } from '../components/PersonalData/PersonalData';
import { updateContact } from '../actions/userContact';

const mapStateToProps = (state: ReduxState) => {
  return {
    personalData: {
      contact: {
        firstName: state.app.firstName,
        lastName: state.app.lastName,
        email: state.app.email,
      },
    },
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execPasswordChange(passwordChangeObject: EditUserRequestBody) {
    dispatch(execPasswordChange(passwordChangeObject));
  },
  execUpdateContact(user: Contact) {
    dispatch(updateContact(user));
  },
});

export const PersonalDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalData);
