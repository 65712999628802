import { Form, Formik } from 'formik';
import { Box } from '@material-ui/core';
import { Path, LocationDescriptorObject } from 'history';
import { Button } from '@gts-common/client';
import { UserLoginRequestBody } from '@gts-sm/utils';
import { userLoginValidation } from '../../validationSchemata';
import { userLoginFormInitialValues } from '../../constants';
import { LoginFormUserFields } from './LoginFormUserFields';

const formId = 'login-form-user';

interface Props {
  execUserLogin: (
    loginData: UserLoginRequestBody,
    location: LocationDescriptorObject | Path | undefined,
  ) => void;
  previousLocation?: LocationDescriptorObject;
}

export const LoginFormUser = ({ execUserLogin, previousLocation }: Props) => (
  <Formik
    initialValues={userLoginFormInitialValues}
    onSubmit={(loginData) => execUserLogin(loginData, previousLocation)}
    validationSchema={userLoginValidation}
  >
    <Form noValidate={true} id={formId}>
      <LoginFormUserFields />
      <Box textAlign="center" mt="1rem">
        <Button submitForForm={formId} label="Anmelden" />
      </Box>
    </Form>
  </Formik>
);
