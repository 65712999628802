import { USERS_PASSWORD_PATH, EditUserRequestBody } from '@gts-sm/utils';

import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { HOME_VIEW } from '../constants';
import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execReplace } from './navigation';

export const execPasswordChange = (
  passwordChangeObject: EditUserRequestBody,
): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  serverComm
    .execPatchRequest<Record<string, never>, EditUserRequestBody>(
      USERS_PASSWORD_PATH,
      passwordChangeObject,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(execReplace(HOME_VIEW));
          dispatch(serverRequestSucceeded());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
