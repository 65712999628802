import { ServiceInstanceCheckoutStepCode } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForSelectPlan({
  checkoutServiceInstance,
}: GetStepToShowParams): StepToShow {
  if (checkoutServiceInstance) {
    return {
      type: StepToShowType.withData,
      stepData: {
        step: ServiceInstanceCheckoutStepCode.SelectPlan,
        checkoutServiceInstance,
      },
    };
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData: false,
      loadServiceInstance: true,
    };
  }
}
