import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { GroupUser } from '@gts-sm/utils';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { GROUP_USER_ADD_VIEW, GROUP_USER_EDIT_PATH } from '../constants';
import { deleteGroupUserDecision } from '../actions/groupUser';
import { GroupUserSettingsList } from '../components/GroupUser/GroupUserSettingsList';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }
  const groupUsers: ReadonlyArray<GroupUser> = state.groupUsers.users;

  return {
    groupNumber: state.group.groupNumber,
    groupUsers,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  goToEditGroupUser(groupUser: GroupUser) {
    dispatch(push(`${GROUP_USER_EDIT_PATH}/${groupUser.groupUserId}`));
  },
  execDeleteGroupUser(groupUserId: string) {
    dispatch(deleteGroupUserDecision(groupUserId));
  },
  goToAddGroupUser() {
    dispatch(push(GROUP_USER_ADD_VIEW));
  },
});

export const GroupUserSettingsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupUserSettingsList);
