import { View } from '@gts-common/client';
import { ServiceInstance } from '@gts-sm/utils';
import { Grid } from '@material-ui/core';
import { GroupStateFilled } from '../../types';
import { ServiceInstanceCard } from './ServiceInstanceCard';
import { AvvText } from './AvvText';
import { NoServiceText } from './NoServicesText';

interface Props {
  serviceInstances: Array<ServiceInstance>;
  group: GroupStateFilled;
  showAvvText: boolean;
  goToProduct: (serviceInstanceId: string) => void;
  goToAvv: () => void;
}

export const Home = ({
  serviceInstances,
  group,
  showAvvText,
  goToProduct,
  goToAvv,
}: Props) => {
  return (
    <View hasBackButton={false} title="Startseite" maxWidth="lg">
      {showAvvText && <AvvText goToAvv={goToAvv} />}
      {serviceInstances.length === 0 && <NoServiceText />}
      <Grid container spacing={2}>
        {serviceInstances.map((serviceInstance) => (
          <Grid
            xs={12}
            sm={6}
            md={4}
            item
            key={serviceInstance.serviceInstanceId}
          >
            <ServiceInstanceCard
              serviceInstance={serviceInstance}
              goToProduct={goToProduct}
              disabled={!group.hasAvv && serviceInstance.needsAvv}
            />
          </Grid>
        ))}
      </Grid>
    </View>
  );
};
