import { connect } from 'react-redux';

import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Path } from 'history';
import { LoginStatus } from '@gts-common/client';
import {
  UserLoginRequestBody,
  SignUpRequestBody,
  PlanForCheckout,
} from '@gts-sm/utils';

import { ProductCheckout } from '../components/Checkout/ProductCheckout/ProductCheckout';
import { PRODUCT_CHECKOUT_VIEW } from '../constants';
import {
  ProductCheckoutStepCode,
  ReduxState,
  SaveInvoiceAndPaymentDataFields,
} from '../types';

import { Actions } from '../actions/reduxActionTypes';
import {
  execFinishProductCheckout,
  execGetProductForCheckout,
  execUserLoginAndRedirect,
  execSignUpAndRedirect,
  execOpenServicePriceExample,
  productCheckoutSaveInvoiceAndPaymentData,
  loadInvoiceAndPaymentData,
} from '../actions';
import { execReplace } from '../actions/navigation';

const matchSelector = createMatchSelector<
  RouterRootState,
  { step: ProductCheckoutStepCode; productId: string }
>(PRODUCT_CHECKOUT_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const stepCodeFromUrl = match?.params.step;
  const productIdFromUrl = match?.params.productId;
  const isLoggedIn = state.app.loginStatus === LoginStatus.LOGGED_IN;
  const groupId = state.group.available ? state.group.groupId : undefined;
  const checkoutProduct = state.checkout.product;
  const paymentState = state.invoiceAndPaymentData;

  return {
    checkoutProduct,
    groupId,
    isLoggedIn,
    paymentState,
    productIdFromUrl,
    stepCodeFromUrl,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execGetProductForCheckout(productId: string) {
    dispatch(execGetProductForCheckout(productId));
  },
  execLoadInvoiceAndPaymentData() {
    dispatch(loadInvoiceAndPaymentData());
  },
  execUserLogin(loginData: UserLoginRequestBody, redirectAfterLogin: Path) {
    dispatch(execUserLoginAndRedirect(loginData, redirectAfterLogin));
  },
  execSignUp(signUp: SignUpRequestBody, redirectAfterSignup: Path) {
    dispatch(execSignUpAndRedirect(signUp, redirectAfterSignup));
  },
  execSavePaymentStep({
    data,
    groupId,
    nextStepUrl,
    productId,
  }: {
    data: SaveInvoiceAndPaymentDataFields;
    groupId: string;
    nextStepUrl: Path;
    productId: string;
  }) {
    dispatch(
      productCheckoutSaveInvoiceAndPaymentData({
        groupId,
        data,
        nextStepUrl,
        productId,
      }),
    );
  },
  execRedirect(redirectUrl: Path) {
    dispatch(execReplace(redirectUrl));
  },
  execFinishProductCheckout(productId: string) {
    dispatch(execFinishProductCheckout(productId));
  },
  execOpenServicePriceExample(plan: PlanForCheckout) {
    dispatch(execOpenServicePriceExample(plan));
  },
});

export const ProductCheckoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCheckout);
