import {
  ServiceInstanceCheckoutStepCode,
  LoadingState,
} from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForFinish({
  checkoutServiceInstance,
  paymentState,
  selectedPlan,
}: GetStepToShowParams): StepToShow | undefined {
  const { invoiceData } = paymentState;
  const { selectedPaymentMethod } = paymentState;

  if (selectedPaymentMethod.loadState === LoadingState.error) {
    return { type: StepToShowType.error };
  } else if (
    selectedPaymentMethod.loadState === LoadingState.loaded &&
    checkoutServiceInstance
  ) {
    const currentPaymentMethod = selectedPaymentMethod.currentPaymentMethod;

    // If the checkoutServiceInstance is defined but we have no plan it means that
    // the planId is either wrong or not there
    if (currentPaymentMethod && selectedPlan) {
      return {
        type: StepToShowType.withData,
        stepData: {
          step: ServiceInstanceCheckoutStepCode.Finish,
          checkoutServiceInstance,
          currentPaymentMethod,
          invoiceData,
          selectedPlan,
        },
      };
    } else {
      return undefined;
    }
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData:
        selectedPaymentMethod.loadState !== LoadingState.loaded,
      loadServiceInstance: !checkoutServiceInstance,
    };
  }
}
