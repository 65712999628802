import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router';
import {
  GlobalErrorCatcher,
  serviceTheme,
  setupGlobalErrorHandler,
} from '@gts-common/client';

import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { history, store } from './store';
import { AppContainer } from './containers/App';
import { checkLoggedIn } from './actions';
import { serverComm } from './serverComm';
import logo from './images/logo.png';

setupGlobalErrorHandler(serverComm, store);

// Redux doesn't understand thunk actions
// Convince it that it is okay to use that here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(checkLoggedIn() as any);

function renderRoot() {
  const Root = () => (
    <GlobalErrorCatcher
      serverComm={serverComm}
      headerTitle="get the service"
      logo={logo}
      domain={getUrl(ServiceCode.SERVICE_MANAGER, UrlType.FRONT_PAGE)}
    >
      <CssBaseline />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={serviceTheme}>
            <AppContainer />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </GlobalErrorCatcher>
  );

  ReactDOM.render(<Root />, document.getElementById('root'));
}

renderRoot();

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    renderRoot();
  });
}
