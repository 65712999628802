import { EmailInput, TextInput } from '@gts-common/client';
import { Grid } from '@material-ui/core';
import { Contact } from '@gts-sm/utils';

interface Props {
  contact: Contact;
}

export const ContactForm = ({ contact }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextInput
          name="contact.firstName"
          value={contact.firstName}
          label="Vorname"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          name="contact.lastName"
          value={contact.lastName}
          label="Nachname"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <EmailInput
          name="contact.email"
          value={contact.email}
          label="E-Mail-Adresse"
          disabled
        />
      </Grid>
    </Grid>
  );
};
