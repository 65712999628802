import { Box } from '@material-ui/core';

import { Form } from 'formik';
import { SaveButton, PasswordField } from '@gts-common/client';

const formId = 'password-change-form';

export const PasswordChangeForm = () => (
  <Form noValidate={true} id={formId}>
    <PasswordField
      name="password"
      label="Aktuelles Passwort"
      autoFocus={true}
      required={true}
      autoComplete="current-password"
    />
    <PasswordField
      name="newPassword"
      label="Neues Passwort"
      required={true}
      autoComplete="new-password"
    />
    <Box textAlign="center" mt="1rem">
      <SaveButton submitForForm={formId} label="Speichern" />
    </Box>
  </Form>
);
