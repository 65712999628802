import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { EditUserRequestBody } from '@gts-sm/utils';
import { App } from '../components/App';
import { execPasswordChange } from '../actions';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';

const mapStateToProps = (state: ReduxState) => {
  return {
    isEmailConfirmed: state.app.isEmailConfirmed,
    loginStatus: state.app.loginStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execPasswordChange(passwordChangeObject: EditUserRequestBody) {
    dispatch(execPasswordChange(passwordChangeObject));
  },
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
