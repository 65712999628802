import { Typography, Divider, Box, Grid } from '@material-ui/core';
import { Button } from '@gts-common/client';
import { PlanForCheckout } from '@gts-sm/utils';
import { NavButtons } from '../../NavButtons';
import { PlanDescription } from '../PlanDescription';
import { FinishStepData } from '../checkoutTypes';
import { PaymentDescription } from '../../PaymentDescription';
import { FinishStepInvoice } from '../../FinishStepInvoice';

interface Props {
  data: FinishStepData;
  prevStepUrl: string;
  execFinishServiceInstanceCheckout: (
    serviceInstanceId: string,
    planId: string,
  ) => void;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const FinishStep = ({
  data,
  prevStepUrl,
  execFinishServiceInstanceCheckout,
  execOpenServicePriceExample,
}: Props) => {
  const {
    currentPaymentMethod,
    checkoutServiceInstance,
    selectedPlan,
    invoiceData,
  } = data;

  return (
    <>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Beschreibung
        </Typography>
      </Box>
      <Box mb="1rem">
        <PlanDescription
          execOpenServicePriceExample={execOpenServicePriceExample}
          plan={checkoutServiceInstance.plans[0]}
        />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Service zum Reaktivieren
        </Typography>
      </Box>
      <Box mb="1rem">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography>Service:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{checkoutServiceInstance.title}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Nummer:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>
              {checkoutServiceInstance.serviceInstanceNumber}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Rechnungsdaten
        </Typography>
      </Box>
      <Box mb="1rem">
        <FinishStepInvoice invoice={invoiceData} />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Bezahldaten
        </Typography>
      </Box>
      <Box mb="1rem">
        <PaymentDescription paymentMethod={currentPaymentMethod} />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box textAlign="center">
        <Button
          label="kostenpflichtig Buchen"
          onClick={() =>
            execFinishServiceInstanceCheckout(
              checkoutServiceInstance.serviceInstanceId,
              selectedPlan.planId,
            )
          }
        />
      </Box>
      <NavButtons prevStepUrl={prevStepUrl} />
    </>
  );
};
