import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  RESEND_MAIL_PATH,
  ResendSignUpMailResponse,
  ResendSignUpRequestBody,
} from '@gts-sm/utils';

import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { signUpConfirmed } from './confirmSignUp';

export const execResendSignUpMail = (email: string): Thunk<Actions> => (
  dispatch,
) => {
  dispatch(startServerRequest());

  const data: ResendSignUpRequestBody = { email };
  serverComm
    .execPostRequest<ResendSignUpMailResponse, ResendSignUpRequestBody>(
      RESEND_MAIL_PATH,
      data,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          // User might have confirmed in an other tab/browser
          // Display the confirmed view in that case
          if (resp.body.isConfirmed) {
            dispatch(signUpConfirmed());
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(
              serverRequestSucceeded(
                `Der Bestätigungslink wurde erneut an Ihre E-Mail-Adresse ${email} gesendet.`,
              ),
            );
          }
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
