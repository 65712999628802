import {
  ErrorText,
  LinkButton,
  View,
  PleaseWaitText,
} from '@gts-common/client';
import { AcceptedAVV } from '@gts-sm/utils';
import { useEffect } from 'react';
import { HOME_VIEW } from '../../constants';
import {
  LoadingState,
  InvoiceAndPaymentDataState,
  SaveInvoiceAndPaymentDataFields,
} from '../../types';
import { AVV } from './AVV';
import { GroupInvoiceAndPaymentData } from './GroupInvoiceAndPaymentData';

interface Props {
  groupId: string;
  avvList: ReadonlyArray<AcceptedAVV>;
  paymentState: InvoiceAndPaymentDataState;
  hasServiceThatNeedsAvv: boolean;
  execGetAllAVVContracts: (groupId: string) => void;
  execShowAvv: () => void;
  execLoadInvoiceAndPaymentData: () => void;
  execSaveInvoiceAndPaymentData: (
    groupId: string,
    data: SaveInvoiceAndPaymentDataFields,
  ) => void;
}

function getContents({
  groupId,
  avvList,
  hasServiceThatNeedsAvv,
  execGetAllAVVContracts,
  execShowAvv,
  paymentState,
  execSaveInvoiceAndPaymentData,
}: Omit<Props, 'execLoadInvoiceAndPaymentData'>) {
  if (
    paymentState.paymentMethods.loadState === LoadingState.loaded &&
    paymentState.invoiceData.loadState === LoadingState.loaded
  ) {
    return (
      <>
        <GroupInvoiceAndPaymentData
          groupId={groupId}
          execSaveInvoiceAndPaymentData={execSaveInvoiceAndPaymentData}
          invoiceData={paymentState.invoiceData}
          paymentMethods={paymentState.paymentMethods}
        />
        <AVV
          groupId={groupId}
          avvList={avvList}
          invoiceData={paymentState.invoiceData}
          execGetAllAVVContracts={execGetAllAVVContracts}
          execShowAvv={execShowAvv}
          hasServiceThatNeedsAvv={hasServiceThatNeedsAvv}
        />
      </>
    );
  } else if (
    paymentState.paymentMethods.loadState === LoadingState.error ||
    paymentState.invoiceData.loadState === LoadingState.error
  ) {
    return (
      <>
        <ErrorText>
          Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später
          erneut.
        </ErrorText>
        <LinkButton to={HOME_VIEW} label="Zur Startseite" />
      </>
    );
  } else {
    return <PleaseWaitText />;
  }
}

export const Group = ({
  groupId,
  avvList,
  hasServiceThatNeedsAvv,
  execGetAllAVVContracts,
  execShowAvv,
  execLoadInvoiceAndPaymentData,
  paymentState,
  execSaveInvoiceAndPaymentData,
}: Props) => {
  useEffect(() => {
    execLoadInvoiceAndPaymentData();
  }, [execLoadInvoiceAndPaymentData]);

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Firmendaten"
      maxWidth="md"
    >
      {getContents({
        groupId,
        avvList,
        hasServiceThatNeedsAvv,
        execGetAllAVVContracts,
        execShowAvv,
        paymentState,
        execSaveInvoiceAndPaymentData,
      })}
    </View>
  );
};
