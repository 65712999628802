import {
  centToText,
  Invoice,
  NormalizedInvoicePaymentStatus,
} from '@gts-sm/utils';
import { useEffect } from 'react';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { View } from '@gts-common/client';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { HOME_VIEW } from '../../constants';

interface Props {
  groupId: string;
  invoices: ReadonlyArray<Invoice>;
  execGetInvoices: (groupId: string) => void;
}

function getPaymentStatusText(status: NormalizedInvoicePaymentStatus) {
  switch (status) {
    case NormalizedInvoicePaymentStatus.PENDING:
      return 'Offen';
    case NormalizedInvoicePaymentStatus.PAID:
      return 'Bezahlt';
    case NormalizedInvoicePaymentStatus.FAILED:
      return 'Unbezahlt';
    case NormalizedInvoicePaymentStatus.CANCELLED:
      return 'Unbezahlt';
    case NormalizedInvoicePaymentStatus.UNKNOWN:
      return 'Unbekannt';
  }
}

export const Invoices = ({ groupId, execGetInvoices, invoices }: Props) => {
  useEffect(() => execGetInvoices(groupId), [execGetInvoices, groupId]);

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Rechnungen"
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rechnungsnummer</TableCell>
              <TableCell align="right">Nettobetrag</TableCell>
              <TableCell align="right">Bruttobetrag</TableCell>
              <TableCell align="right">Rechnungsdatum</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Herunterladen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.invoiceId}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell align="right">
                  {centToText(invoice.netPriceCents)}
                </TableCell>
                <TableCell align="right">
                  {centToText(invoice.grossPriceCents)}
                </TableCell>
                <TableCell align="right">{invoice.invoiceDate}</TableCell>
                <TableCell align="right">
                  {getPaymentStatusText(invoice.paymentStatus)}
                </TableCell>
                <TableCell align="right">
                  <Link
                    href={invoice.downloadUrl}
                    download={invoice.invoiceNumber}
                  >
                    <CloudDownloadIcon />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  );
};
