import { PureComponent, MouseEvent } from 'react';
import { AccountMenu } from './AccountMenu';
import { HeaderAppBar } from './HeaderAppBar';
import { SettingsMenu } from './SettingsMenu';

interface Props {
  isEmailConfirmed: boolean;
  isUser: boolean;
  execLogout: () => void;
}

interface State {
  accountAnchor: Element | null;
  settingsAnchor: Element | null;
}

export class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      accountAnchor: null,
      settingsAnchor: null,
    };

    this.openAccountMenu = this.openAccountMenu.bind(this);
    this.openSettingsMenu = this.openSettingsMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openAccountMenu(event: MouseEvent) {
    this.setState({
      accountAnchor: event.currentTarget,
    });
  }

  openSettingsMenu(event: MouseEvent) {
    this.setState({
      accountAnchor: null,
      settingsAnchor: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      accountAnchor: null,
      settingsAnchor: null,
    });
  }

  render() {
    const { execLogout, isEmailConfirmed, isUser } = this.props;
    const { accountAnchor, settingsAnchor } = this.state;
    const isAccountMenuOpen = Boolean(accountAnchor);
    const isSettingsMenuOpen = Boolean(settingsAnchor);

    return (
      <>
        <HeaderAppBar
          openAccountMenu={this.openAccountMenu}
          openSettingsMenu={this.openSettingsMenu}
          isEmailConfirmed={isEmailConfirmed}
          isUser={isUser}
        />
        <AccountMenu
          anchor={accountAnchor}
          isOpen={isAccountMenuOpen}
          execLogout={execLogout}
          closeMenu={this.closeMenu}
        />
        <SettingsMenu
          anchor={settingsAnchor}
          isOpen={isSettingsMenuOpen}
          closeMenu={this.closeMenu}
        />
      </>
    );
  }
}
