import {
  UserLoginDataResponse,
  SignUpRequestBody,
  SIGN_UP_PATH,
} from '@gts-sm/utils';
import { LocationDescriptorObject, Path } from 'history';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { loginSucceeded } from './login';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execReplace } from './navigation';

export const execSignUpAndRedirect = (
  data: SignUpRequestBody,
  redirectAfterSignUp: LocationDescriptorObject | Path,
): Thunk<Actions> => (dispatch) => {
  dispatch(
    execSignUp(data, (dispatch) => {
      dispatch(execReplace(redirectAfterSignUp));
    }),
  );
};

export const execSignUp = (
  data: SignUpRequestBody,
  then: Thunk<Actions>,
): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  const trimmedData: SignUpRequestBody = {
    ...data,
    username: data.username.toLowerCase().trim(),
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
  };

  return serverComm
    .execPostRequest<UserLoginDataResponse, SignUpRequestBody>(
      SIGN_UP_PATH,
      trimmedData,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(serverRequestSucceeded());
          dispatch(loginSucceeded(resp.body));

          dispatch(then);
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
