import { PlanCode, PlanForCheckout } from '@gts-sm/utils';
import { ErrorText, LinkButton } from '@gts-common/client';
import { HOME_VIEW } from '../../../constants';
import { ServiceDescription } from '../ServiceDescriptions/ServiceDescription';

interface Props {
  plan: PlanForCheckout;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const PlanDescription = ({
  plan,
  execOpenServicePriceExample,
}: Props) => {
  if (plan.code === PlanCode.FREE_TABLE) {
    return (
      <ServiceDescription
        title="free-table"
        plan={plan}
        execOpenServicePriceExample={execOpenServicePriceExample}
      />
    );
  } else if (plan.code === PlanCode.NEXT_SURVEY) {
    return (
      <ServiceDescription
        title="next-survey"
        plan={plan}
        execOpenServicePriceExample={execOpenServicePriceExample}
      />
    );
  } else {
    return (
      <>
        <ErrorText>Unbekanntes Abonnement</ErrorText>
        <LinkButton to={HOME_VIEW} label="Zur Startseite" />
      </>
    );
  }
};
