import {
  CONFIRM_PAYMENT_PROVIDER_CHANGE_PATH,
  ConfirmPaymentProviderChangeRequestBody,
  ConfirmPaymentProviderChangeResponse,
  PaymentChangeInitiator,
  UnreachableError,
} from '@gts-sm/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { getStepUrlFromStepCode as getStepUrlFromProductCheckoutStepCode } from '../containers/helpers/productCheckoutSteps';
import { getStepUrlFromStepCode as getStepUrlFromServiceInstanceCheckoutStepCode } from '../containers/helpers/serviceInstanceCheckoutSteps';
import {
  ProductCheckoutStepCode,
  LoadingState,
  ServiceInstanceCheckoutStepCode,
} from '../types';
import { serverComm } from '../serverComm';
import { HOME_VIEW } from '../constants';
import {
  Actions,
  PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED,
  PaymentProviderChangeAcknowledgedAction,
  PAYMENT_PROVIDER_CHANGE_CONFIRMED,
  PaymentProviderChangeConfirmed,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execReplace } from './navigation';

function paymentProviderChangeAcknowledged(): PaymentProviderChangeAcknowledgedAction {
  return {
    type: PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED,
  };
}

// User saw the payment provider changed info page and can now
// continue what they were doing
export function acknowledgePaymentProviderChanged(): Thunk<Actions> {
  return (dispatch, getState) => {
    const returnFromPaymentProviderState = getState().invoiceAndPaymentData
      .paymentProviderChangeConfirm;

    if (returnFromPaymentProviderState.loadState !== LoadingState.loaded) {
      throw new Error(
        'Unexpected loading state: ' + returnFromPaymentProviderState.loadState,
      );
    }

    const { paymentChangeInitiatorData } = returnFromPaymentProviderState;
    // We have the current payment method but we don't pass it on here because if the
    // reducer sets the value and marks selectedPaymentMethod as loaded then the invoice data
    // are not loaded because the getStepDataForFinish only checks for selectedPaymentMethod + checkoutProduct
    // loaded and not invoiceData. This is something we can change in the future
    dispatch(paymentProviderChangeAcknowledged());

    let goToPath;

    if (
      paymentChangeInitiatorData.initiator ===
      PaymentChangeInitiator.PRODUCT_CHECKOUT
    ) {
      goToPath = getStepUrlFromProductCheckoutStepCode(
        ProductCheckoutStepCode.Finish,
        paymentChangeInitiatorData.productId,
      );
    } else if (
      paymentChangeInitiatorData.initiator ===
      PaymentChangeInitiator.SERVICE_INSTANCE_CHECKOUT
    ) {
      goToPath = getStepUrlFromServiceInstanceCheckoutStepCode(
        ServiceInstanceCheckoutStepCode.Finish,
        paymentChangeInitiatorData.serviceInstanceId,
        paymentChangeInitiatorData.planId,
      );
    } else if (
      paymentChangeInitiatorData.initiator ===
      PaymentChangeInitiator.GROUP_DATA_UPDATE
    ) {
      goToPath = HOME_VIEW;
    } else {
      throw new UnreachableError(paymentChangeInitiatorData);
    }

    dispatch(execReplace(goToPath));
  };
}

function paymentProviderChangeConfirmed(
  payload?: ConfirmPaymentProviderChangeResponse,
): PaymentProviderChangeConfirmed {
  return {
    type: PAYMENT_PROVIDER_CHANGE_CONFIRMED,
    payload: payload,
  };
}

export function confirmPaymentProviderChange(
  urlParams: string,
): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execPostRequest<
        ConfirmPaymentProviderChangeResponse,
        ConfirmPaymentProviderChangeRequestBody
      >(CONFIRM_PAYMENT_PROVIDER_CHANGE_PATH, { urlParams })
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(paymentProviderChangeConfirmed(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(paymentProviderChangeConfirmed());
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
