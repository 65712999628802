import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { GROUP_USER_ADD_VIEW, GROUP_USER_EDIT_VIEW } from '../../constants';
import { GroupUserEditContainer } from '../../containers/GroupUserEdit';
import { GroupUserAddContainer } from '../../containers/GroupUserAdd';
import { GroupUserSettingsListContainer } from '../../containers/GroupUserSettingsList';

interface Props {
  groupId: string;
  execGetGroupUsers: (groupId: string) => void;
}

export const GroupUserSettingsRoutes = ({
  groupId,
  execGetGroupUsers,
}: Props) => {
  useEffect(() => execGetGroupUsers(groupId), [execGetGroupUsers, groupId]);

  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={GROUP_USER_EDIT_VIEW}>
        <GroupUserEditContainer />
      </Route>
      <Route path={GROUP_USER_ADD_VIEW}>
        <GroupUserAddContainer />
      </Route>
      <Route path={path} exact={true}>
        <GroupUserSettingsListContainer />
      </Route>
    </Switch>
  );
};
