import { useState } from 'react';
import { Path } from 'history';
import { UserLoginRequestBody, SignUpRequestBody } from '@gts-sm/utils';

import { NavButtons } from '../../NavButtons';
import { checkoutSignUpForm, NewCustomer } from './NewCustomer';
import { checkoutLoginFormId, ExistingCustomer } from './ExistingCustomer';

interface Props {
  execUserLogin: (
    loginData: UserLoginRequestBody,
    redirectAfterLogin: Path,
  ) => void;
  execSignUp: (signUp: SignUpRequestBody, redirectAfterSignUp: Path) => void;
  prevStepUrl: Path;
  nextStepUrl: Path;
}

export const AccountStep = ({
  execUserLogin,
  execSignUp,
  prevStepUrl,
  nextStepUrl,
}: Props) => {
  const [showNewUserForm, setShowNewUserForm] = useState(true);

  if (showNewUserForm) {
    return (
      <>
        <NewCustomer
          execSignUp={execSignUp}
          setShowNewUserForm={setShowNewUserForm}
          redirectAfterSignUp={nextStepUrl}
        />
        <NavButtons
          forwardButtonLabel="Konto anlegen & Weiter"
          prevStepUrl={prevStepUrl}
          submitForForm={checkoutSignUpForm}
        />
      </>
    );
  } else {
    return (
      <>
        <ExistingCustomer
          execUserLogin={execUserLogin}
          setShowNewUserForm={setShowNewUserForm}
          redirectAfterLogin={nextStepUrl}
        />
        <NavButtons
          forwardButtonLabel="Anmelden & Weiter"
          prevStepUrl={prevStepUrl}
          submitForForm={checkoutLoginFormId}
        />
      </>
    );
  }
};
