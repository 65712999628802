import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ResetPasswordRequestBody } from '@gts-sm/utils';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { resetPassword } from '../actions';
import { PasswordReset } from '../components/PasswordReset/PasswordReset';
import { getQueryParamValueFromState } from './helpers/getQueryParamValueFromState';

const mapStateToProps = (state: ReduxState) => {
  return {
    token: getQueryParamValueFromState(state, 'token'),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execResetPassword(data: ResetPasswordRequestBody) {
    dispatch(resetPassword(data));
  },
});

export const PasswordResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordReset);
