import { Formik } from 'formik';

import { Container } from '@material-ui/core';
import { InvalidLinkText, View } from '@gts-common/client';
import { ResetPasswordRequestBody } from '@gts-sm/utils';
import { TOKEN_LENGTH } from '@gts-common/client-server';
import { passwordResetValidation } from '../../validationSchemata';
import { PasswordResetForm } from './PasswordResetForm';

const passwordReset: Omit<ResetPasswordRequestBody, 'token'> = {
  password: '',
};

interface Props {
  token: string | undefined;
  execResetPassword: (data: ResetPasswordRequestBody) => void;
}

export const PasswordReset = ({ token, execResetPassword }: Props) => {
  if (token) {
    if (token.length !== TOKEN_LENGTH) {
      return <InvalidLinkText />;
    } else {
      return (
        <View hasBackButton={false} title="Passwort zurücksetzen">
          <Container maxWidth="xs">
            <Formik
              onSubmit={(data) => execResetPassword({ ...data, token })}
              initialValues={passwordReset}
              validationSchema={passwordResetValidation}
            >
              {() => <PasswordResetForm />}
            </Formik>
          </Container>
        </View>
      );
    }
  } else {
    return <InvalidLinkText />;
  }
};
