import { EmailField, TextField, SaveButton } from '@gts-common/client';
import { Contact } from '@gts-sm/utils';
import { Grid, Box } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { contactValidation } from '../../validationSchemata';

interface Props {
  contact: Contact;
  execUpdateContact: (user: Contact) => void;
}

const names = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
};

const formId = 'contact-update-form';

export const ContactForm = ({ contact, execUpdateContact }: Props) => {
  return (
    <Formik
      onSubmit={execUpdateContact}
      initialValues={contact}
      validationSchema={contactValidation}
    >
      <Form id={formId} noValidate={true}>
        <Grid container>
          <Grid item xs={12}>
            <TextField name={names.firstName} label="Vorname" required />
          </Grid>
          <Grid item xs={12}>
            <TextField name={names.lastName} label="Nachname" required />
          </Grid>
          <Grid item xs={12}>
            <EmailField name={names.email} label="E-Mail-Adresse" disabled />
          </Grid>
        </Grid>
        <Box textAlign="center" mt="1rem">
          <SaveButton submitForForm={formId} label="Speichern" />
        </Box>
      </Form>
    </Formik>
  );
};
