import { initialState } from '../initialState';
import {
  Actions,
  GOT_SUBSCRIPTIONS,
  SUBSCRIPTION_CANCELLED,
} from '../actions/reduxActionTypes';
import { SubscriptionsState } from '../types';

export function subscriptionsReducer(
  state = initialState.subscriptions,
  action: Actions,
): SubscriptionsState {
  switch (action.type) {
    case GOT_SUBSCRIPTIONS:
      return {
        ...state,
        list: action.payload.serviceInstancesWithSubscriptions,
      };
    case SUBSCRIPTION_CANCELLED:
      return {
        ...state,
        list: state.list.map((serviceInstanceWithSubscriptions) => {
          if (
            serviceInstanceWithSubscriptions.serviceInstanceId ===
            action.payload.serviceInstanceId
          ) {
            const newSubscriptions = serviceInstanceWithSubscriptions.subscriptions.map(
              (subscription) => {
                if (
                  subscription.subscriptionId === action.payload.subscriptionId
                ) {
                  return {
                    ...subscription,
                    endDate: action.payload.endDate,
                    endReason: action.payload.endReason,
                  };
                } else {
                  return subscription;
                }
              },
            );

            // Each service instance can have at most one active subscription and it was just cancelled meaning the instance is now read only
            return {
              ...serviceInstanceWithSubscriptions,
              isReadOnly: true,
              subscriptions: newSubscriptions,
            };
          } else {
            return serviceInstanceWithSubscriptions;
          }
        }),
      };
    default:
      return state;
  }
}
