import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { execResendSignUpMail } from '../actions';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { SignUpEmailSent } from '../components/SignUp/SignUpEmailSent';

const mapStateToProps = (state: ReduxState) => {
  return {
    email: state.app.email,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execResendSignUpMail(email: string) {
    dispatch(execResendSignUpMail(email));
  },
});

export const SignUpEmailSentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpEmailSent);
