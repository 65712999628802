import { Box, Container, Typography } from '@material-ui/core';
import { LinkButton } from '@gts-common/client';
import { centToText } from '@gts-sm/utils';
import { SelectPlanStepData } from '../checkoutTypes';
import { getStepUrlFromStepCode } from '../../../../containers/helpers/serviceInstanceCheckoutSteps';
import { ServiceInstanceCheckoutStepCode } from '../../../../types';

interface Props {
  data: SelectPlanStepData;
}

export const SelectPlanStep = ({ data }: Props) => {
  const { checkoutServiceInstance } = data;
  return (
    <>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Abonnements für {checkoutServiceInstance.title}
        </Typography>
        <Typography variant="h6" component="h3" align="center">
          Servicenummer: {checkoutServiceInstance.serviceInstanceNumber}
        </Typography>
      </Box>
      <Container maxWidth="xs">
        {checkoutServiceInstance.plans.map((plan) => {
          return (
            <Box key={plan.planId} textAlign="center">
              <LinkButton
                to={getStepUrlFromStepCode(
                  ServiceInstanceCheckoutStepCode.Plan,
                  checkoutServiceInstance.serviceInstanceId,
                  plan.planId,
                )}
              >
                Basispreis {centToText(plan.netPricePerIntervalCents)} pro Monat
              </LinkButton>
            </Box>
          );
        })}
      </Container>
    </>
  );
};
