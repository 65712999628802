import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { requestPasswordResetMail } from '../actions';
import { RequestPasswordResetMail } from '../components/PasswordReset/RequestPasswordResetMail';
import { getQueryParamValueFromState } from './helpers/getQueryParamValueFromState';

const mapStateToProps = (state: ReduxState) => {
  return {
    email: getQueryParamValueFromState(state, 'email'),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execRequestPasswordResetMail(email: string) {
    dispatch(requestPasswordResetMail(email));
  },
});

export const RequestPasswordResetMailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPasswordResetMail);
