import { CheckboxField, Link } from '@gts-common/client';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';

export const ConditionsAndPrivacyFields = () => {
  const domain = getUrl(ServiceCode.SERVICE_MANAGER, UrlType.FRONT_PAGE);
  return (
    <>
      <CheckboxField name="privacy" type="checkbox" required={true}>
        Mit der Registrierung stimme ich den{' '}
        <Link target="_blank" to={domain + '/datenschutz.html'} external={true}>
          Datenschutzrichtlinien
        </Link>{' '}
        zu.
      </CheckboxField>
      <CheckboxField name="agb" type="checkbox" required={true}>
        Ich bestätige, dass ich mich als Unternehmer registriere und stimme den{' '}
        <Link target="_blank" to={domain + '/agb.html'} external={true}>
          AGB
        </Link>{' '}
        zu.
      </CheckboxField>
    </>
  );
};
