import { TICKET_PATH } from '@gts-sm/utils';

import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { GetTicketResponse } from '@gts-common/client-server';
import { serverComm } from '../serverComm';
import { Actions, Thunk } from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

export const execGetTicket = (serviceInstanceId: string): Thunk<Actions> => (
  dispatch,
) => {
  dispatch(startServerRequest());

  serverComm
    .execGetRequest<GetTicketResponse>(`${TICKET_PATH}/${serviceInstanceId}`)
    .then(
      (resp) => {
        if (resp.succeeded) {
          const { clientUrl, ticket } = resp.body;
          window.location.href = `${clientUrl}/?ticket=${encodeURIComponent(
            ticket,
          )}`;
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
