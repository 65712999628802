import {
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { VolumeGroupEntry, centToText } from '@gts-sm/utils';
import { salesTaxNotice } from '../constants';

interface Props {
  volumesGroup: ReadonlyArray<VolumeGroupEntry>;
}

const useStyles = makeStyles({
  listItemRoot: {
    justifyContent: 'space-between',
  },
  priceTypographyRoot: {
    width: '94px',
    textAlign: 'right',
  },
});

function getVolumeGroupEntryText(volumeGroupEntry: VolumeGroupEntry) {
  if (volumeGroupEntry.rangeEnd) {
    return `von ${volumeGroupEntry.rangeStart} bis ${volumeGroupEntry.rangeEnd} ${volumeGroupEntry.title}`;
  } else {
    return `ab ${volumeGroupEntry.rangeStart} ${volumeGroupEntry.title}`;
  }
}

export const ServicePriceTable = ({ volumesGroup }: Props) => {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemText primary="Verbrauchspreis (monatlich)" />
      </ListItem>

      {volumesGroup.map((volumeGroupEntry) => (
        <ListItem
          key={volumeGroupEntry.rangeStart}
          classes={{ root: classes.listItemRoot }}
        >
          <Typography>{getVolumeGroupEntryText(volumeGroupEntry)}</Typography>
          <Typography classes={{ root: classes.priceTypographyRoot }}>
            +{centToText(volumeGroupEntry.netPricePerIntervalCents, true)}
          </Typography>
        </ListItem>
      ))}

      <ListItem>
        <ListItemText primary={salesTaxNotice} />
      </ListItem>
    </>
  );
};
