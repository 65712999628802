import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button, ErrorText, PleaseWaitText, View } from '@gts-common/client';
import { Company, Contact } from '@gts-sm/utils';
import { ArrowForward } from '@material-ui/icons';
import { AVV as AVVType, InvoiceDataState, LoadingState } from '../../types';
import { HOME_VIEW } from '../../constants';
import { AVVText } from './AVVText';
import { AVVForm } from './AVVForm';

interface Props {
  contact: Contact;
  invoiceDataState: InvoiceDataState;
  avvTemplate: string | undefined;
  groupId: string;
  execAcceptAvv: (groupId: string, avv: AVVType) => void;
  execLoadAvvTemplate: () => void;
  execLoadInvoiceAndPaymentData: () => void;
}

export const AVV = ({
  contact,
  invoiceDataState,
  avvTemplate,
  groupId,
  execAcceptAvv,
  execLoadAvvTemplate,
  execLoadInvoiceAndPaymentData,
}: Props) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    execLoadAvvTemplate();
  }, [execLoadAvvTemplate]);

  useEffect(() => {
    if (invoiceDataState.loadState !== LoadingState.loaded) {
      execLoadInvoiceAndPaymentData();
    }
  }, [execLoadInvoiceAndPaymentData, invoiceDataState.loadState]);

  if (avvTemplate && invoiceDataState.loadState === LoadingState.loaded) {
    const company: Company = {
      companyName: invoiceDataState.companyName,
      streetAndNumber: invoiceDataState.streetAndNumber,
      zip: invoiceDataState.zip,
      city: invoiceDataState.city,
    };

    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="Auftragsverarbeitung"
        maxWidth="md"
      >
        <Typography variant="body1" gutterBottom={true}>
          Aufgrund rechtlicher Rahmenbedingungen der DSGVO muss vor der Nutzung
          von gebuchten Services, in denen personenbezogene Daten erfasst
          werden, der Auftragsverarbeitungsvertrag (AV-Vertrag) mit uns
          abgeschlossen sein. Solange der AV-Vertrag nicht abgeschlossen ist,
          kann leider der Service nicht genutzt werden.
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          Hier werden Ihre Daten angezeigt, die für den AV-Vertrag benötigt
          werden. Klicken Sie auf den Button &quot;Weiter&quot;, um sich den
          Vertrag anzuschauen und dann zu bestätigen.
        </Typography>
        <AVVForm contact={contact} company={company} />
        <Box textAlign="right" mt="1rem">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            endIcon={<ArrowForward />}
            label="Weiter"
          />
        </Box>
        <AVVText
          company={company}
          db={contact}
          isOpen={isOpen}
          handleClose={() => setOpen(false)}
          handleAccept={(avv) => execAcceptAvv(groupId, avv)}
          avvTemplate={avvTemplate}
        />
      </View>
    );
  } else if (invoiceDataState.loadState === LoadingState.error) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="Auftragsverarbeitung"
        maxWidth="md"
      >
        <ErrorText>
          Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später
          erneut.
        </ErrorText>
      </View>
    );
  } else {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={HOME_VIEW}
        backButtonLabel="Startseite"
        title="Auftragsverarbeitung"
        maxWidth="md"
      >
        <PleaseWaitText />;
      </View>
    );
  }
};
