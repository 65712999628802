import { Typography, Divider, Box } from '@material-ui/core';
import { Button } from '@gts-common/client';
import { PlanForCheckout } from '@gts-sm/utils';
import { NavButtons } from '../../NavButtons';
import { ProductDescription } from '../ProductDescription';
import { FinishStepData } from '../checkoutTypes';
import { PaymentDescription } from '../../PaymentDescription';
import { FinishStepInvoice } from '../../FinishStepInvoice';

interface Props {
  data: FinishStepData;
  prevStepUrl: string;
  execFinishProductCheckout: (productId: string) => void;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const FinishStep = ({
  data,
  prevStepUrl,
  execFinishProductCheckout,
  execOpenServicePriceExample,
}: Props) => {
  const { currentPaymentMethod, checkoutProduct, invoiceData } = data;
  return (
    <>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Produktbeschreibung
        </Typography>
      </Box>
      <Box mb="1rem">
        <ProductDescription
          execOpenServicePriceExample={execOpenServicePriceExample}
          product={checkoutProduct}
        />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Rechnungsdaten
        </Typography>
      </Box>
      <Box mb="1rem">
        <FinishStepInvoice invoice={invoiceData} />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box mb="1rem">
        <Typography variant="h6" component="h3" align="center">
          Bezahldaten
        </Typography>
      </Box>
      <Box mb="1rem">
        <PaymentDescription paymentMethod={currentPaymentMethod} />
      </Box>
      <Box mb="1rem">
        <Divider />
      </Box>
      <Box textAlign="center">
        <Button
          label="kostenpflichtig Buchen"
          onClick={() => execFinishProductCheckout(checkoutProduct.productId)}
        />
      </Box>
      <NavButtons prevStepUrl={prevStepUrl} />
    </>
  );
};
