import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RouterAction } from 'connected-react-router';
import { CommonActions } from '@gts-common/client';

import {
  Company,
  ConfirmPaymentProviderChangeResponse,
  Contact,
  GetAVVContractsResponse,
  GetCheckoutProductResponse,
  GetInvoiceAndPaymentDataResponse,
  GroupUser,
  Invoice,
  PaymentMethod,
  SubscriptionEndReason,
  GetCheckoutServiceInstanceResponse,
  SaveInvoiceAndPaymentDataResponseOk,
  ServiceInstanceWithSubscriptions,
} from '@gts-sm/utils';

import { LoginData, ReduxState } from '../types';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_CHECK_SUCCEEDED = 'LOGIN_CHECK_SUCCEEDED';
export const LOGIN_CHECK_FAILED = 'LOGIN_CHECK_FAILED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';

export const SIGN_UP_CONFIRMED = 'SIGN_UP_CONFIRMED';

export const AVV_ACCEPTED = 'AVV_ACCEPTED';
export const GOT_AVV_TEMPLATE = 'GOT_AVV_TEMPLATE';
export const SET_HAS_AVV = 'SET_HAS_AVV';
export const GOT_AVV_CONTRACTS = 'GOT_AVV_CONTRACTS';

// checkout
export const GOT_CHECKOUT_PRODUCT = 'GOT_CHECKOUT_PRODUCT';
export const GOT_CHECKOUT_SERVICE_INSTANCE = 'GOT_CHECKOUT_SERVICE_INSTANCE';

// invoice and payment data related events
export const INVOICE_AND_PAYMENT_DATA_LOADED =
  'INVOICE_AND_PAYMENT_DATA_LOADED';
export const INVOICE_AND_PAYMENT_DATA_SAVED = 'INVOICE_AND_PAYMENT_DATA_SAVED';
export const PAYMENT_PROVIDER_CHANGE_CONFIRMED =
  'PAYMENT_PROVIDER_CHANGE_CONFIRMED';
export const PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED =
  'PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED';

export const CONTACT_UPDATED = 'CONTACT_UPDATED';

// group users
export const GOT_DELETED_USER = 'GOT_DELETED_USER';
export const GOT_GROUP_USERS = 'GOT_GROUP_USERS';

// subscriptions
export const GOT_SUBSCRIPTIONS = 'GOT_SUBSCRIPTIONS';
export const SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED';

// invoices
export const GOT_INVOICES = 'GOT_INVOICES';

export interface LoginSucceededAction extends Action {
  type: typeof LOGIN_SUCCEEDED;
  payload: LoginData;
}

export interface LoginFailedAction extends Action {
  type: typeof LOGIN_FAILED;
}

export interface LoginCheckFailedAction extends Action {
  type: typeof LOGIN_CHECK_FAILED;
}

export interface LoginCheckSucceededAction extends Action {
  type: typeof LOGIN_CHECK_SUCCEEDED;
  payload: LoginData;
}

export interface LogoutSucceededAction extends Action {
  type: typeof LOGOUT_SUCCEEDED;
}

export interface SignUpConfirmedAction extends Action {
  type: typeof SIGN_UP_CONFIRMED;
}

export interface AvvAcceptedAction extends Action {
  type: typeof AVV_ACCEPTED;
  payload: string;
}

export interface GotAvvTemplateAction extends Action {
  type: typeof GOT_AVV_TEMPLATE;
  payload: string;
}

export interface setHasAvvAction extends Action {
  type: typeof SET_HAS_AVV;
}

export interface GotAVVContractsAction extends Action {
  type: typeof GOT_AVV_CONTRACTS;
  payload: GetAVVContractsResponse;
}

export interface GotCheckoutProduct extends Action {
  type: typeof GOT_CHECKOUT_PRODUCT;
  payload: GetCheckoutProductResponse;
}

export interface GotCheckoutServiceInstance extends Action {
  type: typeof GOT_CHECKOUT_SERVICE_INSTANCE;
  payload: GetCheckoutServiceInstanceResponse;
}

// invoice and payment data related events
export interface InvoiceAndPaymentDataLoaded extends Action {
  type: typeof INVOICE_AND_PAYMENT_DATA_LOADED;
  payload: GetInvoiceAndPaymentDataResponse;
}

export interface InvoiceAndPaymentDataSaved extends Action {
  type: typeof INVOICE_AND_PAYMENT_DATA_SAVED;
  payload: {
    invoiceData: Company;
    newPaymentMethod: PaymentMethod;
    changePaymentMethod: SaveInvoiceAndPaymentDataResponseOk['changePaymentMethod'];
  };
}

export interface PaymentProviderChangeConfirmed extends Action {
  type: typeof PAYMENT_PROVIDER_CHANGE_CONFIRMED;
  payload: ConfirmPaymentProviderChangeResponse | undefined;
}

export interface PaymentProviderChangeAcknowledgedAction extends Action {
  type: typeof PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED;
}

export interface UpdatedContactAction extends Action {
  type: typeof CONTACT_UPDATED;
  payload: Omit<Contact, 'email'>;
}

export interface GotDeletedUserAction extends Action {
  type: typeof GOT_DELETED_USER;
  payload: { groupUserId: string };
}

export interface GotGroupUsersAction extends Action {
  type: typeof GOT_GROUP_USERS;
  payload: { groupUsers: ReadonlyArray<GroupUser> };
}

export interface GotSubscriptionsAction extends Action {
  type: typeof GOT_SUBSCRIPTIONS;
  payload: {
    serviceInstancesWithSubscriptions: ReadonlyArray<ServiceInstanceWithSubscriptions>;
  };
}

export interface SubscriptionCancelledAction extends Action {
  type: typeof SUBSCRIPTION_CANCELLED;
  payload: {
    serviceInstanceId: string;
    subscriptionId: string;
    endDate: string;
    endReason: SubscriptionEndReason;
  };
}

export interface GotInvoicesAction extends Action {
  type: typeof GOT_INVOICES;
  payload: { invoices: ReadonlyArray<Invoice> };
}

export type Actions =
  | LoginSucceededAction
  | LoginFailedAction
  | LoginCheckSucceededAction
  | LoginCheckFailedAction
  | LogoutSucceededAction
  | SignUpConfirmedAction
  | RouterAction
  | AvvAcceptedAction
  | GotAvvTemplateAction
  | setHasAvvAction
  | PaymentProviderChangeAcknowledgedAction
  | GotCheckoutProduct
  | GotCheckoutServiceInstance
  | InvoiceAndPaymentDataLoaded
  | InvoiceAndPaymentDataSaved
  | PaymentProviderChangeConfirmed
  | UpdatedContactAction
  | GotAVVContractsAction
  | GotDeletedUserAction
  | GotGroupUsersAction
  | GotSubscriptionsAction
  | SubscriptionCancelledAction
  | GotInvoicesAction
  | CommonActions;

export type Thunk<A extends Action> = ThunkAction<void, ReduxState, unknown, A>;
