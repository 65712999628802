import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { PaymentProviderChangeConfirm } from '../components/InvoiceAndPaymentData/PaymentProviderChangeConfirm';
import {
  acknowledgePaymentProviderChanged,
  confirmPaymentProviderChange,
} from '../actions/confirmPaymentProviderChange';

const mapStateToProps = (state: ReduxState) => {
  return {
    state: state.invoiceAndPaymentData.paymentProviderChangeConfirm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAcknowledgePaymentProviderChanged() {
    dispatch(acknowledgePaymentProviderChanged());
  },
  execConfirmPaymentProviderChange(urlParams: string) {
    dispatch(confirmPaymentProviderChange(urlParams));
  },
});

export const PaymentProviderChangeConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentProviderChangeConfirm);
