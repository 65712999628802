import { LoginStatus } from '@gts-common/client';
import { SessionType } from '@gts-sm/utils';
import { LoadingState, ReduxState } from './types';

export const initialState: Omit<ReduxState, 'router' | 'common'> = {
  app: {
    loginStatus: LoginStatus.PENDING,
    avvTemplate: undefined,
    isEmailConfirmed: false,
    email: '',
    firstName: '',
    lastName: '',
    userType: SessionType.USER,
  },
  group: { available: false },
  invoiceAndPaymentData: {
    invoiceData: { loadState: LoadingState.loadNeeded },
    paymentProviderChangeConfirm: { loadState: LoadingState.loadNeeded },
    paymentMethods: { loadState: LoadingState.loadNeeded },
    selectedPaymentMethod: { loadState: LoadingState.loadNeeded },
  },
  serviceInstances: {
    serviceInstances: { items: [], entities: {} },
  },
  checkout: {
    product: undefined,
    serviceInstance: undefined,
  },
  avv: {
    acceptedList: [],
  },
  groupUsers: {
    users: [],
  },
  invoices: {
    list: [],
  },
  subscriptions: {
    list: [],
  },
};
