import Interweave from 'interweave';
import {
  Company,
  companyString,
  Contact,
  signedDateString,
  dbString,
} from '@gts-sm/utils';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Button } from '@gts-common/client';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AVV } from '../../types';
import { AVVMatcher } from './AVVMatcher';

function getContactString(contact: Contact) {
  return `${contact.firstName} ${contact.lastName}, ${contact.email}`;
}

interface Props extends AVV {
  isOpen: boolean;
  handleClose: () => void;
  handleAccept: (avv: AVV) => void;
  avvTemplate: string;
  company: Company;
  db: Contact;
}

export const AVVText = ({
  isOpen,
  handleClose,
  handleAccept,
  avvTemplate,
  company,
  db,
}: Props) => {
  const matchers = [
    new AVVMatcher(companyString, () => (
      <>
        {company.companyName}
        <br />
        {company.streetAndNumber}
        <br /> {company.zip} {company.city}
      </>
    )),
    new AVVMatcher(signedDateString, () => (
      <>{format(new Date(), CLIENT_DATE_FORMAT)}</>
    )),
    new AVVMatcher(dbString, () => <>{getContactString(db)}</>),
  ];
  const styleString = avvTemplate.slice(
    avvTemplate.indexOf('<style>') + 7,
    avvTemplate.indexOf('</style>'),
  );

  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose} scroll="paper" maxWidth="md">
        <style>{styleString}</style>
        <DialogTitle>Auftragsverarbeitung</DialogTitle>
        <DialogContent dividers>
          <Interweave content={avvTemplate} matchers={matchers} />
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CancelIcon />}
            onClick={() => handleClose()}
            color="secondary"
            label="Schließen"
          />
          <Button
            label="Akzeptieren"
            onClick={() => {
              handleClose();
              handleAccept({ db, company });
            }}
            startIcon={<CheckCircleIcon />}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};
