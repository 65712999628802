import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { AVV as AVVType, ReduxState } from '../types';
import { AVV } from '../components/AVV/AVV';
import {
  acceptAvv,
  loadAvvTemplate,
  loadInvoiceAndPaymentData,
} from '../actions';
import { Actions } from '../actions/reduxActionTypes';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  const group = state.group;
  const app = state.app;

  const invoiceDataState = state.invoiceAndPaymentData.invoiceData;

  return {
    contact: {
      firstName: app.firstName,
      lastName: app.lastName,
      email: app.email,
    },
    invoiceDataState,
    groupId: group.groupId,
    avvTemplate: state.app.avvTemplate,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAcceptAvv(groupId: string, avv: AVVType) {
    dispatch(acceptAvv(groupId, avv));
  },
  execLoadAvvTemplate() {
    dispatch(loadAvvTemplate());
  },
  execLoadInvoiceAndPaymentData() {
    dispatch(loadInvoiceAndPaymentData());
  },
});

export const AVVContainer = connect(mapStateToProps, mapDispatchToProps)(AVV);
