import { Path } from 'history';
import { NavButtons } from '../../NavButtons';
import { PaymentStepData } from '../checkoutTypes';
import { InvoiceAndPaymentData } from '../../../InvoiceAndPaymentData/InvoiceAndPaymentData';
import { SaveInvoiceAndPaymentDataFields } from '../../../../types';

interface Props {
  data: PaymentStepData;
  execSavePaymentStep: (params: {
    data: SaveInvoiceAndPaymentDataFields;
    groupId: string;
    nextStepUrl: Path;
    productId: string;
  }) => void;
  prevStepUrl: Path;
  nextStepUrl: Path;
}

const formId = 'product-checkout-invoice-and-payment-form';

export const PaymentStep = ({
  data,
  execSavePaymentStep,
  prevStepUrl,
  nextStepUrl,
}: Props) => {
  const { checkoutProduct, groupId, invoiceData, paymentMethods } = data;

  return (
    <>
      <InvoiceAndPaymentData
        formId={formId}
        invoiceData={invoiceData}
        paymentMethods={paymentMethods}
        execSubmit={(paymentFields) => {
          execSavePaymentStep({
            groupId,
            data: paymentFields,
            productId: checkoutProduct.productId,
            nextStepUrl,
          });
        }}
      />
      <NavButtons
        forwardButtonLabel="Weiter"
        prevStepUrl={prevStepUrl}
        submitForForm={formId}
      />
    </>
  );
};
