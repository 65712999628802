import {
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  CancelSubscriptionRequestBody,
  GetSubscriptionsResponse,
  ServiceInstanceWithSubscriptions,
  SUBSCRIPTION_CANCEL_PATH,
  SubscriptionEndReason,
  SUBSCRIPTIONS_PATH,
} from '@gts-sm/utils';
import {
  CLIENT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from '@gts-common/client-server';
import { addDays, format, parse, subDays } from 'date-fns';
import { serverComm } from '../serverComm';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import {
  Actions,
  GOT_SUBSCRIPTIONS,
  GotSubscriptionsAction,
  SUBSCRIPTION_CANCELLED,
  SubscriptionCancelledAction,
  Thunk,
} from './reduxActionTypes';

function gotSubscriptions(
  serviceInstancesWithSubscriptions: ReadonlyArray<ServiceInstanceWithSubscriptions>,
): GotSubscriptionsAction {
  const serviceInstancesWithSubscriptionsForClient = serviceInstancesWithSubscriptions.map(
    (serviceInstanceWithSubscription) => {
      const subscriptions = serviceInstanceWithSubscription.subscriptions.map(
        (subscription) => {
          return {
            ...subscription,
            startDate: format(
              parse(subscription.startDate, SERVER_DATE_FORMAT, new Date()),
              CLIENT_DATE_FORMAT,
            ),
            // Note that on the server endDate is the first day the service is no longer available. For the user we want to display the last day it is available
            endDate:
              subscription.endDate !== null
                ? format(
                    subDays(
                      parse(
                        subscription.endDate,
                        SERVER_DATE_FORMAT,
                        new Date(),
                      ),
                      1,
                    ),
                    CLIENT_DATE_FORMAT,
                  )
                : null,
          };
        },
      );

      return {
        ...serviceInstanceWithSubscription,
        subscriptions,
      };
    },
  );

  return {
    type: GOT_SUBSCRIPTIONS,
    payload: {
      serviceInstancesWithSubscriptions: serviceInstancesWithSubscriptionsForClient,
    },
  };
}

function subscriptionCancelled(
  serviceInstanceId: string,
  subscriptionId: string,
  endDate: string,
): SubscriptionCancelledAction {
  return {
    type: SUBSCRIPTION_CANCELLED,
    payload: {
      serviceInstanceId,
      subscriptionId,
      endDate,
      endReason: SubscriptionEndReason.USER_CANCELLED,
    },
  };
}

export function getSubscriptions(groupId: string): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execGetRequest<GetSubscriptionsResponse>(
        `${SUBSCRIPTIONS_PATH}/${groupId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotSubscriptions(resp.body.serviceInstances));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function cancelSubscription(
  serviceInstanceId: string,
  subscriptionId: string,
): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    const now = new Date();
    // Cancel effective tomorrow. User can still use the subscription today
    const subscriptionEndDatetime = addDays(now, 1);
    const subscriptionEndDate = format(
      subscriptionEndDatetime,
      SERVER_DATE_FORMAT,
    );
    const data: CancelSubscriptionRequestBody = {
      subscriptionEndDate,
    };
    serverComm
      .execPatchRequest<Record<string, never>, CancelSubscriptionRequestBody>(
        `${SUBSCRIPTION_CANCEL_PATH}/${subscriptionId}`,
        data,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(
              subscriptionCancelled(
                serviceInstanceId,
                subscriptionId,
                format(now, CLIENT_DATE_FORMAT),
              ),
            );
            dispatch(serverRequestSucceeded('Abo erfolgreich gekündigt'));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function cancelSubscriptionDecision(
  serviceInstanceId: string,
  subscriptionId: string,
) {
  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: ModalType.DECISION_MODAL,
    title: 'Abonnement kündigen',
    body:
      'Wollen Sie das Abonnement wirklich kündigen? Falls Sie das tun, können Sie den dazugehörigen Service nur eingeschränkt nutzen.',
    extraProps: {
      confirmAction: cancelSubscription(serviceInstanceId, subscriptionId),
    },
  };

  return execOpenMessage(modal);
}
