import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { EmailField, PasswordField, TextField } from '@gts-common/client';

export const UserInfoFields = () => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container spacing={isSmOrSmaller ? 0 : 2}>
        <Grid item xs={12} md={6}>
          <TextField
            name="firstName"
            label="Vorname"
            autoFocus={true}
            required={true}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="lastName"
            label="Nachname"
            required={true}
            autoComplete="family-name"
          />
        </Grid>
      </Grid>
      <EmailField
        name="username"
        label="E-Mail-Adresse"
        required={true}
        autoComplete="username"
      />
      <PasswordField
        name="password"
        label="Passwort"
        required={true}
        autoComplete="new-password"
      />
    </>
  );
};
