import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { SignUpConfirmRequestBody } from '@gts-sm/utils';
import { LoginStatus } from '@gts-common/client';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { execConfirmSignUp } from '../actions';
import { SignUpConfirm } from '../components/SignUpConfirmation/SignUpConfirm';
import { getQueryParamValueFromState } from './helpers/getQueryParamValueFromState';

const mapStateToProps = (state: ReduxState) => {
  return {
    token: getQueryParamValueFromState(state, 'token'),
    isLoggedIn: state.app.loginStatus === LoginStatus.LOGGED_IN,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execConfirmSignUp(confirm: SignUpConfirmRequestBody) {
    dispatch(execConfirmSignUp(confirm));
  },
});

export const SignUpConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpConfirm);
