import { Box, ButtonGroup } from '@material-ui/core';

import { LinkButton, View } from '@gts-common/client';
import { SignUpRequestBody } from '@gts-sm/utils';
import { LOGIN_VIEW, REQUEST_PASSWORD_RESET_MAIL_VIEW } from '../../constants';
import { SignUpForm } from './SignUpForm/SignUpForm';

interface Props {
  execSignUp: (signUp: SignUpRequestBody) => void;
}

export const SignUp = ({ execSignUp }: Props) => {
  return (
    <View
      hasBackButton={true}
      title="Registrierung"
      backButtonLabel="Anmeldung"
      navigateBackTo={LOGIN_VIEW}
      maxWidth="md"
    >
      <SignUpForm execSignUp={execSignUp} />
      <Box textAlign="center" mt="1rem">
        <ButtonGroup variant="text">
          <LinkButton to={LOGIN_VIEW} label="Anmeldung" />
          <LinkButton
            to={REQUEST_PASSWORD_RESET_MAIL_VIEW}
            label="Passwort vergessen"
          />
        </ButtonGroup>
      </Box>
    </View>
  );
};
