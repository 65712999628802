import { Box } from '@material-ui/core';
import { Button } from '@gts-common/client';

interface Props {
  execResendSignUpMail: (email: string) => void;
  email: string;
}

export const ResendSignUpMail = ({ execResendSignUpMail, email }: Props) => {
  return (
    <Box mt="1rem" textAlign="center">
      <Button
        onClick={() => execResendSignUpMail(email)}
        label="E-Mail erneut senden"
      />
    </Box>
  );
};
