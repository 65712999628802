import { NotFoundText, View } from '@gts-common/client';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import { GROUP_USER_VIEW } from '../../constants';
import { GroupUserBase } from '../../types';
import { groupUserValidation } from '../../validationSchemata';
import { GroupUserForm } from './GroupUserForm';

interface Props {
  groupUserId: string;
  initialGroupUserValues: GroupUserBase;
  userNotExists: boolean;
  execEditGroupUser: (groupUserId: string, groupUser: GroupUserBase) => void;
}

export const GroupUserEdit = ({
  groupUserId,
  initialGroupUserValues,
  userNotExists,
  execEditGroupUser,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={GROUP_USER_VIEW}
      backButtonLabel="Benutzerverwaltung"
      title="Passwort neu setzen"
      maxWidth="sm"
    >
      {!userNotExists && (
        <Formik
          onSubmit={(values) => execEditGroupUser(groupUserId, values)}
          initialValues={initialGroupUserValues}
          validationSchema={groupUserValidation}
        >
          <GroupUserForm isEdit={true} />
        </Formik>
      )}
      {userNotExists && (
        <Box>
          <NotFoundText>
            Der Benutzer existiert nicht. Bitte gehen Sie zur Benutzerverwaltung
            zurück und wählen den Benutzer aus, für den Sie das Passwort neu
            setzen möchten.
          </NotFoundText>
        </Box>
      )}
    </View>
  );
};
