import { Form, Formik } from 'formik';
import { Box } from '@material-ui/core';
import { Path, LocationDescriptorObject } from 'history';
import { Button } from '@gts-common/client';
import { GroupUserLoginRequestBody } from '@gts-sm/utils';
import { groupUserLoginValidation } from '../../validationSchemata';
import { groupUserLoginFormInitialValues } from '../../constants';
import { LoginFormGroupUserFields } from './LoginFormGroupUserFields';

const formId = 'login-form-group-user';

interface Props {
  execGroupUserLogin: (
    loginData: GroupUserLoginRequestBody,
    location: LocationDescriptorObject | Path | undefined,
  ) => void;
  previousLocation?: LocationDescriptorObject;
}

export const LoginFormGroupUser = ({
  execGroupUserLogin,
  previousLocation,
}: Props) => (
  <Formik
    initialValues={groupUserLoginFormInitialValues}
    onSubmit={(loginData) => execGroupUserLogin(loginData, previousLocation)}
    validationSchema={groupUserLoginValidation}
  >
    <Form noValidate={true} id={formId}>
      <LoginFormGroupUserFields />
      <Box textAlign="center" mt="1rem">
        <Button submitForForm={formId} label="Anmelden" />
      </Box>
    </Form>
  </Formik>
);
