import { Typography } from '@material-ui/core';

export const NoServiceText = () => {
  return (
    <Typography variant="h6" component="h3" align="center">
      Aktuell haben Sie noch keinen Service bei uns gebucht. Sobald Sie einen
      Service bei uns gebucht haben, wird dieser hier angezeigt.
    </Typography>
  );
};
