import { initialState } from '../initialState';
import {
  Actions,
  GOT_DELETED_USER,
  GOT_GROUP_USERS,
} from '../actions/reduxActionTypes';
import { GroupUsersState } from '../types';

export function groupUsersReducer(
  state: GroupUsersState = initialState.groupUsers,
  action: Actions,
): GroupUsersState {
  switch (action.type) {
    case GOT_DELETED_USER:
      return {
        ...state,
        users: state.users.filter(
          (user) => user.groupUserId !== action.payload.groupUserId,
        ),
      };

    case GOT_GROUP_USERS:
      return {
        ...state,
        users: action.payload.groupUsers,
      };
    default:
      return state;
  }
}
