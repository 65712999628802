import { centToText } from '@gts-sm/utils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Button } from '@gts-common/client';
import { Action } from 'redux';
import { salesTaxNotice } from '../constants';
import { ServicePriceExampleModalExtraProps } from '../../../actions/openServicePriceExampleModal';

interface Props {
  isOpen: boolean;
  onDialogAction: (action?: Action) => void;
  extraProps?: ServicePriceExampleModalExtraProps;
}

const useStyles = makeStyles({
  listItemRoot: {
    justifyContent: 'space-between',
  },
  priceTypographyRoot: {
    width: '94px',
    textAlign: 'right',
  },
});

export const ServicePriceExampleModal = ({
  isOpen,
  onDialogAction,
  extraProps,
}: Props) => {
  const classes = useStyles();

  if (extraProps === undefined) {
    throw new Error('extraProps cannot be undefined here');
  }

  const {
    sum,
    consumption,
    netPricePerIntervalEuros,
    volumesGroupForExample,
  } = extraProps;

  return (
    <Dialog onClose={() => onDialogAction()} open={isOpen}>
      <DialogTitle>Beispiel</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">
          Beispielrechnung mit <strong>{consumption}</strong>{' '}
          {volumesGroupForExample.title} im Monat.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Basispreis (monatlich)" />
            <ListItemSecondaryAction>
              <Typography>{netPricePerIntervalEuros}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{ root: classes.listItemRoot }}>
            <Typography>
              von {volumesGroupForExample.rangeStart} bis{' '}
              {volumesGroupForExample.rangeEnd} {volumesGroupForExample.title}
            </Typography>
            <Typography classes={{ root: classes.priceTypographyRoot }}>
              +
              {centToText(
                volumesGroupForExample.netPricePerIntervalCents,
                true,
              )}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Gesamtpreis" />
            <ListItemSecondaryAction>
              <Typography style={{ fontWeight: 'bold' }}>{sum}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary={salesTaxNotice} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onDialogAction()}
          color="primary"
          label="Schließen"
        />
      </DialogActions>
    </Dialog>
  );
};
