import { EmailField, PasswordField } from '@gts-common/client';

export const LoginFormUserFields = () => {
  return (
    <>
      <EmailField
        label="E-Mail-Adresse"
        name="username"
        autoFocus={true}
        autoComplete="username"
        required={true}
      />
      <PasswordField
        label="Passwort"
        name="password"
        autoComplete="current-password"
        required={true}
      />
    </>
  );
};
