import {
  GetInvoiceAndPaymentDataResponse,
  INVOICE_AND_PAYMENT_DATA_PATH,
} from '@gts-sm/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '../serverComm';
import {
  Actions,
  INVOICE_AND_PAYMENT_DATA_LOADED,
  InvoiceAndPaymentDataLoaded,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

function invoiceAndPaymentDataLoaded(
  payload: GetInvoiceAndPaymentDataResponse,
): InvoiceAndPaymentDataLoaded {
  return {
    type: INVOICE_AND_PAYMENT_DATA_LOADED,
    payload: payload,
  };
}

export function loadInvoiceAndPaymentData(): Thunk<Actions> {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.group.available) {
      throw new Error('Cannot load invoice and payment data if not logged in');
    }
    const groupId = state.group.groupId;

    dispatch(startServerRequest());
    serverComm
      .execGetRequest<GetInvoiceAndPaymentDataResponse>(
        `${INVOICE_AND_PAYMENT_DATA_PATH}/${groupId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(invoiceAndPaymentDataLoaded(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
