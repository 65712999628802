import {
  AddButton,
  PasswordField,
  SaveButton,
  TextField,
} from '@gts-common/client';
import { Grid, Box } from '@material-ui/core';
import { Form } from 'formik';

interface Props {
  isEdit: boolean;
}

const names = {
  username: 'username',
  password: 'password',
};

const formId = 'group-user-form';

export const GroupUserForm = ({ isEdit }: Props) => {
  return (
    <Form id={formId} noValidate={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name={names.username}
            autoFocus={!isEdit}
            label="Benutzername"
            disabled={isEdit}
            required
            hint="Der Benutzername kann nicht mehr geändert werden, sobald der Benutzer angelegt wurde."
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name={names.password}
            label="Passwort"
            required
            autoComplete="new-password"
          />
        </Grid>
      </Grid>
      <Box textAlign="center" mt="1rem">
        {!isEdit && (
          <AddButton submitForForm={formId} label="Benutzer anlegen" />
        )}
        {isEdit && (
          <SaveButton submitForForm={formId} label="Neues Passwort speichern" />
        )}
      </Box>
    </Form>
  );
};
