import { ProductCheckoutStepCode } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { getSteps } from '../../../../containers/helpers/productCheckoutSteps';
import { getStepDataForProduct } from './getStepDataForProduct';
import { getStepDataForAccount } from './getStepDataForAccount';
import { getStepDataForPayment } from './getStepDataForPayment';
import { getStepDataForFinish } from './getStepDataForFinish';

const getStepDataFunctions = {
  [ProductCheckoutStepCode.Product]: getStepDataForProduct,
  [ProductCheckoutStepCode.Account]: getStepDataForAccount,
  [ProductCheckoutStepCode.Payment]: getStepDataForPayment,
  [ProductCheckoutStepCode.Finish]: getStepDataForFinish,
};

/** Determines the step to show (may be before the step in state if the user navigated back) */
export function getStepToShow(params: GetStepToShowParams): StepToShow {
  const { initialStepCode, steps, stepReplacements } = getSteps(
    params.isLoggedIn,
  );

  const firstStepCode = steps[0].code;

  // use first step if no step code is given in URL
  const { stepCodeFromUrl = firstStepCode } = params;
  // replace step code if in replacement table
  const stepCode = stepReplacements[stepCodeFromUrl] ?? stepCodeFromUrl;

  let stepIndex = steps.findIndex(({ code }) => code === stepCode);
  if (stepIndex === -1) {
    // fallback to first step if step code is unknown
    stepIndex = 0;
  }

  let result;

  // try to get step data for given step, trying previous steps if unsuccessful
  for (
    let currentStepIndex = stepIndex;
    // the first step is not included in the loop and will be checked after the loop
    // to allow the typescript compiler to specially check that the first step never returns undefined
    result === undefined && currentStepIndex >= 1;
    currentStepIndex--
  ) {
    result = getStepDataFunctions[steps[currentStepIndex].code](params);
  }

  // now get data for the first step if this is the current step or the other steps didn't give data
  if (result === undefined) {
    // first step always has to return data
    result = getStepDataFunctions[initialStepCode](params);
  }

  return result;
}
