import { useState } from 'react';
import {
  NormalizedPaymentMethodStatus,
  PaymentChangeInitiator,
  PaymentMethodType,
  UnreachableError,
} from '@gts-sm/utils';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
  LoadingState,
  PaymentProviderChangeConfirmLoaded,
  PaymentProviderChangeConfirmLoadNeeded,
} from '../types';
import { PaymentProviderChangeConfirm } from '../components/InvoiceAndPaymentData/PaymentProviderChangeConfirm';

const stateError: PaymentProviderChangeConfirmLoadNeeded = {
  loadState: LoadingState.error,
};
const stateLoaded: PaymentProviderChangeConfirmLoaded = {
  loadState: LoadingState.loaded,
  paymentChangeInitiatorData: {
    initiator: PaymentChangeInitiator.GROUP_DATA_UPDATE,
  },
  groupId: 'grp1',
  currentPaymentMethod: {
    type: PaymentMethodType.debit,
    status: NormalizedPaymentMethodStatus.PENDING,
    data: {
      accountNumberEnding: '00',
      bankName: 'Demobank',
      accountHolderName: 'FOO BAR',
    },
  },
};

enum States {
  error,
  loaded,
}

function stringToState(value: string): States {
  const valNumber = Number(value);
  if (Object.values(States).includes(valNumber)) {
    return valNumber;
  } else {
    throw new Error('Unknown value: ' + value);
  }
}

export function TestBedReturnFromPaymentProvider() {
  const [selectedState, changeSelectedState] = useState(States.loaded);

  let state;
  switch (selectedState) {
    case States.error:
      state = stateError;
      break;
    case States.loaded:
      state = stateLoaded;
      break;
      break;
    default:
      throw new UnreachableError(selectedState);
  }

  function handler(name: string) {
    return (...params: Array<unknown>) => {
      // eslint-disable-next-line no-console
      console.log([name, ...params]);
    };
  }

  function handleSelectedStateChanged(e: React.ChangeEvent<HTMLInputElement>) {
    changeSelectedState(stringToState(e.target.value));
  }

  return (
    <div>
      <RadioGroup
        aria-label="Zahlungsmethode"
        name="paymentMethod"
        value={selectedState}
        onChange={handleSelectedStateChanged}
      >
        <FormControlLabel
          key="selstate_error"
          value={States.error}
          control={<Radio />}
          label="Error"
        />
        <FormControlLabel
          key="selstate_loaded"
          value={States.loaded}
          control={<Radio />}
          label="Loaded"
        />
      </RadioGroup>
      <PaymentProviderChangeConfirm
        execAcknowledgePaymentProviderChanged={handler(
          'execAcknowledgePaymentProviderChanged',
        )}
        execConfirmPaymentProviderChange={handler(
          'execConfirmPaymentProviderChange',
        )}
        state={state}
      />
    </div>
  );
}
