import { Grid, Typography } from '@material-ui/core';
import {
  PaymentMethod,
  PaymentMethodDebitData,
  PaymentMethodType,
  UnreachableError,
} from '@gts-sm/utils';

interface PaymentDescriptionProps {
  paymentMethod: PaymentMethod;
}

interface PaymentMethodDebitProps {
  paymentMethodData: PaymentMethodDebitData;
}
const PaymentMethodDebit = ({ paymentMethodData }: PaymentMethodDebitProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Typography>Bazahlart:</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>Lastschrift</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography>Konto:</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>…{paymentMethodData.accountNumberEnding}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography>Bank:</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>{paymentMethodData.bankName ?? 'N/A'}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography>Kontoinhaber:</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>{paymentMethodData.accountHolderName ?? 'N/A'}</Typography>
      </Grid>
    </Grid>
  );
};

export const PaymentDescription = ({
  paymentMethod,
}: PaymentDescriptionProps) => {
  switch (paymentMethod.type) {
    case PaymentMethodType.debit:
      return <PaymentMethodDebit paymentMethodData={paymentMethod.data} />;
    case PaymentMethodType.manual:
      throw new Error('Payment method "manual" not supported in this context');
    default:
      throw new UnreachableError(paymentMethod);
  }
};
