import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { SignUpRequestBody } from '@gts-sm/utils';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { execSignUpAndRedirect } from '../actions';
import { SignUp } from '../components/SignUp/SignUp';
import { SIGN_UP_EMAIL_SENT_VIEW } from '../constants';

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execSignUp(signUp: SignUpRequestBody) {
    dispatch(execSignUpAndRedirect(signUp, SIGN_UP_EMAIL_SENT_VIEW));
  },
});

export const SignUpContainer = connect(null, mapDispatchToProps)(SignUp);
