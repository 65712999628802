import { Typography } from '@material-ui/core';
import { View } from '@gts-common/client';
import { ResendSignUpMail } from '../ResendSignUpMail';

interface Props {
  execResendSignUpMail: (email: string) => void;
  email: string;
}

export const SignUpEmailSent = ({ email, execResendSignUpMail }: Props) => {
  return (
    <View hasBackButton={false} title="Registrierung" maxWidth="md">
      <Typography gutterBottom={true} align="justify">
        Vielen Dank für Ihre Registrierung bei der get the service mit Ihrer
        E-Mail-Adresse{` ${email}`}.
      </Typography>
      <Typography align="justify">
        Klicken Sie auf den Link in der Bestätigungsmail, um Ihre Registrierung
        abzuschließen. Es kann bis zu 15 Minuten dauern, bis Sie die E-Mail
        erhalten.
      </Typography>

      <ResendSignUpMail
        email={email}
        execResendSignUpMail={execResendSignUpMail}
      />
    </View>
  );
};
