import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Button } from '@gts-common/client';
import { centToText, PlanForCheckout } from '@gts-sm/utils';
import { ServicePriceTable } from './ServicePriceTable';

interface Props {
  title: string;
  plan: PlanForCheckout;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const ServiceDescription = ({
  title,
  plan,
  execOpenServicePriceExample,
}: Props) => {
  return (
    <>
      <Typography variant="h6" component="h3">
        {title}
      </Typography>
      <Box mt={2} mb={2}>
        <Typography>Vertragslaufzeit: unbefristet, täglich kündbar</Typography>
        <Typography>Abrechnung: monatlich nach erbrachter Leistung</Typography>
      </Box>
      <List>
        <ListItem>
          <ListItemText primary="Basispreis (monatlich)" />
          <ListItemSecondaryAction>
            <Typography>
              {centToText(plan.netPricePerIntervalCents, true)}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ServicePriceTable volumesGroup={plan.volumesGroup} />
      </List>
      <Box textAlign="right" mt="1rem">
        <Button
          color="secondary"
          onClick={() => execOpenServicePriceExample(plan)}
          label="Beispiel"
        />
      </Box>
    </>
  );
};
