import { PureComponent } from 'react';
import { Box, Container } from '@material-ui/core';
import {
  Footer,
  SimpleHeader,
  MessagesContainer,
  LoginStatus,
  PleaseWaitText,
  GlobalError,
  sendErrorToServer,
} from '@gts-common/client';
import { EditUserRequestBody } from '@gts-sm/utils';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import logo from '../images/logo.png';

import { HeaderContainer } from '../containers/Header';
import { HOME_VIEW } from '../constants';
import { serverComm } from '../serverComm';
import { MainRouter } from './MainRouter';
import { ServicePriceExampleModal } from './Checkout/ServiceDescriptions/ServicePriceExampleModal';

interface Props {
  execPasswordChange: (passwordChangeObject: EditUserRequestBody) => void;
  loginStatus: LoginStatus;
  isEmailConfirmed: boolean;
}

interface ReduxState {
  hasError: boolean;
}

const extraModals = {
  ServicePriceExampleModal: ServicePriceExampleModal,
};

export class App extends PureComponent<Props, ReduxState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    sendErrorToServer(serverComm, error);
  }

  renderHeader() {
    const { loginStatus } = this.props;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      return <HeaderContainer />;
    } else {
      return (
        <SimpleHeader to={HOME_VIEW} title="get the service" logoSrc={logo} />
      );
    }
  }

  renderRoutingContainer() {
    const { execPasswordChange, loginStatus, isEmailConfirmed } = this.props;

    if (loginStatus === LoginStatus.PENDING) {
      return (
        <Box mt="2rem">
          <PleaseWaitText />
        </Box>
      );
    } else {
      return (
        <MainRouter
          loginStatus={loginStatus}
          isEmailConfirmed={isEmailConfirmed}
          execPasswordChange={execPasswordChange}
        />
      );
    }
  }

  renderFooter() {
    const { loginStatus } = this.props;

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      return (
        <Footer
          domain={getUrl(ServiceCode.SERVICE_MANAGER, UrlType.FRONT_PAGE)}
        />
      );
    } else {
      return null;
    }
  }

  renderError() {
    return (
      <GlobalError
        to={HOME_VIEW}
        headerTitle="get the service"
        logo={logo}
        domain={getUrl(ServiceCode.SERVICE_MANAGER, UrlType.FRONT_PAGE)}
      />
    );
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return this.renderError();
    }

    return (
      <>
        {this.renderHeader()}
        {/* bottom margin is used to offset the footer height */}
        <Container disableGutters={true} style={{ marginBottom: '100px' }}>
          {this.renderRoutingContainer()}
        </Container>
        {this.renderFooter()}
        <MessagesContainer extraModals={extraModals} />
      </>
    );
  }
}
