import { ProductCheckoutStepCode, LoadingState } from '../../../../types';
import { GetStepToShowParams, StepToShow } from '../checkoutTypes';
import { StepToShowType } from '../../commonCheckoutTypes';

export function getStepDataForPayment({
  checkoutProduct,
  groupId,
  paymentState,
}: GetStepToShowParams): StepToShow | undefined {
  const { invoiceData, paymentMethods } = paymentState;

  if (groupId === undefined) {
    // must be logged in
    return undefined;
  }

  if (paymentMethods.loadState === LoadingState.error) {
    return { type: StepToShowType.error };
  } else if (
    paymentMethods.loadState === LoadingState.loaded &&
    invoiceData.loadState === LoadingState.loaded &&
    checkoutProduct
  ) {
    return {
      type: StepToShowType.withData,
      stepData: {
        step: ProductCheckoutStepCode.Payment,
        checkoutProduct,
        groupId,
        invoiceData,
        paymentMethods,
      },
    };
  } else {
    return {
      type: StepToShowType.loadNeeded,
      loadInvoiceAndPaymentData:
        paymentMethods.loadState !== LoadingState.loaded ||
        invoiceData.loadState !== LoadingState.loaded,
      loadProduct: !checkoutProduct,
    };
  }
}
