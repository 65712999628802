import { centToText, PlanForCheckout, VolumeGroupEntry } from '@gts-sm/utils';
import {
  execOpenMessage,
  ExtraModalMessage,
  MessageType,
} from '@gts-common/client';

export interface ServicePriceExampleModalExtraProps {
  sum: string;
  consumption: number;
  netPricePerIntervalEuros: string;
  volumesGroupForExample: VolumeGroupEntry;
}

function calculateConsumption(volumeGroup: VolumeGroupEntry) {
  if (volumeGroup.rangeEnd) {
    return Math.round((volumeGroup.rangeStart + volumeGroup.rangeEnd) / 2);
  } else {
    return volumeGroup.rangeStart;
  }
}

function calculateSumTotal(basePrice: number, consumptionPrice: number) {
  const sum = basePrice + consumptionPrice;
  return centToText(sum, true);
}

export function execOpenServicePriceExample(plan: PlanForCheckout) {
  const volumesGroupForExample = plan.volumesGroup[2];
  const consumption = calculateConsumption(volumesGroupForExample);
  const sum = calculateSumTotal(
    plan.netPricePerIntervalCents,
    volumesGroupForExample.netPricePerIntervalCents,
  );

  const extraProps: ServicePriceExampleModalExtraProps = {
    consumption,
    sum,
    netPricePerIntervalEuros: centToText(plan.netPricePerIntervalCents, true),
    volumesGroupForExample,
  };

  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: 'ServicePriceExampleModal',
    extraProps,
  };

  return execOpenMessage(modal);
}
