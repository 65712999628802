import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { SessionType } from '@gts-sm/utils';
import { Header } from '../components/Header/Header';
import { execLogout } from '../actions';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';

const mapStateToProps = (state: ReduxState) => ({
  isEmailConfirmed: state.app.isEmailConfirmed,
  isUser: state.app.userType === SessionType.USER,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execLogout() {
    dispatch(execLogout());
  },
});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
