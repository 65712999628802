import {
  CHECKOUT_PRODUCT_PATH,
  CHECKOUT_SERVICE_INSTANCE_PATH,
  GetCheckoutProductResponse,
  PostCheckoutProductRequestBody,
  PostCheckoutServiceInstanceRequestBody,
  GetCheckoutServiceInstanceResponse,
} from '@gts-sm/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '../serverComm';
import { CONFIRMATION_VIEW } from '../constants';
import {
  Actions,
  GOT_CHECKOUT_PRODUCT,
  GOT_CHECKOUT_SERVICE_INSTANCE,
  GotCheckoutProduct,
  GotCheckoutServiceInstance,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execPush } from './navigation';

function gotCheckoutProduct(
  product: GetCheckoutProductResponse,
): GotCheckoutProduct {
  return {
    type: GOT_CHECKOUT_PRODUCT,
    payload: product,
  };
}

export const execGetProductForCheckout = (
  productId: string,
): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execGetRequest<GetCheckoutProductResponse>(
        `${CHECKOUT_PRODUCT_PATH}/${productId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotCheckoutProduct(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export const execFinishProductCheckout = (
  productId: string,
): Thunk<Actions> => {
  return (dispatch, getState) => {
    dispatch(startServerRequest());

    const state = getState();

    if (!state.group.available) {
      throw new Error('Cannot finish product checkout if group is not loaded');
    }
    const groupId = state.group.groupId;

    serverComm
      .execPostRequest<Record<string, never>, PostCheckoutProductRequestBody>(
        CHECKOUT_PRODUCT_PATH,
        {
          groupId,
          productId,
        },
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(serverRequestSucceeded());
            dispatch(execPush(CONFIRMATION_VIEW));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

function gotCheckoutServiceInstance(
  serviceInstance: GetCheckoutServiceInstanceResponse,
): GotCheckoutServiceInstance {
  return {
    type: GOT_CHECKOUT_SERVICE_INSTANCE,
    payload: serviceInstance,
  };
}

export const execGetServiceInstanceForCheckout = (
  serviceInstanceId: string,
): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execGetRequest<GetCheckoutServiceInstanceResponse>(
        `${CHECKOUT_SERVICE_INSTANCE_PATH}/${serviceInstanceId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotCheckoutServiceInstance(resp.body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export const execFinishServiceInstanceCheckout = (
  serviceInstanceId: string,
  planId: string,
): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    serverComm
      .execPostRequest<
        Record<string, never>,
        PostCheckoutServiceInstanceRequestBody
      >(CHECKOUT_SERVICE_INSTANCE_PATH, {
        serviceInstanceId,
        planId,
      })
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(serverRequestSucceeded());
            dispatch(execPush(CONFIRMATION_VIEW));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};
