import { GroupUser } from '@gts-sm/utils';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MouseEvent } from 'react';

interface Props {
  groupUser: GroupUser;
  goToEditGroupUser: (event: MouseEvent) => void;
  execDeleteGroupUser: (event: MouseEvent) => void;
}

export const GroupUserItem = ({
  groupUser,
  goToEditGroupUser,
  execDeleteGroupUser,
}: Props) => {
  return (
    <ListItem divider>
      {groupUser.username}
      <ListItemSecondaryAction>
        <Tooltip title="Hier können Sie das Passwort neu setzen. Der Benutzername kann nicht geändert werden.">
          <IconButton onClick={goToEditGroupUser}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Diesen Benutzer löschen.">
          <IconButton onClick={execDeleteGroupUser}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
