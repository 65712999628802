import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { execPush } from '../actions/navigation';
import { HOME_VIEW, SIGN_UP_NOT_CONFIRMED_VIEW } from '../constants';
import { Confirmation } from '../components/Confirmation/Confirmation';
import { checkLoggedIn } from '../actions';

const mapStateToProps = (state: ReduxState) => {
  return {
    isEmailConfirmed: state.app.isEmailConfirmed,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  goToHome() {
    dispatch(execPush(HOME_VIEW));
    // Do a login check to get the newly booked product
    // We might want to change this in the future so that we get the serviceInstance
    // as the response of the booking step
    dispatch(checkLoggedIn());
  },
  goToSignUpMailSent() {
    dispatch(execPush(SIGN_UP_NOT_CONFIRMED_VIEW));
  },
});

export const ConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Confirmation);
