import {
  Contact,
  PostContactRequestBody,
  USERS_CONTACT_PATH,
} from '@gts-sm/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';

import { serverComm } from '../serverComm';
import {
  Actions,
  CONTACT_UPDATED,
  Thunk,
  UpdatedContactAction,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

export const updateContact = (user: Contact): Thunk<Actions> => {
  return (dispatch) => {
    dispatch(startServerRequest());

    const data: PostContactRequestBody = {
      firstName: user.firstName,
      lastName: user.lastName,
    };
    serverComm
      .execPatchRequest<string, PostContactRequestBody>(
        USERS_CONTACT_PATH,
        data,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(updatedContact(user.firstName, user.lastName));
            dispatch(serverRequestSucceeded('Kontaktdaten wurden geändert.'));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
};

export function updatedContact(
  firstName: string,
  lastName: string,
): UpdatedContactAction {
  return {
    type: CONTACT_UPDATED,
    payload: { firstName, lastName },
  };
}
