import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import { ReduxState, SaveInvoiceAndPaymentDataFields } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import {
  getAllAVVContracts,
  groupDataSaveInvoiceAndPaymentData,
  loadInvoiceAndPaymentData,
} from '../actions';
import { Group } from '../components/Group/Group';
import { AVV_VIEW } from '../constants';

const mapStateToProps = (state: ReduxState) => {
  const paymentState = state.invoiceAndPaymentData;
  const serviceInstances = denormalize(state.serviceInstances.serviceInstances);

  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  const hasServiceThatNeedsAvv = serviceInstances.some(
    (serviceInstance) => serviceInstance.needsAvv,
  );

  return {
    groupId: state.group.groupId,
    avvList: state.avv.acceptedList,
    paymentState,
    hasServiceThatNeedsAvv,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execGetAllAVVContracts(groupId: string) {
    dispatch(getAllAVVContracts(groupId));
  },
  execShowAvv() {
    dispatch(push(AVV_VIEW));
  },
  execLoadInvoiceAndPaymentData() {
    dispatch(loadInvoiceAndPaymentData());
  },
  execSaveInvoiceAndPaymentData(
    groupId: string,
    data: SaveInvoiceAndPaymentDataFields,
  ) {
    dispatch(groupDataSaveInvoiceAndPaymentData(groupId, data));
  },
});

export const GroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Group);
