import { initialState } from '../initialState';
import { Actions, GOT_INVOICES } from '../actions/reduxActionTypes';
import { InvoicesState } from '../types';

export function invoicesReducer(
  state = initialState.invoices,
  action: Actions,
): InvoicesState {
  switch (action.type) {
    case GOT_INVOICES:
      return {
        ...state,
        list: action.payload.invoices,
      };
    default:
      return state;
  }
}
