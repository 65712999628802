import { LoginStatus } from '@gts-common/client';
import { initialState } from '../initialState';
import {
  Actions,
  CONTACT_UPDATED,
  GOT_AVV_TEMPLATE,
  LOGIN_CHECK_FAILED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  SIGN_UP_CONFIRMED,
} from '../actions/reduxActionTypes';
import { AppState } from '../types';

export function appReducer(
  state = initialState.app,
  action: Actions,
): AppState {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        isEmailConfirmed: action.payload.isEmailConfirmed,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        loginStatus: LoginStatus.LOGGED_IN,
        userType: action.payload.userType,
      };

    // Reset all data
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState.app,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_CHECK_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        isEmailConfirmed: action.payload.isEmailConfirmed,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        loginStatus: LoginStatus.LOGGED_IN,
        userType: action.payload.userType,
      };

    case SIGN_UP_CONFIRMED:
      return {
        ...state,
        isEmailConfirmed: true,
      };

    case GOT_AVV_TEMPLATE:
      return {
        ...state,
        avvTemplate: action.payload,
      };
    case CONTACT_UPDATED:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };

    default:
      return state;
  }
}
