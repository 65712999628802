import { Form } from 'formik';

import { Box } from '@material-ui/core';
import { PasswordField, Button } from '@gts-common/client';

const formId = 'password-reset-form';

export const PasswordResetForm = () => {
  return (
    <Form noValidate={true} id={formId}>
      <PasswordField
        name="password"
        label="Neues Passwort"
        autoComplete="new-password"
        autoFocus
        required
      />
      <Box textAlign="center" mt="1rem">
        <Button submitForForm={formId} label="Passwort zurücksetzen" />
      </Box>
    </Form>
  );
};
