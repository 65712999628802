import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';

import { routerMiddleware } from 'connected-react-router';
import { errorHandlingMiddleware } from '@gts-common/client';
import { initialState } from './initialState';
import { rootReducer } from './reducers/rootReducer';
import { ReduxState } from './types';
import { Actions } from './actions/reduxActionTypes';
import { serverComm } from './serverComm';

type WindowWithDevTools = typeof window & {
  __REDUX_DEVTOOLS_EXTENSION__: typeof compose;
};

const enableDevTool =
  process.env.NODE_ENV !== 'production' &&
  (window as WindowWithDevTools).__REDUX_DEVTOOLS_EXTENSION__;

export const history = createBrowserHistory();

const commonMiddleware = [
  errorHandlingMiddleware(serverComm),
  thunkMiddleware as ThunkMiddleware<ReduxState, Actions>,
  routerMiddleware(history),
];

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [...commonMiddleware, immutableStateInvariantMiddleware()]
    : commonMiddleware;

function getReducer() {
  return rootReducer(history);
}

export const store = createStore(
  getReducer(),
  initialState,
  compose(
    applyMiddleware(...middleware),
    enableDevTool
      ? (window as WindowWithDevTools).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: unknown) => f,
  ),
);

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      store.replaceReducer(getReducer());
    });
  }
}
