import { PlanForCheckout } from '@gts-sm/utils';
import { Box } from '@material-ui/core';
import { PlanStepData } from '../checkoutTypes';
import { NavButtons } from '../../NavButtons';
import { PlanDescription } from '../PlanDescription';

interface Props {
  data: PlanStepData;
  nextStepUrl: string;
  prevStepUrl: string;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const PlanStep = ({
  data,
  nextStepUrl,
  prevStepUrl,
  execOpenServicePriceExample,
}: Props) => {
  const { selectedPlan } = data;

  return (
    <>
      <Box mb="2rem">
        <PlanDescription
          execOpenServicePriceExample={execOpenServicePriceExample}
          plan={selectedPlan}
        />
      </Box>
      <NavButtons
        forwardButtonLabel="Weiter"
        nextStepUrl={nextStepUrl}
        prevStepUrl={prevStepUrl}
      />
    </>
  );
};
