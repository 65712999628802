import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { denormalize } from '@gts-common/client';
import { push } from 'connected-react-router';
import { Home } from '../components/Home/Home';
import { ReduxState } from '../types';
import { execGetTicket } from '../actions';
import { Actions } from '../actions/reduxActionTypes';
import { AVV_VIEW } from '../constants';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  const group = state.group;
  const serviceInstances = denormalize(state.serviceInstances.serviceInstances);

  const hasServiceThatNeedsAvv = serviceInstances.some(
    (serviceInstance) => serviceInstance.needsAvv,
  );

  const showAvvText = hasServiceThatNeedsAvv && !group.hasAvv;

  return {
    serviceInstances,
    group,
    showAvvText,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  goToProduct(serviceInstanceId: string) {
    dispatch(execGetTicket(serviceInstanceId));
  },
  goToAvv() {
    dispatch(push(AVV_VIEW));
  },
});

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
