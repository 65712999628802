import { initialState } from '../initialState';
import {
  Actions,
  PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED,
  INVOICE_AND_PAYMENT_DATA_LOADED,
  INVOICE_AND_PAYMENT_DATA_SAVED,
  PAYMENT_PROVIDER_CHANGE_CONFIRMED,
} from '../actions/reduxActionTypes';
import { LoadingState, InvoiceAndPaymentDataState } from '../types';

export function invoiceAndPaymentDataReducer(
  state: InvoiceAndPaymentDataState = initialState.invoiceAndPaymentData,
  action: Actions,
): InvoiceAndPaymentDataState {
  switch (action.type) {
    case PAYMENT_PROVIDER_CHANGE_ACKNOWLEDGED:
      return {
        ...state,
        paymentProviderChangeConfirm: {
          loadState: LoadingState.loadNeeded,
        },
      };

    case PAYMENT_PROVIDER_CHANGE_CONFIRMED:
      if (action.payload) {
        return {
          ...state,
          paymentProviderChangeConfirm: {
            loadState: LoadingState.loaded,
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          paymentProviderChangeConfirm: { loadState: LoadingState.error },
        };
      }

    case INVOICE_AND_PAYMENT_DATA_LOADED:
      return {
        ...state,
        paymentMethods: {
          loadState: LoadingState.loaded,
          changePaymentMethod: action.payload.changePaymentMethod,
          currentPaymentMethod: action.payload.currentPaymentMethod,
        },
        invoiceData: {
          ...(action.payload.invoiceData ?? {
            companyName: '',
            streetAndNumber: '',
            zip: '',
            city: '',
          }),
          loadState: LoadingState.loaded,
        },
        selectedPaymentMethod: {
          loadState: LoadingState.loaded,
          currentPaymentMethod: action.payload.currentPaymentMethod,
        },
      };

    case INVOICE_AND_PAYMENT_DATA_SAVED:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          ...action.payload.invoiceData,
        },
        selectedPaymentMethod: {
          loadState: LoadingState.loaded,
          currentPaymentMethod: action.payload.newPaymentMethod,
        },
        paymentMethods: {
          loadState: LoadingState.loaded,
          changePaymentMethod: action.payload.changePaymentMethod,
          currentPaymentMethod: action.payload.newPaymentMethod,
        },
      };

    default:
      return state;
  }
}
