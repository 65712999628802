"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundNumberToFourDecimalPlaces = exports.roundNumberToTwoDecimalPlaces = exports.roundNumberToInteger = void 0;
/**
 * Rounds the number like Math.round but does it "correctly" in cases like Math.round(600.175*100).
 * This function reduces the precision to 4 fractional digits
 * @param num number to round
 */
// Note that Math.round doesn't behave like it does in other languages
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function roundNumberToInteger(num) {
    const integer = Math.round(num + 0.00001);
    if (!Number.isInteger(integer)) {
        throw new Error('Failed to round to integer ' + integer);
    }
    return integer;
}
exports.roundNumberToInteger = roundNumberToInteger;
function roundNumberToTwoDecimalPlaces(num) {
    return roundNumberToInteger(num * 100) / 100;
}
exports.roundNumberToTwoDecimalPlaces = roundNumberToTwoDecimalPlaces;
function roundNumberToFourDecimalPlaces(num) {
    return roundNumberToInteger(num * 10000) / 10000;
}
exports.roundNumberToFourDecimalPlaces = roundNumberToFourDecimalPlaces;
