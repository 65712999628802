import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BusinessIcon from '@material-ui/icons/Business';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  GROUP_USER_VIEW,
  GROUP_VIEW,
  INVOICES_VIEW,
  PERSONAL_DATA_VIEW,
  SUBSCRIPTIONS_VIEW,
} from '../../constants';

interface SettingsMenuProps {
  anchor: MouseEvent['currentTarget'] | null;
  isOpen: boolean;
  closeMenu: () => void;
}

export const SettingsMenu = ({
  anchor,
  isOpen,
  closeMenu,
}: SettingsMenuProps) => {
  return (
    <Menu
      id="settings-menu-appbar"
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={closeMenu}
      onClick={closeMenu}
    >
      <MenuItem component={Link} to={PERSONAL_DATA_VIEW}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText>Persönliche Daten</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={GROUP_VIEW}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText>Firmendaten</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={GROUP_USER_VIEW}>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText>Benutzerverwaltung</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={INVOICES_VIEW}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText>Rechnungen</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={SUBSCRIPTIONS_VIEW}>
        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText>Abonnements</ListItemText>
      </MenuItem>
    </Menu>
  );
};
