import { Box } from '@material-ui/core';
import { PlanForCheckout } from '@gts-sm/utils';
import { NavButtons } from '../../NavButtons';
import { ProductDescription } from '../ProductDescription';
import { ProductStepData } from '../checkoutTypes';

interface Props {
  data: ProductStepData;
  nextStepUrl: string;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const ProductStep = ({
  data,
  nextStepUrl,
  execOpenServicePriceExample,
}: Props) => {
  const { checkoutProduct } = data;
  return (
    <>
      <Box mb="2rem">
        <ProductDescription
          execOpenServicePriceExample={execOpenServicePriceExample}
          product={checkoutProduct}
        />
      </Box>
      <NavButtons
        showBackBtn={false}
        forwardButtonLabel="Weiter"
        nextStepUrl={nextStepUrl}
      />
    </>
  );
};
