"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.centToText = void 0;
const constants_1 = require("./constants");
const round_numbers_1 = require("./round-numbers");
/**
 * Splits an amount given in cent to euro and cent. Value must be an integer and correctly rounded (e.g. via
 * roundNumber)
 * @param amountInCent Integer amount of cent
 */
function centToEuroAndCent(amountInCent) {
    const euro = Math.trunc(amountInCent / 100);
    const cent = amountInCent - euro * 100;
    if (cent < 0) {
        throw new Error('Negative cent value (accuracy problem?): ' + cent);
    }
    if (cent >= 100) {
        throw new Error('Cent value >= 100 (accuracy problem?): ' + cent);
    }
    return [euro, cent];
}
/**
 * Formats an amount given in cent to euro and cent.
 * If an integer amount is given it is converted to euro and cents with two digit precision (the cents)
 * If a float amount is given it is converted to euro and cents with four digit precision. Allowing floats is for example useful when we need to display the price of a service per day
 * @param amountInCent Amount in cents
 * @param skipAfterDecimalIfZero If this is true and cents is zero only the euro amount is returned
 */
function centToText(amountInCent, skipAfterDecimalIfZero = false) {
    if (Number.isInteger(amountInCent)) {
        const [euro, cent] = centToEuroAndCent(amountInCent);
        if (cent === 0 && skipAfterDecimalIfZero) {
            return `${euro} €`;
        }
        else {
            return (String(euro) + constants_1.DECIMAL_SEPARATOR + String(cent).padStart(2, '0') + ' €');
        }
    }
    else {
        const [euro, cent = 0] = String(round_numbers_1.roundNumberToFourDecimalPlaces(amountInCent / 100)).split('.');
        if (cent === 0 && skipAfterDecimalIfZero) {
            return `${euro} €`;
        }
        else {
            return euro + constants_1.DECIMAL_SEPARATOR + String(cent).padEnd(4, '0') + ' €';
        }
    }
}
exports.centToText = centToText;
