import { Path } from 'history';
import { ProductCheckoutStep, ProductCheckoutStepCode } from '../../types';
import { PRODUCT_CHECKOUT_PATH } from '../../constants';

const selectProductStep = {
  code: ProductCheckoutStepCode.Product,
  label: 'Produktübersicht',
};

const accountStep = {
  code: ProductCheckoutStepCode.Account,
  label: 'Kundenkonto',
};

const paymentStep = {
  code: ProductCheckoutStepCode.Payment,
  label: 'Rechnungs-/Bezahldaten',
};

const bookStep = { code: ProductCheckoutStepCode.Finish, label: 'Buchen' };

export function getStepUrlFromStepCode(
  stepCode: ProductCheckoutStepCode,
  productId: string | undefined,
) {
  return `${PRODUCT_CHECKOUT_PATH}/${stepCode}/${productId}`;
}

function getStepUrlFromStep(
  step: ProductCheckoutStep | undefined,
  productId: string | undefined,
): Path {
  if (step) {
    return getStepUrlFromStepCode(step.code, productId);
  } else {
    return '';
  }
}

export function getSteps(isLoggedIn: boolean) {
  // type argument has to be given to force the typescript compiler to see this as type ProductCheckoutStepCode.ProductSelect
  // instead of ProductCheckoutStepCode
  const initialStepCode: ProductCheckoutStepCode.Product =
    ProductCheckoutStepCode.Product;
  const steps = [
    selectProductStep,
    ...(isLoggedIn ? [] : [accountStep]),
    paymentStep,
    bookStep,
  ];

  const stepReplacements = isLoggedIn
    ? {
        [accountStep.code]: paymentStep.code,
      }
    : {};

  return {
    initialStepCode,
    steps,
    stepReplacements,
  };
}
export function getStepUrls(
  isLoggedIn: boolean,
  productId: string | undefined,
  stepCode: ProductCheckoutStepCode | undefined,
) {
  const { steps } = getSteps(isLoggedIn);

  const activeStep = steps.findIndex((step) => step.code === stepCode);
  const prevStep = steps[activeStep - 1];
  const nextStep = steps[activeStep + 1];

  const prevStepUrl = getStepUrlFromStep(prevStep, productId);
  const nextStepUrl = getStepUrlFromStep(nextStep, productId);

  return {
    steps,
    prevStepUrl,
    nextStepUrl,
  };
}
