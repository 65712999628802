import { ReduxState } from '../../types';

export function getQueryParamValueFromState(
  state: ReduxState,
  valueKey: string,
) {
  if (state.router) {
    const value = state.router.location.query[valueKey];

    if (value) {
      return decodeURIComponent(value);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
