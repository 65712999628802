import { initialState } from '../initialState';
import { Actions, GOT_AVV_CONTRACTS } from '../actions/reduxActionTypes';
import { AvvState } from '../types';

export function avvReducer(
  state = initialState.avv,
  action: Actions,
): AvvState {
  switch (action.type) {
    case GOT_AVV_CONTRACTS:
      return {
        ...state,
        acceptedList: action.payload.avvList,
      };
    default:
      return state;
  }
}
