import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { GroupUserBase, ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { GroupUserEdit } from '../components/GroupUser/GroupUserEdit';
import { editGroupUser } from '../actions/groupUser';
import { GROUP_USER_EDIT_VIEW } from '../constants';

const matchSelector = createMatchSelector<
  RouterRootState,
  { groupUserId: string }
>(GROUP_USER_EDIT_VIEW);

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }
  const match = matchSelector(state);
  const groupUserId = match?.params.groupUserId;

  const foundGroupUser = state.groupUsers.users.find(
    (user) => user.groupUserId === groupUserId,
  );

  const initialGroupUserValues: GroupUserBase = {
    username: foundGroupUser ? foundGroupUser.username : '',
    password: '',
  };

  return {
    groupUserId: groupUserId ? groupUserId : '',
    initialGroupUserValues: initialGroupUserValues,
    userNotExists:
      foundGroupUser === undefined && state.groupUsers.users.length > 0,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execEditGroupUser(groupUserId: string, groupUser: GroupUserBase) {
    dispatch(editGroupUser(groupUserId, groupUser));
  },
});

export const GroupUserEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupUserEdit);
