import { connect } from 'react-redux';
import { LocationDescriptorObject, Path } from 'history';

import { ThunkDispatch } from 'redux-thunk';
import { UserLoginRequestBody, GroupUserLoginRequestBody } from '@gts-sm/utils';
import {
  execGroupUserLoginAndRedirect,
  execUserLoginAndRedirect,
} from '../actions';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { Login } from '../components/Login/Login';

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execUserLogin(
    loginData: UserLoginRequestBody,
    redirectAfterLogin: LocationDescriptorObject | Path | undefined,
  ) {
    dispatch(execUserLoginAndRedirect(loginData, redirectAfterLogin));
  },
  execGroupUserLogin(
    loginData: GroupUserLoginRequestBody,
    redirectAfterLogin: LocationDescriptorObject | Path | undefined,
  ) {
    dispatch(execGroupUserLoginAndRedirect(loginData, redirectAfterLogin));
  },
});

export const LoginContainer = connect(null, mapDispatchToProps)(Login);
