import * as yup from 'yup';

import {
  MAX_NAME_LENGTH,
  USER_PASSWORD_MIN_LENGTH,
  GROUP_USER_PASSWORD_MIN_LENGTH,
  MAX_COMPANY_NAME_LENGTH,
  MAX_STREET_AND_NUMBER_LENGTH,
  MAX_CITY_NAME_LENGTH,
  MAX_ZIP_LENGTH,
  MAX_GROUP_USER_USERNAME_LENGTH,
  MIN_GROUP_NUMBER,
  MAX_GROUP_NUMBER,
} from '@gts-sm/utils';
import { EMAIL_REGEX, MAX_EMAIL_LENGTH } from '@gts-common/client-server';
import {
  emailFormatValidationMessage,
  getGreaterThanEqualValidationMessage,
  getLessThanEqualValidationMessage,
  getMaxLengthValidationMessage,
  getMinLengthValidationMessage,
  requiredFieldValidationMessage,
} from '@gts-common/client';

const stringRequired = yup.string().required(requiredFieldValidationMessage);
// Note that even though we trim here we need to trim again before we send the
// data because formik doesn't use the trimmed value. It is only used for validation
const stringRequiredTrimmed = stringRequired.trim();

const username = stringRequiredTrimmed
  .matches(EMAIL_REGEX, emailFormatValidationMessage)
  .max(MAX_EMAIL_LENGTH, getMaxLengthValidationMessage(MAX_EMAIL_LENGTH));

const password = stringRequired.min(
  USER_PASSWORD_MIN_LENGTH,
  getMinLengthValidationMessage(USER_PASSWORD_MIN_LENGTH),
);

const checked = yup.bool().oneOf([true], requiredFieldValidationMessage);

export const userLoginValidation = yup.object({
  username,
  password,
});

export const passwordChangeValidation = yup.object({
  password,
  newPassword: password,
});

export const passwordResetValidation = yup.object({
  password,
});

export const passwordResetRequestValidation = yup.object({
  email: username,
});

const contactFields = {
  firstName: stringRequiredTrimmed.max(
    MAX_NAME_LENGTH,
    getMaxLengthValidationMessage(MAX_NAME_LENGTH),
  ),
  lastName: stringRequiredTrimmed.max(
    MAX_NAME_LENGTH,
    getMaxLengthValidationMessage(MAX_NAME_LENGTH),
  ),
};

const signUpFields = {
  firstName: contactFields.firstName,
  lastName: contactFields.lastName,
  username,
  password,
  agb: checked,
  privacy: checked,
};

const companyFields = {
  companyName: stringRequiredTrimmed.max(
    MAX_COMPANY_NAME_LENGTH,
    getMaxLengthValidationMessage(MAX_COMPANY_NAME_LENGTH),
  ),
  streetAndNumber: stringRequiredTrimmed.max(
    MAX_STREET_AND_NUMBER_LENGTH,
    getMaxLengthValidationMessage(MAX_STREET_AND_NUMBER_LENGTH),
  ),
  zip: stringRequiredTrimmed.max(
    MAX_ZIP_LENGTH,
    getMaxLengthValidationMessage(MAX_ZIP_LENGTH),
  ),
  city: stringRequiredTrimmed.max(
    MAX_CITY_NAME_LENGTH,
    getMaxLengthValidationMessage(MAX_CITY_NAME_LENGTH),
  ),
};

const paymentFields = {
  paymentMethod: stringRequiredTrimmed,
};

const groupUserFields = {
  username: stringRequiredTrimmed.max(
    MAX_GROUP_USER_USERNAME_LENGTH,
    getMaxLengthValidationMessage(MAX_GROUP_USER_USERNAME_LENGTH),
  ),
  password: stringRequired.min(
    GROUP_USER_PASSWORD_MIN_LENGTH,
    getMinLengthValidationMessage(GROUP_USER_PASSWORD_MIN_LENGTH),
  ),
};

export const groupUserLoginValidation = yup.object({
  username: stringRequiredTrimmed.max(
    MAX_GROUP_USER_USERNAME_LENGTH,
    getMaxLengthValidationMessage(MAX_GROUP_USER_USERNAME_LENGTH),
  ),
  password: stringRequired.min(
    GROUP_USER_PASSWORD_MIN_LENGTH,
    getMinLengthValidationMessage(GROUP_USER_PASSWORD_MIN_LENGTH),
  ),
  groupNumber: yup
    .number()
    // Prevent the validation from failing for the initial NaN value https://github.com/jquense/yup/issues/1330
    .transform((value) => (isNaN(value) ? undefined : value))
    .integer('Bitte geben Sie nur ganze Zahlen ein.')
    .min(
      MIN_GROUP_NUMBER,
      getGreaterThanEqualValidationMessage(MIN_GROUP_NUMBER),
    )
    .max(MAX_GROUP_NUMBER, getLessThanEqualValidationMessage(MAX_GROUP_NUMBER))
    .required(requiredFieldValidationMessage),
});

export const signUpValidation = yup.object(signUpFields);

export const checkoutPaymentValidation = yup.object({
  ...companyFields,
  ...paymentFields,
});
export const checkoutSignUpValidation = yup.object(signUpFields);

export const contactValidation = yup.object(contactFields);

export const companyValidation = yup.object(companyFields);

export const groupUserValidation = yup.object(groupUserFields);
