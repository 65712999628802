import {
  SignUpRequestBody,
  CONFIRM_PAYMENT_PROVIDER_CHANGE_CLIENT_PATH,
  PASSWORD_RESET_CLIENT_PATH,
  SIGN_UP_CONFIRM_CLIENT_PATH,
  INVOICES_CLIENT_PATH,
} from '@gts-sm/utils';

export const HOME_VIEW = '/';
export const LOGIN_VIEW = '/login';
export const SIGN_UP_CONFIRM_VIEW = SIGN_UP_CONFIRM_CLIENT_PATH;
export const SIGN_UP_NOT_CONFIRMED_VIEW = '/not-confirmed';
export const SIGN_UP_VIEW = '/sign-up';
export const SIGN_UP_EMAIL_SENT_VIEW = '/sign-up-mail-sent';
export const PERSONAL_DATA_VIEW = '/personal-data';
export const GROUP_VIEW = '/group';

export const CONFIRM_PAYMENT_PROVIDER_CHANGE_VIEW = CONFIRM_PAYMENT_PROVIDER_CHANGE_CLIENT_PATH;

export const AVV_VIEW = '/avv';
export const GROUP_USER_VIEW = '/group-user';
export const GROUP_USER_ADD_VIEW = `${GROUP_USER_VIEW}/add`;
export const GROUP_USER_EDIT_PATH = `${GROUP_USER_VIEW}/edit`;
export const GROUP_USER_EDIT_VIEW = `${GROUP_USER_EDIT_PATH}/:groupUserId`;

export const INVOICES_VIEW = INVOICES_CLIENT_PATH;

export const SUBSCRIPTIONS_VIEW = '/subscriptions';

export const REQUEST_PASSWORD_RESET_MAIL_VIEW = '/request-password-reset';
export const PASSWORD_RESET_VIEW = PASSWORD_RESET_CLIENT_PATH;

// Used when a user books a new plan for a new service instance
export const PRODUCT_CHECKOUT_PATH = '/checkout/product';
export const PRODUCT_CHECKOUT_VIEW = `${PRODUCT_CHECKOUT_PATH}/:step/:productId`;

// Used when a user books a plan for an existing service instance
export const SERVICE_INSTANCE_CHECKOUT_PATH = '/checkout/service-instance';
// The ? at the end means that planId is optional
export const SERVICE_INSTANCE_CHECKOUT_VIEW = `${SERVICE_INSTANCE_CHECKOUT_PATH}/:step/:serviceInstanceId/:planId?`;

export const CONFIRMATION_VIEW = '/confirmation';

export const signUpFormInitialValues: SignUpRequestBody = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  agb: false,
  privacy: false,
};

export const loginFormInitialValues = {
  username: '',
  password: '',
};

export const userLoginFormInitialValues = {
  username: '',
  password: '',
};

export const groupUserLoginFormInitialValues = {
  groupNumber: NaN,
  username: '',
  password: '',
};
