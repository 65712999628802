import { FunctionComponent } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { LinkButton, LoginStatus } from '@gts-common/client';
import {
  SIGN_UP_NOT_CONFIRMED_VIEW,
  HOME_VIEW,
  LOGIN_VIEW,
} from '../constants';

interface RouteProps {
  loginStatus: LoginStatus;
  path: string;
  exact?: boolean;
}

export const PublicRoute: FunctionComponent<RouteProps> = ({
  children,
  path,
  loginStatus,
  exact = false,
}) => {
  return (
    <Route path={path} exact={exact}>
      {(() => {
        if (loginStatus === LoginStatus.LOGGED_OUT) {
          return children;
        } else {
          return <Redirect to={HOME_VIEW} />;
        }
      })()}
    </Route>
  );
};

interface PrivateRouteProps extends RouteProps {
  isEmailConfirmed: boolean;
}
export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  children,
  path,
  loginStatus,
  exact = false,
  isEmailConfirmed,
}) => {
  const currentLocation = useLocation();

  return (
    <Route path={path} exact={exact}>
      {(() => {
        if (loginStatus === LoginStatus.LOGGED_IN && isEmailConfirmed) {
          return children;
        } else if (loginStatus === LoginStatus.LOGGED_IN && !isEmailConfirmed) {
          return (
            <Redirect
              to={{
                pathname: SIGN_UP_NOT_CONFIRMED_VIEW,
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: LOGIN_VIEW,
                state: { referrer: currentLocation },
              }}
            />
          );
        }
      })()}
    </Route>
  );
};

export const NotFoundRoute = ({
  loginStatus,
}: {
  loginStatus: LoginStatus;
}) => {
  return (
    <Route path="*">
      <Box textAlign="center" mt="4rem">
        <Typography>Pfad nicht gefunden.</Typography>
        <Box mt="3rem">
          {(() => {
            if (loginStatus === LoginStatus.LOGGED_IN) {
              return <LinkButton to={HOME_VIEW} label="Zur Startseite" />;
            } else {
              return <LinkButton to={LOGIN_VIEW} label="Zur Anmeldung" />;
            }
          })()}
        </Box>
      </Box>
    </Route>
  );
};
