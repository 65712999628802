import { TextField, PasswordField, NumberField } from '@gts-common/client';

export const LoginFormGroupUserFields = () => {
  return (
    <>
      <NumberField
        label="Firmennummer"
        name="groupNumber"
        autoFocus={true}
        autoComplete="groupNumber"
        required={true}
      />
      <TextField label="Benutzername" name="username" required={true} />
      <PasswordField
        label="Passwort"
        name="password"
        autoComplete="current-password"
        required={true}
      />
    </>
  );
};
