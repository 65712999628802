"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentChangeInitiator = exports.PaymentMethodType = exports.PlanCode = exports.ProductCode = exports.NormalizedPaymentMethodStatus = exports.NormalizedInvoicePaymentStatus = exports.SessionType = exports.MAX_GROUP_NUMBER = exports.MIN_GROUP_NUMBER = exports.MAX_GROUP_USER_USERNAME_LENGTH = exports.VAT = exports.DECIMAL_SEPARATOR = exports.SubscriptionEndReason = exports.INVOICES_CLIENT_PATH = exports.SIGN_UP_CONFIRM_CLIENT_PATH = exports.PASSWORD_RESET_CLIENT_PATH = exports.CONFIRM_PAYMENT_PROVIDER_CHANGE_CLIENT_PATH = exports.MAX_CITY_NAME_LENGTH = exports.MAX_ZIP_LENGTH = exports.MAX_STREET_AND_NUMBER_LENGTH = exports.MAX_COMPANY_NAME_LENGTH = exports.MAX_CONTACT_LENGTH = exports.MAX_NAME_LENGTH = exports.USER_PASSWORD_MIN_LENGTH = exports.GROUP_USER_PASSWORD_MIN_LENGTH = exports.GROUP_USERS_MANAGE = exports.GROUP_USERS_LOGIN = exports.INVOICE_AND_PAYMENT_DATA_PATH = exports.CONFIRM_PAYMENT_PROVIDER_CHANGE_PATH = exports.CHECKOUT_SERVICE_INSTANCE_PATH = exports.CHECKOUT_PRODUCT_PATH = exports.SUBSCRIPTION_CANCEL_PATH = exports.SUBSCRIPTIONS_PATH = exports.INVOICE_DOWNLOAD_PATH = exports.INVOICES_PATH = exports.AVV_DOWNLOAD_PATH = exports.AVV_PATH = exports.PASSWORD_RESET_PATH = exports.REQUEST_PASSWORD_RESET_PATH = exports.TICKET_PATH = exports.USERS_PASSWORD_PATH = exports.USERS_CONTACT_PATH = exports.USERS_PATH = exports.LOGOUT_PATH = exports.RESEND_MAIL_PATH = exports.SIGN_UP_PATH = exports.LOGIN_PATH = void 0;
// paths
exports.LOGIN_PATH = '/login';
exports.SIGN_UP_PATH = '/sign-up';
exports.RESEND_MAIL_PATH = '/resend-mail';
exports.LOGOUT_PATH = '/logout';
exports.USERS_PATH = '/users';
exports.USERS_CONTACT_PATH = `${exports.USERS_PATH}/contact`;
exports.USERS_PASSWORD_PATH = `${exports.USERS_PATH}/password`;
exports.TICKET_PATH = '/ticket';
exports.REQUEST_PASSWORD_RESET_PATH = '/request-password-reset';
exports.PASSWORD_RESET_PATH = '/password-reset';
// AVV paths
exports.AVV_PATH = '/avvs';
exports.AVV_DOWNLOAD_PATH = `${exports.AVV_PATH}/download`;
// invoice paths
exports.INVOICES_PATH = '/invoices';
exports.INVOICE_DOWNLOAD_PATH = `${exports.INVOICES_PATH}/download`;
// subscription paths
exports.SUBSCRIPTIONS_PATH = '/subscriptions';
exports.SUBSCRIPTION_CANCEL_PATH = `${exports.SUBSCRIPTIONS_PATH}/cancel`;
// checkout paths
exports.CHECKOUT_PRODUCT_PATH = '/checkout-product';
exports.CHECKOUT_SERVICE_INSTANCE_PATH = '/checkout-service-instance';
// invoice and payment data paths
exports.CONFIRM_PAYMENT_PROVIDER_CHANGE_PATH = '/confirm-payment-provider-change';
exports.INVOICE_AND_PAYMENT_DATA_PATH = '/invoice-and-payment-data';
// user groups
const GROUP_USERS = '/group-users';
exports.GROUP_USERS_LOGIN = `${GROUP_USERS}/login`;
exports.GROUP_USERS_MANAGE = `${GROUP_USERS}/manage`;
// Validations
exports.GROUP_USER_PASSWORD_MIN_LENGTH = 6;
exports.USER_PASSWORD_MIN_LENGTH = 8;
exports.MAX_NAME_LENGTH = 50;
exports.MAX_CONTACT_LENGTH = exports.MAX_NAME_LENGTH;
exports.MAX_COMPANY_NAME_LENGTH = 200;
exports.MAX_STREET_AND_NUMBER_LENGTH = 200;
exports.MAX_ZIP_LENGTH = 10;
exports.MAX_CITY_NAME_LENGTH = 200;
// client paths used in the server
exports.CONFIRM_PAYMENT_PROVIDER_CHANGE_CLIENT_PATH = '/confirm-payment-provider-change';
exports.PASSWORD_RESET_CLIENT_PATH = '/password-reset';
exports.SIGN_UP_CONFIRM_CLIENT_PATH = '/confirm-sign-up';
exports.INVOICES_CLIENT_PATH = '/invoices';
var SubscriptionEndReason;
(function (SubscriptionEndReason) {
    // User changed to a different plan for the same service instance
    SubscriptionEndReason["USER_CHANGED"] = "USER_CHANGED";
    SubscriptionEndReason["USER_CANCELLED"] = "USER_CANCELLED";
    SubscriptionEndReason["NOT_PAID"] = "NOT_PAID";
})(SubscriptionEndReason = exports.SubscriptionEndReason || (exports.SubscriptionEndReason = {}));
exports.DECIMAL_SEPARATOR = ',';
exports.VAT = 0.19;
exports.MAX_GROUP_USER_USERNAME_LENGTH = 50;
// The group number sequence starts with a number > 4000. No need to allow numbers smaller 4000
exports.MIN_GROUP_NUMBER = 4000;
exports.MAX_GROUP_NUMBER = 100000;
var SessionType;
(function (SessionType) {
    SessionType["USER"] = "USER";
    SessionType["GROUP_USER"] = "GROUP_USER";
})(SessionType = exports.SessionType || (exports.SessionType = {}));
// The normalized_invoice_payments view maps the invoice-and-invoice-and-payment-data-data status of various providers to the normalized status defined here
var NormalizedInvoicePaymentStatus;
(function (NormalizedInvoicePaymentStatus) {
    NormalizedInvoicePaymentStatus["CANCELLED"] = "CANCELLED";
    NormalizedInvoicePaymentStatus["FAILED"] = "FAILED";
    NormalizedInvoicePaymentStatus["PAID"] = "PAID";
    NormalizedInvoicePaymentStatus["PENDING"] = "PENDING";
    NormalizedInvoicePaymentStatus["UNKNOWN"] = "UNKNOWN";
})(NormalizedInvoicePaymentStatus = exports.NormalizedInvoicePaymentStatus || (exports.NormalizedInvoicePaymentStatus = {}));
// The normalized_group_payment_methods view maps the invoice-and-invoice-and-payment-data-data status of various providers to the normalized status defined here
var NormalizedPaymentMethodStatus;
(function (NormalizedPaymentMethodStatus) {
    NormalizedPaymentMethodStatus["PENDING"] = "PENDING";
    NormalizedPaymentMethodStatus["ENABLED"] = "ENABLED";
    NormalizedPaymentMethodStatus["DISABLED"] = "DISABLED";
    NormalizedPaymentMethodStatus["UNKNOWN"] = "UNKNOWN";
})(NormalizedPaymentMethodStatus = exports.NormalizedPaymentMethodStatus || (exports.NormalizedPaymentMethodStatus = {}));
// Must match the product codes defined in the products table
var ProductCode;
(function (ProductCode) {
    ProductCode["FREE_TABLE"] = "FREE_TABLE";
    ProductCode["NEXT_SURVEY"] = "NEXT_SURVEY";
})(ProductCode = exports.ProductCode || (exports.ProductCode = {}));
// Must match the plan codes defined in the plans table
var PlanCode;
(function (PlanCode) {
    PlanCode["FREE_TABLE"] = "FREE_TABLE";
    PlanCode["NEXT_SURVEY"] = "NEXT_SURVEY";
})(PlanCode = exports.PlanCode || (exports.PlanCode = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["debit"] = "debit";
    PaymentMethodType["manual"] = "manual";
})(PaymentMethodType = exports.PaymentMethodType || (exports.PaymentMethodType = {}));
var PaymentChangeInitiator;
(function (PaymentChangeInitiator) {
    PaymentChangeInitiator["PRODUCT_CHECKOUT"] = "PRODUCT_CHECKOUT";
    PaymentChangeInitiator["GROUP_DATA_UPDATE"] = "GROUP_DATA_UPDATE";
    PaymentChangeInitiator["SERVICE_INSTANCE_CHECKOUT"] = "SERVICE_INSTANCE_CHECKOUT";
})(PaymentChangeInitiator = exports.PaymentChangeInitiator || (exports.PaymentChangeInitiator = {}));
