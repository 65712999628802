import { SIGN_UP_PATH, SignUpConfirmRequestBody } from '@gts-sm/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '../serverComm';
import { SIGN_UP_CONFIRM_VIEW } from '../constants';
import {
  Actions,
  SIGN_UP_CONFIRMED,
  SignUpConfirmedAction,
  Thunk,
} from './reduxActionTypes';
import { execReplace } from './navigation';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

export function signUpConfirmed(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(execReplace(SIGN_UP_CONFIRM_VIEW));
    const signUpConfirmedAction: SignUpConfirmedAction = {
      type: SIGN_UP_CONFIRMED,
    };
    dispatch(signUpConfirmedAction);
  };
}

export const execConfirmSignUp = (
  confirm: SignUpConfirmRequestBody,
): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  serverComm
    .execPatchRequest<Record<string, never>, SignUpConfirmRequestBody>(
      SIGN_UP_PATH,
      confirm,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(serverRequestSucceeded());
          dispatch(signUpConfirmed());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
