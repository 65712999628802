import { initialState } from '../initialState';
import {
  Actions,
  GOT_CHECKOUT_PRODUCT,
  GOT_CHECKOUT_SERVICE_INSTANCE,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { CheckoutState } from '../types';

export function checkoutReducer(
  state: CheckoutState = initialState.checkout,
  action: Actions,
): CheckoutState {
  switch (action.type) {
    case LOGOUT_SUCCEEDED:
      return initialState.checkout;

    case GOT_CHECKOUT_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    case GOT_CHECKOUT_SERVICE_INSTANCE:
      return {
        ...state,
        serviceInstance: action.payload,
      };

    default:
      return state;
  }
}
