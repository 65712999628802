import { Grid, Typography } from '@material-ui/core';
import { InvoiceDataState, LoadingState } from '../../types';

interface Props {
  invoice: InvoiceDataState;
}

export const FinishStepInvoice = ({ invoice }: Props) => {
  return (
    <>
      {invoice.loadState === LoadingState.loaded && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography>Firma:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{invoice.companyName}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Straße und Hausnummer:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{invoice.streetAndNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Postleitzahl:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{invoice.zip}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Stadt:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{invoice.city}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
