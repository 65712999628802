import { Typography } from '@material-ui/core';
import { View } from '@gts-common/client';
import { LOGIN_VIEW } from '../../constants';

interface Props {
  email: string;
}

export const PasswordResetMailSent = ({ email }: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={LOGIN_VIEW}
      title="Passwort zurücksetzen"
      backButtonLabel="Anmeldung"
      maxWidth="md"
    >
      <Typography gutterBottom={true} align="justify">
        Wir haben Ihnen eine E-Mail an die Adresse
        {` ${email}`} geschickt.
      </Typography>
      <Typography align="justify">
        Bitte klicken Sie auf den Link in der E-Mail, um Ihr Passwort
        zurückzusetzen. Es kann bis zu 15 Minuten dauern, bis Sie die E-Mail
        erhalten.
      </Typography>
    </View>
  );
};
