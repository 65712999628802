import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { GetInvoicesResponse, Invoice, INVOICES_PATH } from '@gts-sm/utils';
import {
  CLIENT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from '@gts-common/client-server';
import { format, parse } from 'date-fns';
import { serverComm } from '../serverComm';
import {
  Actions,
  GOT_INVOICES,
  GotInvoicesAction,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

function gotInvoices(invoices: ReadonlyArray<Invoice>): GotInvoicesAction {
  const invoicesForClient = invoices.map((invoice) => {
    return {
      ...invoice,
      invoiceDate: format(
        parse(invoice.invoiceDate, SERVER_DATE_FORMAT, new Date()),
        CLIENT_DATE_FORMAT,
      ),
    };
  });

  return {
    type: GOT_INVOICES,
    payload: { invoices: invoicesForClient },
  };
}

export function getInvoices(groupId: string): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    serverComm
      .execGetRequest<GetInvoicesResponse>(`${INVOICES_PATH}/${groupId}`)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(gotInvoices(resp.body.invoices));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
