import { Form } from 'formik';

import { Box } from '@material-ui/core';
import { Button, EmailField } from '@gts-common/client';

const formId = 'request-password-reset-mail-form';

export const RequestPasswordResetMailForm = () => {
  return (
    <Form noValidate={true} id={formId}>
      <EmailField name="email" label="E-Mail-Adresse" required />
      <Box textAlign="center" mt="1rem">
        <Button submitForForm={formId} label="E-Mail anfordern" />
      </Box>
    </Form>
  );
};
