import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { GroupUserBase, ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { GroupUserAdd } from '../components/GroupUser/GroupUserAdd';
import { addGroupUser } from '../actions/groupUser';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  return {
    groupId: state.group.groupId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddGroupUser(groupId: string, groupUser: GroupUserBase) {
    dispatch(addGroupUser(groupId, groupUser));
  },
});

export const GroupUserAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupUserAdd);
