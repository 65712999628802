import {
  GetCheckoutProductResponse,
  PlanForCheckout,
  ProductCode,
} from '@gts-sm/utils';
import { ErrorText, LinkButton } from '@gts-common/client';
import { HOME_VIEW } from '../../../constants';
import { ServiceDescription } from '../ServiceDescriptions/ServiceDescription';

interface Props {
  product: GetCheckoutProductResponse;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

export const ProductDescription = ({
  product,
  execOpenServicePriceExample,
}: Props) => {
  if (product.code === ProductCode.FREE_TABLE) {
    const { title, plans } = product;
    const freeTablePlan = plans[0];
    return (
      <ServiceDescription
        title={title}
        plan={freeTablePlan}
        execOpenServicePriceExample={execOpenServicePriceExample}
      />
    );
  } else if (product.code === ProductCode.NEXT_SURVEY) {
    const { title, plans } = product;
    const nextSurveyPlan = plans[0];
    return (
      <ServiceDescription
        title={title}
        plan={nextSurveyPlan}
        execOpenServicePriceExample={execOpenServicePriceExample}
      />
    );
  } else {
    return (
      <>
        <ErrorText>Unbekanntes Produkt</ErrorText>
        <LinkButton to={HOME_VIEW} label="Zur Startseite" />
      </>
    );
  }
};
