import { Box, Paper } from '@material-ui/core';
import { SaveButton } from '@gts-common/client';
import {
  InvoiceDataLoadedState,
  PaymentMethodsLoadedState,
  SaveInvoiceAndPaymentDataFields,
} from '../../types';
import { InvoiceAndPaymentData } from '../InvoiceAndPaymentData/InvoiceAndPaymentData';

interface Props {
  groupId: string;
  invoiceData: InvoiceDataLoadedState;
  paymentMethods: PaymentMethodsLoadedState;
  execSaveInvoiceAndPaymentData: (
    groupId: string,
    data: SaveInvoiceAndPaymentDataFields,
  ) => void;
}

const formId = 'group-update-form';

export const GroupInvoiceAndPaymentData = ({
  groupId,
  invoiceData,
  paymentMethods,
  execSaveInvoiceAndPaymentData,
}: Props) => {
  return (
    <Paper
      variant="outlined"
      square
      style={{ padding: '1rem', marginBottom: '2rem' }}
    >
      <InvoiceAndPaymentData
        formId={formId}
        invoiceData={invoiceData}
        paymentMethods={paymentMethods}
        execSubmit={(paymentFields) => {
          execSaveInvoiceAndPaymentData(groupId, paymentFields);
        }}
      />
      <Box textAlign="center" mt="1rem">
        <SaveButton submitForForm={formId} label="Speichern" />
      </Box>
    </Paper>
  );
};
