import {
  GetCheckoutProductResponse,
  PlanForCheckout,
  SignUpRequestBody,
  UnreachableError,
  UserLoginRequestBody,
} from '@gts-sm/utils';
import { Path } from 'history';
import {
  InvoiceAndPaymentDataState,
  ProductCheckoutStepCode,
  SaveInvoiceAndPaymentDataFields,
} from '../../../types';
import { ProductStep } from './ProductStep/ProductStep';
import { AccountStep } from './AccountStep/AccountStep';
import { PaymentStep } from './PaymentStep/PaymentStep';
import { FinishStep } from './FinishStep/FinishStep';
import { StepData } from './checkoutTypes';

interface PropsBase {
  execLoadInvoiceAndPaymentData: () => void;
  execGetProductForCheckout: (productId: string) => void;
  execSavePaymentStep: (data: {
    data: SaveInvoiceAndPaymentDataFields;
    groupId: string;
    nextStepUrl: Path;
    productId: string;
  }) => void;
  execSignUp: (signUp: SignUpRequestBody, redirectAfterSignUp: Path) => void;
  execUserLogin: (
    loginData: UserLoginRequestBody,
    redirectAfterLogin: Path,
  ) => void;
  execRedirect: (redirectUrl: Path) => void;
  execFinishProductCheckout: (productId: string) => void;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

interface PropsContent extends PropsBase {
  stepData: StepData;
  nextStepUrl: Path;
  prevStepUrl: Path;
}

export interface PropsCheckout extends PropsBase {
  checkoutProduct: GetCheckoutProductResponse | undefined;
  groupId: string | undefined;
  isLoggedIn: boolean;
  paymentState: InvoiceAndPaymentDataState;
  stepCodeFromUrl: ProductCheckoutStepCode | undefined;
}

export function getStepperContents(props: PropsContent) {
  const stepData = props.stepData;
  switch (stepData.step) {
    case ProductCheckoutStepCode.Product:
      return (
        <ProductStep
          data={stepData}
          nextStepUrl={props.nextStepUrl}
          execOpenServicePriceExample={props.execOpenServicePriceExample}
        />
      );
    case ProductCheckoutStepCode.Account:
      return (
        <AccountStep
          execUserLogin={props.execUserLogin}
          execSignUp={props.execSignUp}
          nextStepUrl={props.nextStepUrl}
          prevStepUrl={props.prevStepUrl}
        />
      );
    case ProductCheckoutStepCode.Payment:
      return (
        <PaymentStep
          data={stepData}
          execSavePaymentStep={props.execSavePaymentStep}
          nextStepUrl={props.nextStepUrl}
          prevStepUrl={props.prevStepUrl}
        />
      );
    case ProductCheckoutStepCode.Finish:
      return (
        <FinishStep
          data={stepData}
          execFinishProductCheckout={props.execFinishProductCheckout}
          execOpenServicePriceExample={props.execOpenServicePriceExample}
          prevStepUrl={props.prevStepUrl}
        />
      );
    default:
      throw new UnreachableError(stepData);
  }
}
