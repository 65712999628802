import {
  GetCheckoutServiceInstanceResponse,
  PlanForCheckout,
  UnreachableError,
} from '@gts-sm/utils';
import { Path } from 'history';
import {
  InvoiceAndPaymentDataState,
  SaveInvoiceAndPaymentDataFields,
  ServiceInstanceCheckoutStepCode,
} from '../../../types';
import { PaymentStep } from './PaymentStep/PaymentStep';
import { StepData } from './checkoutTypes';
import { SelectPlanStep } from './SelectPlanStep/SelectPlanStep';
import { PlanStep } from './PlanStep/PlanStep';
import { FinishStep } from './FinishStep/FinishStep';

interface PropsBase {
  execLoadInvoiceAndPaymentData: () => void;
  execGetServiceInstanceForCheckout: (serviceInstanceId: string) => void;
  execSavePaymentStep: (data: {
    data: SaveInvoiceAndPaymentDataFields;
    groupId: string;
    nextStepUrl: Path;
    serviceInstanceId: string;
    planId: string;
  }) => void;
  execRedirect: (redirectUrl: Path) => void;
  execFinishServiceInstanceCheckout: (
    serviceInstanceId: string,
    planId: string,
  ) => void;
  execOpenServicePriceExample: (plan: PlanForCheckout) => void;
}

interface PropsContent extends PropsBase {
  stepData: StepData;
  nextStepUrl: Path;
  prevStepUrl: Path;
}

export interface PropsCheckout extends PropsBase {
  checkoutServiceInstance: GetCheckoutServiceInstanceResponse | undefined;
  selectedPlan: PlanForCheckout | undefined;
  groupId: string;
  planIdFromUrl: string | undefined;
  paymentState: InvoiceAndPaymentDataState;
  stepCodeFromUrl: ServiceInstanceCheckoutStepCode | undefined;
}

export function getStepperContents(props: PropsContent) {
  const stepData = props.stepData;
  switch (stepData.step) {
    case ServiceInstanceCheckoutStepCode.SelectPlan:
      return <SelectPlanStep data={stepData} />;
    case ServiceInstanceCheckoutStepCode.Plan:
      return (
        <PlanStep
          data={stepData}
          nextStepUrl={props.nextStepUrl}
          prevStepUrl={props.prevStepUrl}
          execOpenServicePriceExample={props.execOpenServicePriceExample}
        />
      );
    case ServiceInstanceCheckoutStepCode.Payment:
      return (
        <PaymentStep
          data={stepData}
          execSavePaymentStep={props.execSavePaymentStep}
          nextStepUrl={props.nextStepUrl}
          prevStepUrl={props.prevStepUrl}
        />
      );
    case ServiceInstanceCheckoutStepCode.Finish:
      return (
        <FinishStep
          data={stepData}
          execFinishServiceInstanceCheckout={
            props.execFinishServiceInstanceCheckout
          }
          execOpenServicePriceExample={props.execOpenServicePriceExample}
          prevStepUrl={props.prevStepUrl}
        />
      );
    default:
      throw new UnreachableError(stepData);
  }
}
