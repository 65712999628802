import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Subscription } from '@gts-sm/utils';

interface Props {
  subscriptions: ReadonlyArray<Subscription>;
  execCancelSubscription: (
    serviceInstanceId: string,
    subscriptionId: string,
  ) => void;
}

export const SubscriptionsForInstance = ({
  subscriptions,
  execCancelSubscription,
}: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Start Datum</TableCell>
            <TableCell align="center">End Datum</TableCell>
            <TableCell align="right">Kündigen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((subscription) => (
            <TableRow key={subscription.subscriptionId}>
              <TableCell align="left">{subscription.startDate}</TableCell>
              <TableCell align="center">{subscription.endDate}</TableCell>
              <TableCell align="right">
                {subscription.endDate === null && (
                  <IconButton
                    onClick={() =>
                      execCancelSubscription(
                        subscription.serviceInstanceId,
                        subscription.subscriptionId,
                      )
                    }
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
