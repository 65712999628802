import { Path } from 'history';
import { Grid, Link, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { UserLoginRequestBody } from '@gts-sm/utils';
import { userLoginValidation } from '../../../../validationSchemata';
import { userLoginFormInitialValues } from '../../../../constants';
import { LoginFormUserFields } from '../../../Login/LoginFormUserFields';

interface Props {
  execUserLogin: (
    loginData: UserLoginRequestBody,
    redirectAfterLogin: Path,
  ) => void;
  setShowNewUserForm: (show: boolean) => void;
  redirectAfterLogin: Path;
}

export const checkoutLoginFormId = 'checkout-login-form';

export const ExistingCustomer = ({
  execUserLogin,
  setShowNewUserForm,
  redirectAfterLogin,
}: Props) => {
  return (
    <Grid container justify="space-around">
      <Grid item xs={12}>
        <Typography align="center" gutterBottom={true}>
          Noch kein Kunde?{' '}
          <Link component="button" onClick={() => setShowNewUserForm(true)}>
            Hier Konto anlegen.
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Formik
          initialValues={userLoginFormInitialValues}
          onSubmit={(loginData) => execUserLogin(loginData, redirectAfterLogin)}
          validationSchema={userLoginValidation}
        >
          <Form noValidate={true} id={checkoutLoginFormId}>
            <LoginFormUserFields />
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};
