import { ServiceInstanceWithSubscriptions } from '@gts-sm/utils';
import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { LinkButton, View } from '@gts-common/client';
import { HOME_VIEW, SERVICE_INSTANCE_CHECKOUT_PATH } from '../../constants';
import { ServiceInstanceCheckoutStepCode } from '../../types';
import { SubscriptionsForInstance } from './SubscriptionsForInstance';

interface Props {
  groupId: string;
  serviceInstancesWithSubscriptions: ReadonlyArray<ServiceInstanceWithSubscriptions>;
  execGetSubscriptions: (groupId: string) => void;
  execCancelSubscription: (
    serviceInstanceId: string,
    subscriptionId: string,
  ) => void;
}

export const Subscriptions = ({
  groupId,
  execGetSubscriptions,
  serviceInstancesWithSubscriptions,
  execCancelSubscription,
}: Props) => {
  useEffect(() => execGetSubscriptions(groupId), [
    execGetSubscriptions,
    groupId,
  ]);

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Abonnements"
      maxWidth="md"
    >
      {serviceInstancesWithSubscriptions.map(
        (serviceInstanceWithSubscription) => {
          return (
            <Box key={serviceInstanceWithSubscription.serviceInstanceId}>
              <Box mb="1rem">
                <Typography variant="h6" component="h3" gutterBottom={true}>
                  Service: {serviceInstanceWithSubscription.serviceTitle}{' '}
                  {serviceInstanceWithSubscription.serviceInstanceNumber}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography>
                    Status:{' '}
                    {serviceInstanceWithSubscription.isReadOnly
                      ? 'Inaktiv'
                      : 'Aktiv'}
                  </Typography>
                  <Box ml="2rem">
                    {serviceInstanceWithSubscription.isReadOnly && (
                      <LinkButton
                        label="Aktivieren"
                        to={`${SERVICE_INSTANCE_CHECKOUT_PATH}/${ServiceInstanceCheckoutStepCode.SelectPlan}/${serviceInstanceWithSubscription.serviceInstanceId}`}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <SubscriptionsForInstance
                subscriptions={serviceInstanceWithSubscription.subscriptions}
                execCancelSubscription={execCancelSubscription}
              />
            </Box>
          );
        },
      )}
    </View>
  );
};
