import { Path } from 'history';
import {
  ServiceInstanceCheckoutStep,
  ServiceInstanceCheckoutStepCode,
} from '../../types';
import { SERVICE_INSTANCE_CHECKOUT_PATH } from '../../constants';

const selectPlanStep = {
  code: ServiceInstanceCheckoutStepCode.SelectPlan,
  label: 'Abonnement auswählen',
};

const planStep = {
  code: ServiceInstanceCheckoutStepCode.Plan,
  label: 'Abonnement',
};

const paymentStep = {
  code: ServiceInstanceCheckoutStepCode.Payment,
  label: 'Rechnungs-/Bezahldaten',
};

const bookStep = {
  code: ServiceInstanceCheckoutStepCode.Finish,
  label: 'Buchen',
};

export function getStepUrlFromStepCode(
  stepCode: ServiceInstanceCheckoutStepCode,
  serviceInstanceId: string | undefined,
  planId?: string | undefined,
) {
  if (planId) {
    return `${SERVICE_INSTANCE_CHECKOUT_PATH}/${stepCode}/${serviceInstanceId}/${planId}`;
  } else {
    return `${SERVICE_INSTANCE_CHECKOUT_PATH}/${stepCode}/${serviceInstanceId}`;
  }
}

function getStepUrlFromStep(
  step: ServiceInstanceCheckoutStep | undefined,
  serviceInstanceId: string | undefined,
  planId: string | undefined,
): Path {
  if (step) {
    if (step.code === ServiceInstanceCheckoutStepCode.SelectPlan) {
      return getStepUrlFromStepCode(step.code, serviceInstanceId);
    } else {
      return getStepUrlFromStepCode(step.code, serviceInstanceId, planId);
    }
  } else {
    return '';
  }
}

export function getSteps() {
  // type argument has to be given to force the typescript compiler to see this as type ServiceInstanceCheckoutStepCode.SelectPlan
  // instead of ServiceInstanceCheckoutStepCode
  const initialStepCode: ServiceInstanceCheckoutStepCode.SelectPlan =
    ServiceInstanceCheckoutStepCode.SelectPlan;
  const steps = [selectPlanStep, planStep, paymentStep, bookStep];

  return {
    initialStepCode,
    steps,
  };
}
export function getStepUrls(
  serviceInstanceId: string | undefined,
  planId: string | undefined,
  stepCode: ServiceInstanceCheckoutStepCode | undefined,
) {
  const { steps } = getSteps();

  const activeStep = steps.findIndex((step) => step.code === stepCode);
  const prevStep = steps[activeStep - 1];
  const nextStep = steps[activeStep + 1];

  const prevStepUrl = getStepUrlFromStep(prevStep, serviceInstanceId, planId);
  const nextStepUrl = getStepUrlFromStep(nextStep, serviceInstanceId, planId);

  return {
    steps,
    prevStepUrl,
    nextStepUrl,
  };
}
