import { Formik } from 'formik';

import { Box, ButtonGroup, Container, Typography } from '@material-ui/core';
import { View, LinkButton } from '@gts-common/client';
import { passwordResetRequestValidation } from '../../validationSchemata';
import { LOGIN_VIEW, SIGN_UP_VIEW } from '../../constants';
import { RequestPasswordResetMailForm } from './RequestPasswordResetMailForm';
import { PasswordResetMailSent } from './PasswordResetMailSent';

const resetRequest = {
  email: '',
};

interface Props {
  email: string | undefined;
  execRequestPasswordResetMail: (email: string) => void;
}

export const RequestPasswordResetMail = ({
  email,
  execRequestPasswordResetMail,
}: Props) => {
  if (email) {
    return <PasswordResetMailSent email={email} />;
  } else {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={LOGIN_VIEW}
        title="Passwort zurücksetzen"
        backButtonLabel="Anmeldung"
      >
        <Typography align="center">
          Geben Sie hier bitte die E-Mail-Adresse an, die Sie bei uns hinterlegt
          haben.
        </Typography>
        <Container maxWidth="xs">
          <Formik
            onSubmit={({ email }) => execRequestPasswordResetMail(email)}
            initialValues={resetRequest}
            validationSchema={passwordResetRequestValidation}
          >
            {() => <RequestPasswordResetMailForm />}
          </Formik>

          <Box textAlign="center" mt="1rem">
            <ButtonGroup variant="text">
              <LinkButton to={LOGIN_VIEW} label="Anmeldung" />
              <LinkButton to={SIGN_UP_VIEW} label="Registrierung" />
            </ButtonGroup>
          </Box>
        </Container>
      </View>
    );
  }
};
