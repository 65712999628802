import { Box, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';

import { useEffect } from 'react';
import {
  ErrorText,
  View,
  PleaseWaitText,
  InvalidLinkText,
  LinkButton,
} from '@gts-common/client';
import { UnreachableError } from '@gts-sm/utils';

import { ID_REGEX } from '@gts-common/client-server';
import {
  getSteps,
  getStepUrls,
  getStepUrlFromStepCode,
} from '../../../containers/helpers/serviceInstanceCheckoutSteps';
import { HOME_VIEW } from '../../../constants';
import { StepToShowType } from '../commonCheckoutTypes';
import { getStepToShow } from './getStepToShow/getStepToShow';
import { getStepperContents, PropsCheckout } from './getStepperContents';

interface PropsCheckoutChecked extends PropsCheckout {
  serviceInstanceIdFromUrl: string;
}

interface PropsCheckoutUnchecked extends PropsCheckout {
  serviceInstanceIdFromUrl: string | undefined;
}

const CheckoutValid = ({
  checkoutServiceInstance: checkoutServiceInstanceUnchecked,
  groupId,
  execFinishServiceInstanceCheckout,
  execGetServiceInstanceForCheckout,
  execLoadInvoiceAndPaymentData,
  execSavePaymentStep,
  execRedirect,
  execOpenServicePriceExample,
  paymentState,
  serviceInstanceIdFromUrl,
  planIdFromUrl,
  selectedPlan,
  stepCodeFromUrl,
}: PropsCheckoutChecked) => {
  // only use the checkoutServiceInstance from state if is same as in URL
  const checkoutServiceInstance =
    serviceInstanceIdFromUrl ===
    checkoutServiceInstanceUnchecked?.serviceInstanceId
      ? checkoutServiceInstanceUnchecked
      : undefined;

  const stepAndLoadData = getStepToShow({
    checkoutServiceInstance,
    planIdFromUrl,
    groupId,
    paymentState,
    stepCodeFromUrl,
    selectedPlan,
  });

  let loadServiceInstance = false;
  let loadInvoiceAndPaymentData = false;
  if (stepAndLoadData.type === StepToShowType.loadNeeded) {
    loadServiceInstance = stepAndLoadData.loadServiceInstance;
    loadInvoiceAndPaymentData = stepAndLoadData.loadInvoiceAndPaymentData;
  }

  const stepCodeToShow =
    stepAndLoadData.type === StepToShowType.withData
      ? stepAndLoadData.stepData.step
      : undefined;

  useEffect(() => {
    if (loadServiceInstance) {
      execGetServiceInstanceForCheckout(serviceInstanceIdFromUrl);
    }
  }, [
    execGetServiceInstanceForCheckout,
    loadServiceInstance,
    serviceInstanceIdFromUrl,
  ]);
  useEffect(() => {
    if (loadInvoiceAndPaymentData) {
      execLoadInvoiceAndPaymentData();
    }
  }, [execLoadInvoiceAndPaymentData, loadInvoiceAndPaymentData]);
  useEffect(() => {
    if (stepCodeToShow !== undefined && stepCodeToShow !== stepCodeFromUrl) {
      // if step to show differs from the step in the URL --> change URL to match the shown step
      execRedirect(
        getStepUrlFromStepCode(
          stepCodeToShow,
          serviceInstanceIdFromUrl,
          planIdFromUrl,
        ),
      );
    }
  }, [
    execRedirect,
    serviceInstanceIdFromUrl,
    planIdFromUrl,
    stepCodeFromUrl,
    stepCodeToShow,
  ]);

  switch (stepAndLoadData.type) {
    case StepToShowType.error:
      return (
        <>
          <ErrorText>
            Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später
            erneut.
          </ErrorText>
          <LinkButton to={HOME_VIEW} label="Zur Startseite" />
        </>
      );
    case StepToShowType.loadNeeded:
      return <PleaseWaitText />;
    case StepToShowType.withData: {
      const stepData = stepAndLoadData.stepData;
      const { nextStepUrl, prevStepUrl } = getStepUrls(
        serviceInstanceIdFromUrl,
        planIdFromUrl,
        stepData.step,
      );
      const { steps } = getSteps();
      const stepNumberToShow = steps.findIndex(
        (step) => step.code === stepData.step,
      );

      return (
        <View hasBackButton={false} title="Abonnement buchen">
          <Box>
            <Stepper activeStep={stepNumberToShow} orientation="vertical">
              {steps.map(({ label, code }) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {code === stepData.step
                        ? getStepperContents({
                            execFinishServiceInstanceCheckout,
                            execGetServiceInstanceForCheckout,
                            execLoadInvoiceAndPaymentData,
                            execSavePaymentStep,
                            execRedirect,
                            execOpenServicePriceExample,
                            nextStepUrl,
                            prevStepUrl,
                            stepData,
                          })
                        : undefined}
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </View>
      );
    }
    default:
      throw new UnreachableError(stepAndLoadData);
  }
};

export const ServiceInstanceCheckout = (props: PropsCheckoutUnchecked) => {
  const serviceInstanceIdFromUrl = props.serviceInstanceIdFromUrl;
  if (serviceInstanceIdFromUrl && ID_REGEX.test(serviceInstanceIdFromUrl)) {
    const propsChecked = { ...props, serviceInstanceIdFromUrl };
    return <CheckoutValid {...propsChecked} />;
  } else {
    return <InvalidLinkText />;
  }
};
