"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.okFailedResponseBodies = void 0;
exports.okFailedResponseBodies = {
    USER_USERNAME_TAKEN: {
        status: 1001,
        reason: 'This user username is already taken',
    },
    USER_NOT_CONFIRMED: {
        status: 1002,
        reason: 'This account has not been confirmed yet',
    },
    OLD_PASSWORD_DOESNT_MATCH: {
        status: 1003,
        reason: "Old password doesn't match the given password",
    },
    USER_LOGIN_FAILED: { status: 1004, reason: 'User login failed' },
    CAN_NOT_REACH_SERVICE: {
        status: 1005,
        reason: 'Cannot reach service server',
    },
    PASSWORD_RESET_REQUEST_EXPIRED: {
        status: 1006,
        reason: 'Password reset request expired',
    },
    COULDNT_GET_DATA_FROM_EXTERNAL_PAYMENT_PROVIDER: {
        status: 1007,
        reason: "Couldn't get data from the external payment provider",
    },
    CAN_NOT_CONFIRM_PAYMENT_PROVIDER_CHANGE: {
        status: 1008,
        reason: 'Can not confirm return from payment provider',
    },
    GROUP_USER_USERNAME_TAKEN: {
        status: 1009,
        reason: 'This group user username is already taken',
    },
    GROUP_USER_LOGIN_FAILED: { status: 1010, reason: 'Group user login failed' },
    SUBSCRIPTION_ALREADY_CANCELLED: {
        status: 1011,
        reason: 'The subscription was already cancelled',
    },
};
