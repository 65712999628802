import { InfoText, Link, View } from '@gts-common/client';
import { Contact, EditUserRequestBody } from '@gts-sm/utils';
import { Box, Paper, Typography } from '@material-ui/core';
import { GROUP_VIEW, HOME_VIEW } from '../../constants';
import { PersonalDataView } from '../../types';
import { PasswordChange } from './PasswordChange';
import { ContactForm } from './ContactForm';

interface Props {
  personalData: PersonalDataView;
  execPasswordChange: (passwordChange: EditUserRequestBody) => void;
  execUpdateContact: (user: Contact) => void;
}

export const PersonalData = ({
  execPasswordChange,
  execUpdateContact,
  personalData,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Persönliche Daten"
      maxWidth="sm"
    >
      <Paper
        variant="outlined"
        square
        style={{ padding: '1rem', marginBottom: '2rem' }}
      >
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>
          Password ändern
        </Typography>
        <PasswordChange execPasswordChange={execPasswordChange} />
      </Paper>

      <Paper variant="outlined" square style={{ padding: '1rem' }}>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>
          Kontaktdaten
        </Typography>
        <ContactForm
          contact={personalData.contact}
          execUpdateContact={execUpdateContact}
        />
        <Box mt="2rem">
          <InfoText variant="body2">
            Hinweis: Den Namen des Kontoinhabers der aktuellen Zahlungsmethode
            können Sie bei den{' '}
            <Link color="secondary" to={GROUP_VIEW}>
              Bezahldaten
            </Link>{' '}
            ändern.
          </InfoText>
        </Box>
      </Paper>
    </View>
  );
};
