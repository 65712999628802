import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import { cancelSubscriptionDecision, getSubscriptions } from '../actions';
import { Actions } from '../actions/reduxActionTypes';
import { Subscriptions } from '../components/Subscriptions/Subscriptions';

const mapStateToProps = (state: ReduxState) => {
  if (!state.group.available) {
    throw new Error('Group is not available');
  }

  const serviceInstancesWithSubscriptions = state.subscriptions.list;

  return {
    serviceInstancesWithSubscriptions,
    groupId: state.group.groupId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execGetSubscriptions(groupId: string) {
    dispatch(getSubscriptions(groupId));
  },
  execCancelSubscription(serviceInstanceId: string, subscriptionId: string) {
    dispatch(cancelSubscriptionDecision(serviceInstanceId, subscriptionId));
  },
});

export const SubscriptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subscriptions);
