import { combineReducers } from 'redux';

import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { commonStateReducer } from '@gts-common/client';
import { ReduxState } from '../types';
import { appReducer } from './app';
import { groupReducer } from './group';
import { serviceInstancesReducer } from './serviceInstances';
import { invoiceAndPaymentDataReducer } from './payment';
import { avvReducer } from './avv';
import { groupUsersReducer } from './groupUsers';
import { invoicesReducer } from './invoices';
import { subscriptionsReducer } from './subscriptions';
import { checkoutReducer } from './checkout';

export const rootReducer = (history: History) =>
  combineReducers<ReduxState>({
    app: appReducer,
    avv: avvReducer,
    group: groupReducer,
    groupUsers: groupUsersReducer,
    invoiceAndPaymentData: invoiceAndPaymentDataReducer,
    serviceInstances: serviceInstancesReducer,
    checkout: checkoutReducer,
    invoices: invoicesReducer,
    subscriptions: subscriptionsReducer,
    common: commonStateReducer,
    router: connectRouter(history),
  });
