import { Field, FieldProps } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  NormalizedPaymentMethodStatus,
  PaymentMethod,
  PaymentMethodDebitData,
  PaymentMethodType,
  UnreachableError,
} from '@gts-sm/utils';
import { InfoText, WarningText } from '@gts-common/client';
import { PaymentMethodsLoadedState } from '../../types';

interface Props {
  paymentMethods: PaymentMethodsLoadedState;
}

export const PAYMENT_METHOD_CURRENT = 'current';

function getDebitPaymentMethodString(debitData: PaymentMethodDebitData) {
  const account = `Lastschrift von Konto …${debitData.accountNumberEnding}`;
  const accountHolder = debitData.accountHolderName
    ? ` (Kontoinhaber: ${debitData.accountHolderName})`
    : '';
  const bankName = debitData.bankName ? ` bei ${debitData.bankName}` : '';

  return account + bankName + accountHolder;
}

export const paymentMethodLabels = {
  [PaymentMethodType.debit]: getDebitPaymentMethodString,
  [PaymentMethodType.manual]: 'Überweisung',
};

function getCurrentPaymentMethodLabel(
  currentPaymentMethod: PaymentMethod | undefined,
) {
  if (currentPaymentMethod) {
    switch (currentPaymentMethod.type) {
      case PaymentMethodType.debit:
        return paymentMethodLabels[currentPaymentMethod.type](
          currentPaymentMethod.data,
        );
      case PaymentMethodType.manual:
        throw new Error(
          'Manual payment method is not supported in this context',
        );
      default:
        throw new UnreachableError(currentPaymentMethod);
    }
  } else {
    return undefined;
  }
}

function getCurrentPaymentMethodUi(
  currentPaymentMethod: PaymentMethod | undefined,
) {
  if (currentPaymentMethod) {
    if (
      currentPaymentMethod.status === NormalizedPaymentMethodStatus.DISABLED
    ) {
      return [
        <WarningText key="label_current_payment_method">
          Die aktuelle Zahlungsmethode kann nicht verwendet werden.
        </WarningText>,
        <Typography key="label_choose_other_method" align="center">
          Bitte wählen Sie eine andere Zahlungsmethode aus.
        </Typography>,
        <FormControlLabel
          key={PAYMENT_METHOD_CURRENT}
          value={PAYMENT_METHOD_CURRENT}
          control={<Radio />}
          label={getCurrentPaymentMethodLabel(currentPaymentMethod)}
          disabled={true}
        />,
      ];
    } else {
      return [
        <Typography key="label_current_payment_method">
          Aktuelle Zahlungsmethode beibehalten:
        </Typography>,
        <FormControlLabel
          key={PAYMENT_METHOD_CURRENT}
          value={PAYMENT_METHOD_CURRENT}
          control={<Radio />}
          label={getCurrentPaymentMethodLabel(currentPaymentMethod)}
        />,
      ];
    }
  } else {
    return [];
  }
}

function getChangePaymentMethodLabelUi(
  currentPaymentMethod: PaymentMethod | undefined,
) {
  const hasCurrentPaymentMethod = currentPaymentMethod !== undefined;
  const changePaymentMethodLabelUi = hasCurrentPaymentMethod
    ? [
        <Typography key="label_change_payment_method">
          Zahlungsmethode für die aktuelle und alle zukünftigen Zahlungen
          ändern:
        </Typography>,
      ]
    : [];

  return changePaymentMethodLabelUi;
}

function getChangePaymentMethodUi(
  changePaymentMethod: PaymentMethodsLoadedState['changePaymentMethod'],
) {
  return changePaymentMethod.map((method) => (
    <FormControlLabel
      key={`new_${method.type}`}
      value={`new_${method.type}`}
      control={<Radio />}
      label={method.label}
    />
  ));
}

function getUnpaidInvoicesInfo(
  currentPaymentMethod: PaymentMethod | undefined,
  value: string,
) {
  const hasCurrentPaymentMethod = currentPaymentMethod !== undefined;

  // Show the warning only when a method is selected that is not the current method
  if (
    hasCurrentPaymentMethod &&
    value !== PAYMENT_METHOD_CURRENT &&
    value !== ''
  ) {
    return [
      <WarningText variant="body1" key="label_unpaid_invoices_info">
        Falls Sie noch offene Rechnungen haben, werden wir versuchen diese über
        die neue Zahlungsmethode abzubuchen. In Einzelfällen erhalten Sie im
        Rahmen der Umstellung aus technischen Gründen E-Mails mit
        Stornierungsbestätigungen für Zahlungen.
      </WarningText>,
    ];
  } else {
    return [
      <InfoText variant="body2" key="label_unpaid_invoices_info">
        Hinweis: Wenn Sie die Kontoverbindung oder den Namen des Kontoinhabers
        der aktuellen Zahlungsmethode ändern möchten, legen Sie hierfür bitte
        eine neue Zahlungsmethode (Lastschrift) an.
      </InfoText>,
    ];
  }
}

export const PaymentOptions = ({ paymentMethods }: Props) => {
  return (
    <Field name="paymentMethod">
      {({
        field: { onChange, value },
        meta: { error, touched },
      }: FieldProps) => {
        const showError = Boolean(touched && error);
        // Use empty string to make sure that the helperText takes
        // up the same space it would if a real error is shown.
        const errorToDisplay = showError ? error : ' ';

        const { currentPaymentMethod, changePaymentMethod } = paymentMethods;

        const currentPaymentMethodUi = getCurrentPaymentMethodUi(
          currentPaymentMethod,
        );
        const changePaymentMethodLabelUi = getChangePaymentMethodLabelUi(
          currentPaymentMethod,
        );
        const changePaymentMethodUi = getChangePaymentMethodUi(
          changePaymentMethod,
        );
        const unpaidInvoicesInfo = getUnpaidInvoicesInfo(
          currentPaymentMethod,
          value,
        );

        return (
          <Grid container justify="space-around">
            <Grid item xs={12} sm={9}>
              <FormControl
                component="fieldset"
                fullWidth={true}
                error={showError}
              >
                <FormLabel component="legend">Zahlungsmethode</FormLabel>
                <FormHelperText>{errorToDisplay}</FormHelperText>
                <RadioGroup
                  aria-label="Zahlungsmethode"
                  name="paymentMethod"
                  value={value}
                  onChange={onChange}
                >
                  {[
                    ...currentPaymentMethodUi,
                    ...changePaymentMethodLabelUi,
                    ...changePaymentMethodUi,
                    ...unpaidInvoicesInfo,
                  ]}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
