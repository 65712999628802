import { Matcher } from 'interweave';
import { getTemplateString } from '@gts-sm/utils';
import { ComponentClass, FunctionComponent, ReactNode } from 'react';

export class AVVMatcher extends Matcher {
  regex: string;
  // name has to be letters only
  constructor(name: string, component: ComponentClass | FunctionComponent) {
    super(name, {}, component);
    this.regex = getTemplateString(name);
  }
  match(string: string) {
    return this.doMatch(string, this.regex, () => {
      return {};
    });
  }
  replaceWith(children: ReactNode, props: unknown) {
    return <span {...props}>{children}</span>;
  }
  asTag(): string {
    return 'span';
  }
}
