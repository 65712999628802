import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorText, LinkButton, PleaseWaitText } from '@gts-common/client';
import { LoadingState, PaymentProviderChangeConfirmState } from '../../types';
import { HOME_VIEW } from '../../constants';
import { MandateCreatedInfo } from './MandateCreatedInfo';

interface Props {
  execAcknowledgePaymentProviderChanged: () => void;
  execConfirmPaymentProviderChange: (urlParams: string) => void;
  state: PaymentProviderChangeConfirmState;
}

export function PaymentProviderChangeConfirm({
  execAcknowledgePaymentProviderChanged,
  execConfirmPaymentProviderChange,
  state,
}: Props) {
  const urlParams = useLocation().search;

  useEffect(() => execConfirmPaymentProviderChange(urlParams), [
    execConfirmPaymentProviderChange,
    urlParams,
  ]);

  switch (state.loadState) {
    case LoadingState.loadNeeded:
      return <PleaseWaitText />;
    case LoadingState.error:
      return (
        <>
          <ErrorText>
            Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
          </ErrorText>
          <LinkButton to={HOME_VIEW} label="Zur Startseite" />
        </>
      );
    case LoadingState.loaded:
      return (
        <MandateCreatedInfo
          state={state}
          execAcknowledgePaymentProviderChanged={
            execAcknowledgePaymentProviderChanged
          }
        />
      );
  }
}
