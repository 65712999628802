import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Logo } from '@gts-common/client';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { MouseEvent } from 'react';
import logoSrc from '../../images/logo.png';
import { HOME_VIEW } from '../../constants';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

interface HeaderAppBarProps {
  openSettingsMenu: (event: MouseEvent) => void;
  openAccountMenu: (event: MouseEvent) => void;
  isEmailConfirmed: boolean;
  isUser: boolean;
}

export const HeaderAppBar = ({
  openSettingsMenu,
  openAccountMenu,
  isEmailConfirmed,
  isUser,
}: HeaderAppBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <AppBar position="static" color="default" classes={classes}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          {/* Offset to center the headline */}
          {!isXsOrSmaller && <Grid item xs={4} sm={3} md={2} />}
          {/* Logo */}
          <Grid item xs={6} sm={6} md={8}>
            <Box
              display="flex"
              justifyContent={isXsOrSmaller ? 'start' : 'center'}
            >
              <Logo
                to={HOME_VIEW}
                src={logoSrc}
                title="get the service"
                hideText={isXsOrSmaller}
              />
            </Box>
          </Grid>
          {/* IconButtons */}
          <Grid item xs={6} sm={3} md={2}>
            <Box textAlign="right">
              {isEmailConfirmed && isUser && (
                <IconButton
                  onClick={openSettingsMenu}
                  color="inherit"
                  size="small"
                >
                  <SettingsIcon />
                </IconButton>
              )}
              <IconButton
                onClick={openAccountMenu}
                color="inherit"
                size="small"
              >
                <PersonIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
