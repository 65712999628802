import { Paper, Typography } from '@material-ui/core';
import { AcceptedAVV } from '@gts-sm/utils';
import { InvoiceDataLoadedState } from '../../types';
import { AVVList } from './AVVList';

interface Props {
  groupId: string;
  invoiceData: InvoiceDataLoadedState;
  avvList: ReadonlyArray<AcceptedAVV>;
  execGetAllAVVContracts: (groupId: string) => void;
  execShowAvv: () => void;
  hasServiceThatNeedsAvv: boolean;
}

export const AVV = ({
  groupId,
  avvList,
  execGetAllAVVContracts,
  execShowAvv,
  invoiceData,
  hasServiceThatNeedsAvv,
}: Props) => {
  return (
    <Paper
      variant="outlined"
      square
      style={{ padding: '1rem', marginBottom: '2rem' }}
    >
      <Typography variant="h6" style={{ marginBottom: '1rem' }}>
        Auftrags&shy;verarbeitungs&shy;vertrag
      </Typography>
      <AVVList
        company={invoiceData}
        execShowAvv={execShowAvv}
        avvList={avvList}
        execGetAllAVVContracts={execGetAllAVVContracts}
        groupId={groupId}
        hasServiceThatNeedsAvv={hasServiceThatNeedsAvv}
      />
    </Paper>
  );
};
