import {
  LOGIN_PATH,
  LOGOUT_PATH,
  UserLoginDataResponse,
  UserLoginRequestBody,
  GroupUserLoginRequestBody,
  GROUP_USERS_LOGIN,
  SessionType,
  GroupUserLoginDataResponse,
} from '@gts-sm/utils';

import { LocationDescriptorObject, Path } from 'history';

import {
  getErrorModalMessage,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  normalize,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  ServerResponseError,
  ServerResponseErrorType,
} from '@gts-common/client-server';

import { LoginData } from '../types';
import { LOGIN_VIEW } from '../constants';

import { serverComm } from '../serverComm';
import {
  Actions,
  LOGIN_CHECK_FAILED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LoginCheckFailedAction,
  LoginCheckSucceededAction,
  LoginFailedAction,
  LoginSucceededAction,
  LOGOUT_SUCCEEDED,
  LogoutSucceededAction,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';
import { execReplace } from './navigation';

function getLoginData(
  body: UserLoginDataResponse | GroupUserLoginDataResponse,
): LoginData {
  if (body.sessionType === SessionType.GROUP_USER) {
    return {
      isEmailConfirmed: true,
      email: '',
      firstName: '',
      lastName: '',
      group: {
        groupId: '',
        groupNumber: 0,
        hasAvv: true,
        available: true,
      },
      userType: body.sessionType,
      serviceInstances: normalize('serviceInstanceId', body.serviceInstances),
    };
  }

  return {
    isEmailConfirmed: body.isEmailConfirmed,
    email: body.email,
    firstName: body.firstName,
    lastName: body.lastName,
    group: { ...body.groups[0], available: true },
    userType: body.sessionType,
    serviceInstances: normalize('serviceInstanceId', body.serviceInstances),
  };
}

function loginCheckSucceeded(
  body: UserLoginDataResponse | GroupUserLoginDataResponse,
): LoginCheckSucceededAction {
  return {
    type: LOGIN_CHECK_SUCCEEDED,
    payload: getLoginData(body),
  };
}

function loginCheckFailed(): LoginCheckFailedAction {
  return {
    type: LOGIN_CHECK_FAILED,
  };
}

export function loginSucceeded(
  body: UserLoginDataResponse | GroupUserLoginDataResponse,
): LoginSucceededAction {
  return {
    type: LOGIN_SUCCEEDED,
    payload: getLoginData(body),
  };
}

function loginFailed(): LoginFailedAction {
  return {
    type: LOGIN_FAILED,
  };
}

export function logoutSucceeded(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(execReplace(LOGIN_VIEW));
    const logoutSucceededAction: LogoutSucceededAction = {
      type: LOGOUT_SUCCEEDED,
    };
    dispatch(logoutSucceededAction);
  };
}

export const checkLoggedIn = (): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  serverComm
    .execGetRequest<UserLoginDataResponse | GroupUserLoginDataResponse>(
      LOGIN_PATH,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(loginCheckSucceeded(resp.body));
          dispatch(serverRequestSucceeded());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          dispatch(loginCheckFailed());
        }
      },
      (e: unknown) => {
        if (e instanceof ServerResponseError) {
          if (e.type === ServerResponseErrorType.UNAUTHORIZED) {
            dispatch(serverRequestFailed());
            dispatch(loginCheckFailed());
            return;
          }
        }
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
        dispatch(loginCheckFailed());
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};

export const execUserLoginAndRedirect = (
  data: UserLoginRequestBody,
  redirectAfterLogin: LocationDescriptorObject | Path | undefined,
): Thunk<Actions> => (dispatch) => {
  dispatch(
    execUserLogin(data, (dispatch) => {
      dispatch(execReplace(redirectAfterLogin));
    }),
  );
};

export const execGroupUserLoginAndRedirect = (
  data: GroupUserLoginRequestBody,
  redirectAfterLogin: LocationDescriptorObject | Path | undefined,
): Thunk<Actions> => (dispatch) => {
  dispatch(
    execGroupUserLogin(data, (dispatch) => {
      dispatch(execReplace(redirectAfterLogin));
    }),
  );
};

export const execUserLogin = (
  data: UserLoginRequestBody,
  then: Thunk<Actions>,
): Thunk<Actions> => (dispatch) => {
  const normalizedUsername = data.username.toLowerCase().trim();
  const dataToSend: UserLoginRequestBody = {
    username: normalizedUsername,
    password: data.password,
  };

  dispatch(startServerRequest());

  return serverComm
    .execPostRequest<UserLoginDataResponse, UserLoginRequestBody>(
      LOGIN_PATH,
      dataToSend,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(loginSucceeded(resp.body));
          dispatch(serverRequestSucceeded());
          dispatch(then);
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          dispatch(loginFailed());
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
        dispatch(loginFailed());
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};

export const execGroupUserLogin = (
  data: GroupUserLoginRequestBody,
  then: Thunk<Actions>,
): Thunk<Actions> => (dispatch) => {
  const normalizedUsername = data.username.toLowerCase().trim();
  const dataToSend: GroupUserLoginRequestBody = {
    username: normalizedUsername,
    password: data.password,
    groupNumber: data.groupNumber,
  };

  dispatch(startServerRequest());

  return serverComm
    .execPostRequest<UserLoginDataResponse, GroupUserLoginRequestBody>(
      GROUP_USERS_LOGIN,
      dataToSend,
    )
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(loginSucceeded(resp.body));
          dispatch(serverRequestSucceeded());
          dispatch(then);
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          dispatch(loginFailed());
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
        dispatch(loginFailed());
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};

export const execLogout = (): Thunk<Actions> => (dispatch) => {
  dispatch(startServerRequest());

  serverComm
    .execPostRequest(LOGOUT_PATH)
    .then(
      (resp) => {
        if (resp.succeeded) {
          dispatch(logoutSucceeded());
          dispatch(serverRequestSucceeded());
        } else {
          dispatch(serverRequestFailed(getOperationFailureReason(resp)));
        }
      },
      (e: unknown) => {
        dispatch(serverRequestFailed(getErrorModalMessage(e)));
      },
    )
    .catch((e: unknown) => {
      sendErrorToServer(serverComm, e);
      dispatch(showError(getErrorModalMessage(e)));
    });
};
